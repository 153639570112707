/* eslint-disable no-param-reassign */
import { format } from 'date-fns';
import { Dispatch, SetStateAction, useCallback, useContext, useEffect, useMemo } from 'react';
import { useQuery } from 'react-query';
import { MovimentTypesSlugEnum } from 'src/pages/movements/types/MovementInterface';
import { DEFAULT_LOCALE } from 'src/config';
import { uniq } from 'lodash';
import { listAllMovementsPaymentTypes } from 'src/pages/movements/services/listAllMovementsPaymentTypes';
import { getCashFlow } from 'src/pages/cashFlow/services/getCashFlow';
import { ICashflow } from 'src/pages/cashFlow/types/cashflow.interface';
import { BalanceType } from 'src/pages/cashFlow/components/BalanceTypeChangeSwitch';
import { PaymentTypeInterface } from 'src/pages/movements/types/paymentTypeInterface';
import { useEnterprise } from 'src/pages/enterprises/hooks/useEnterprise';
import { percentage } from '../../../utils/calcHelper';
import { GetDashboardParams } from '../services/getDashboard';
import { groupBy } from '../../../utils/groupBy';
import { DashboardContext } from '../contexts/DashboardContext';

interface LabelTotalInterface {
  label: string;
  total: number;
}

interface PaymentTypesTotalInterface extends PaymentTypeInterface {
  [MovimentTypesSlugEnum.invoice]: number;
  [MovimentTypesSlugEnum.expense]: number;
  balance: number;
}

interface UseDashboardInterface {
  expensesGroupedByPayment: Record<string, ICashflow[]>;
  invoicesGroupedByPayment: Record<string, ICashflow[]>;
  cashflowEntradasFiltered: ICashflow[];
  cashflowSaidasFiltered: ICashflow[];
  entradas: ICashflow[];
  saidas: ICashflow[];
  entradasFuturas: ICashflow[];
  saidasFuturas: ICashflow[];
  sumEntradas: number;
  sumSaidas: number;
  totalSumEntradas: number;
  balanceEntradas: number;
  percentEntradas: number;
  percentSaidas: number;
  sumSaidasFuturas: number;
  sumEntradasFuturas: number;
  sumTotalEntradasFuturas: number;
  sumTotalSaidasFuturas: number;
  percentEntradasFuturas: number;
  percentSaidasFuturas: number;

  balance: number;
  balancePrevisto: number;

  startDate: Date;
  endDate: Date;
  todayDate: Date;
  setStartDate: Dispatch<SetStateAction<Date>>;
  setEndDate: Dispatch<SetStateAction<Date>>;

  rangeDateLabel: string;

  balancePaymentTypes: LabelTotalInterface[];
  balancePaymentTypesDivided: PaymentTypesTotalInterface[];

  isFetching: boolean;
  refetch: () => void;

  selectBalanceType: BalanceType;
  toggleSelectBalanceType: () => void;
}

export function useDashboard(): UseDashboardInterface {
  const { endDate, setEndDate, startDate, setStartDate, selectBalanceType, setSelectBalanceType, todayDate } =
    useContext(DashboardContext);
  const { selectedEnterprise } = useEnterprise();

  const params: Omit<GetDashboardParams, 'invoiceOrExpense'> = useMemo(
    () => ({
      selectedEnterprise,
      start_date: startDate,
      end_date: endDate,
    }),
    [endDate, selectedEnterprise, startDate],
  );

  const cashFlowQuery = useQuery('cashflow', () =>
    getCashFlow({
      ...params,
    }),
  );
  const cashFlowQueryDataResponse = cashFlowQuery?.data?.data;

  const movementTypesQuery = useQuery('listAllMovementsPaymentTypes', listAllMovementsPaymentTypes);

  const refetch = useCallback(
    () => {
      cashFlowQuery.refetch();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [cashFlowQuery],
  );

  useEffect(() => {
    refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedEnterprise, startDate, endDate]);

  const cashflowEntradasFiltered = useMemo(
    () => cashFlowQueryDataResponse?.filter(({ type_slug }) => type_slug === MovimentTypesSlugEnum.invoice) || [],
    [cashFlowQueryDataResponse],
  );

  // saidas = type_slug == expense
  const cashflowSaidasFiltered = useMemo(
    () => cashFlowQueryDataResponse?.filter(({ type_slug }) => type_slug === MovimentTypesSlugEnum.expense) || [],
    [cashFlowQueryDataResponse],
  );

  const entradas = useMemo(() => cashflowEntradasFiltered.filter(({ paid }) => paid) || [], [cashflowEntradasFiltered]);

  // saidas = type_slug == expense
  const saidas = useMemo(() => cashflowSaidasFiltered.filter(({ paid }) => paid) || [], [cashflowSaidasFiltered]);

  // a pagar = type_slug == expense and due_date >= today
  const entradasFuturas = useMemo(
    () => cashflowEntradasFiltered?.filter(({ paid }) => paid === false) || [],
    [cashflowEntradasFiltered],
  );

  // a receber = type_slug == invoice and due_date >= today
  const saidasFuturas = useMemo(
    () => cashflowSaidasFiltered?.filter(({ paid }) => paid === false) || [],
    [cashflowSaidasFiltered],
  );

  const paymentsTypes = useMemo(() => movementTypesQuery?.data?.data || [], [movementTypesQuery.data]);

  const { isFetching } = cashFlowQuery;

  const rangeDateLabel = useMemo(() => {
    const start = format(startDate, 'dd MMMM', { locale: DEFAULT_LOCALE });
    const end = format(endDate, 'dd MMMM', { locale: DEFAULT_LOCALE });

    return `De ${start} até ${end}`;
  }, [endDate, startDate]);

  const shouldGetPrevisto = useMemo(() => selectBalanceType === BalanceType.previsto, [selectBalanceType]);

  const entradasGroupedByPayment = useMemo(
    () => groupBy<ICashflow>(shouldGetPrevisto ? entradasFuturas : entradas, ({ payment_label }) => payment_label),
    [entradas, entradasFuturas, shouldGetPrevisto],
  );

  const saidasGroupedByPayment = useMemo(
    () => groupBy<ICashflow>(shouldGetPrevisto ? saidasFuturas : saidas, ({ payment_label }) => payment_label),
    [saidas, saidasFuturas, shouldGetPrevisto],
  );

  const balancePaymentTypes: LabelTotalInterface[] = useMemo(() => {
    const saidasEntries = Object.entries(saidasGroupedByPayment);
    const entradasEntries = Object.entries(entradasGroupedByPayment);

    const arrSummedSaidas: LabelTotalInterface[] = saidasEntries.map(([label, saidasArr]) => {
      const total = saidasArr.reduce(
        // eslint-disable-next-line @typescript-eslint/comma-dangle
        (acc, val) => (acc += val?.paid ? val?.value_paid || 0 : val?.total_tobe_paid), 0
      );
      const object: LabelTotalInterface = { label, total };

      return object;
    });

    const arrSummedEntradas: LabelTotalInterface[] = entradasEntries.map(([label, entradasArr]) => {
      const total = entradasArr.reduce(
        // eslint-disable-next-line @typescript-eslint/comma-dangle
        (acc, val) => (acc += val?.paid ? val?.value_paid || 0 : val?.total_tobe_paid), 0
      );
      const object: LabelTotalInterface = { label, total };

      return object;
    });

    const saidasLabel = Object.keys(groupBy<ICashflow>(cashflowSaidasFiltered, ({ payment_label }) => payment_label));

    const entradasLabel = Object.keys(
      groupBy<ICashflow>(cashflowEntradasFiltered, ({ payment_label }) => payment_label),
    );

    const mixedLabels: string[] = uniq([...saidasLabel, ...entradasLabel]);

    return mixedLabels.map((label) => {
      const sumSaidas = arrSummedSaidas.find((exp) => label === exp.label)?.total || 0;
      const sumEntradas = arrSummedEntradas.find((inv) => label === inv.label)?.total || 0;

      return {
        label,
        total: sumEntradas - sumSaidas,
      };
    });
  }, [cashflowEntradasFiltered, saidasGroupedByPayment, cashflowSaidasFiltered, entradasGroupedByPayment]);

  const balancePaymentTypesDivided = useMemo(() => {
    const internalEntradas = shouldGetPrevisto ? entradasFuturas : entradas;
    const internalSaidas = shouldGetPrevisto ? saidasFuturas : saidas;

    return paymentsTypes.map((paymentType) => {
      const sumedInvoices = internalEntradas
        .filter(({ payment_slug }) => payment_slug === paymentType.slug)
        .reduce((acc, val) => (acc += val?.paid ? val?.value_paid || 0 : val?.total_tobe_paid), 0);

      const sumedExpenses = internalSaidas
        .filter(({ payment_slug }) => payment_slug === paymentType.slug)
        .reduce((acc, val) => (acc += val?.paid ? val?.value_paid || 0 : val?.total_tobe_paid), 0);

      return {
        ...paymentType,
        [MovimentTypesSlugEnum.invoice]: sumedInvoices,
        [MovimentTypesSlugEnum.expense]: sumedExpenses,
        balance: sumedInvoices - sumedExpenses,
      };
    });
  }, [entradas, entradasFuturas, paymentsTypes, saidas, saidasFuturas, shouldGetPrevisto]);

  // entradas = type_slug == invoice

  // @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
  // Invoices / expenses already paid
  const sumEntradas = entradas?.reduce(
    // eslint-disable-next-line @typescript-eslint/comma-dangle
    (acc, val) => (acc += val?.paid ? val?.value_paid || 0 : val?.total_tobe_paid), 0
  );
  const sumSaidas = saidas?.reduce(
    // eslint-disable-next-line @typescript-eslint/comma-dangle
    (acc, val) => (acc += val?.paid ? val?.value_paid || 0 : val?.total_tobe_paid), 0
  );
  const totalSumEntradas = useMemo(() => sumEntradas + sumSaidas, [sumSaidas, sumEntradas]);
  const balanceEntradas = useMemo(() => sumEntradas - sumSaidas, [sumSaidas, sumEntradas]);
  // end Invoices / expenses already paid
  // @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@

  // ---

  // @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
  // Invoices / expenses already paid
  const sumSaidasFuturas = saidasFuturas?.reduce(
    // eslint-disable-next-line @typescript-eslint/comma-dangle
    (acc, val) => (acc += val?.paid ? val?.value_paid || 0 : val?.total_tobe_paid), 0
  );
  const sumEntradasFuturas = entradasFuturas?.reduce(
    // eslint-disable-next-line @typescript-eslint/comma-dangle
    (acc, val) => (acc += val?.paid ? val?.value_paid || 0 : val?.total_tobe_paid), 0
  );
  const sumTotalEntradasFuturas = useMemo(
    () => sumSaidasFuturas + sumEntradasFuturas,
    [sumEntradasFuturas, sumSaidasFuturas],
  );
  const sumTotalSaidasFuturas = useMemo(
    () => sumSaidasFuturas - sumEntradasFuturas,
    [sumEntradasFuturas, sumSaidasFuturas],
  );

  const sumEntradasRealizadasPlusEntradasPrevistas = useMemo(
    () => sumEntradas + sumEntradasFuturas,
    [sumEntradas, sumEntradasFuturas],
  );

  const sumSaidasRealizadasPlusSaidasPrevistas = useMemo(
    () => sumSaidas + sumSaidasFuturas,
    [sumSaidas, sumSaidasFuturas],
  );

  const percentEntradas = useMemo(
    () => percentage(sumEntradas, sumEntradasRealizadasPlusEntradasPrevistas),
    [sumEntradas, sumEntradasRealizadasPlusEntradasPrevistas],
  );
  const percentSaidas = useMemo(
    () => percentage(sumSaidas, sumSaidasRealizadasPlusSaidasPrevistas),
    [sumSaidas, sumSaidasRealizadasPlusSaidasPrevistas],
  );
  const percentEntradasFuturas = useMemo(
    () => percentage(sumEntradasFuturas, sumEntradasRealizadasPlusEntradasPrevistas),
    [sumEntradasFuturas, sumEntradasRealizadasPlusEntradasPrevistas],
  );
  const percentSaidasFuturas = useMemo(
    () => percentage(sumSaidasFuturas, sumSaidasRealizadasPlusSaidasPrevistas),
    [sumSaidasFuturas, sumSaidasRealizadasPlusSaidasPrevistas],
  );
  // end Invoices / expenses already paid
  // @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@

  const balance = useMemo(() => sumEntradas - sumSaidas, [sumEntradas, sumSaidas]);

  const balancePrevisto = useMemo(() => sumEntradasFuturas - sumSaidasFuturas, [sumEntradasFuturas, sumSaidasFuturas]);

  function toggleSelectBalanceType() {
    setSelectBalanceType(selectBalanceType === BalanceType.realizado ? BalanceType.previsto : BalanceType.realizado);
  }

  return {
    expensesGroupedByPayment: saidasGroupedByPayment,
    invoicesGroupedByPayment: entradasGroupedByPayment,
    isFetching,
    refetch,
    balance,
    balancePrevisto,
    startDate,
    endDate,
    rangeDateLabel,
    balancePaymentTypes,
    balancePaymentTypesDivided,
    setStartDate,
    setEndDate,
    todayDate,

    cashflowEntradasFiltered,
    cashflowSaidasFiltered,

    entradas,
    saidas,
    entradasFuturas,
    saidasFuturas,

    sumEntradas,
    sumSaidas,
    totalSumEntradas,
    balanceEntradas,
    percentEntradas,
    percentSaidas,

    sumSaidasFuturas,
    sumEntradasFuturas,
    sumTotalEntradasFuturas,
    sumTotalSaidasFuturas,
    percentEntradasFuturas,
    percentSaidasFuturas,

    selectBalanceType,
    toggleSelectBalanceType,
  };
}
