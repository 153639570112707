import type { FC } from 'react';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { useNavigate } from 'react-router';
import { Box, Button, CircularProgress, FormHelperText, TextField } from '@material-ui/core';
import useMounted from 'src/hooks/useMounted';
import useAuth from 'src/hooks/useAuth';

const LoginJWT: FC = (props) => {
  const navigate = useNavigate();
  const mounted = useMounted();
  const { login } = useAuth();

  function resetPassword() {
    navigate('/authentication/password-recovery');
  }

  return (
    <Formik
      initialValues={{
        email: '',
        password: '',
        submit: null,
      }}
      validationSchema={Yup.object().shape({
        email: Yup.string().email('O email é inválido').max(255).required('Email é obrigatório'),
        password: Yup.string().max(255).required('Senha é obrigatória'),
      })}
      onSubmit={async (values, { setStatus, setSubmitting }): Promise<void> => {
        try {
          await login({ email: values.email, password: values.password });

          if (mounted.current) {
            setStatus({ success: true });
            setSubmitting(false);
          }
        } catch (err) {
          console.error(err);
          if (mounted.current) {
            setStatus({ success: false });
            // // setErrors({ submit: err?.message });
            setSubmitting(false);
          }
        }
      }}
    >
      {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }): JSX.Element => (
        <form noValidate onSubmit={handleSubmit} {...props}>
          <TextField
            error={Boolean(touched.email && errors.email)}
            fullWidth
            helperText={touched.email && errors.email}
            label="Endereço de email"
            margin="normal"
            name="email"
            onBlur={handleBlur}
            onChange={handleChange}
            type="email"
            value={values.email}
            variant="outlined"
            disabled={isSubmitting}
          />
          <TextField
            error={Boolean(touched.password && errors.password)}
            fullWidth
            helperText={touched.password && errors.password}
            label="Senha"
            margin="normal"
            name="password"
            onBlur={handleBlur}
            onChange={handleChange}
            type="password"
            value={values.password}
            variant="outlined"
            disabled={isSubmitting}
          />
          {errors.submit && (
            <Box sx={{ mt: 3 }}>
              <FormHelperText error>{errors.submit}</FormHelperText>
            </Box>
          )}
          <Box alignItems="flex-end" justifyContent="right" display="flex">
            <Button onClick={() => resetPassword()} size="small" type="button">
              Esqueci minha senha
            </Button>
          </Box>
          <Box sx={{ mt: 2 }}>
            <Button color="primary" disabled={isSubmitting} fullWidth size="large" type="submit" variant="contained">
              { isSubmitting ? <CircularProgress size={26} /> : 'Login' }
            </Button>
          </Box>
        </form>
      )}
    </Formik>
  );
};

export default LoginJWT;
