import { EnterpriseInterface } from 'src/pages/enterprises/types/EnterpriseInterface';
import { getProviderOrCustomerFromParams } from '../utils/getProviderOrCustomerFromParams';
import { axiosPOST, CustomAxiosResponse } from './../../../services/axiosService';
import { BusinessContactInterface } from './../types/BusinessContactInterface';

interface BussinessContactCreate extends Omit<BusinessContactInterface, 'address'> {
  address: {
    formatted_address?: string;
    complement?: string;
  };
}
export interface CreateBusinessContactInteface extends Partial<BussinessContactCreate> {}

export interface CreateBusinessServiceInterface {
  businessContactToBeCreated: CreateBusinessContactInteface;
  selectedEnterprise: EnterpriseInterface | undefined;
  customerOrProvider: string;
}

export const createBusinessContact = async (
  obj: CreateBusinessServiceInterface,
): Promise<CustomAxiosResponse<BusinessContactInterface>> => {
  try {
    const { businessContactToBeCreated, selectedEnterprise, customerOrProvider } = obj;
    const urlParam = getProviderOrCustomerFromParams(customerOrProvider);
    const endpoint = `${process.env.REACT_APP_BACKEND}/business_contact/${urlParam}/${selectedEnterprise?.uuid}`;

    const response = await axiosPOST<BusinessContactInterface>(endpoint, businessContactToBeCreated);

    return {
      ...response,
      data: response.data,
      message: `${urlParam === 'customer' ? 'Cliente' : 'Fornecedor'} criado com sucesso!`,
    };
  } catch (error: any) {
    throw new Error(error?.message ? error.message : 'Ocorreu um erro, tente novamente.');
  }
};
