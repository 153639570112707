import { useRef, useState } from 'react';
import type { FC } from 'react';
import {
  Avatar,
  Badge,
  Box,
  Button,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Popover,
  Tooltip,
  Typography,
} from '@material-ui/core';
import BellIcon from '../../icons/Bell';

interface Notification {
  id: string;
  title: string;
  description: string;
  type: string;
  createdAt: number;
}

const notifications: Notification[] = [];

const NotificationsPopover: FC = () => {
  const anchorRef = useRef<HTMLButtonElement | null>(null);
  const [open, setOpen] = useState<boolean>(false);

  const handleOpen = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  return (
    <>
      <Tooltip title="Notificações">
        <IconButton color="inherit" ref={anchorRef} onClick={handleOpen}>
          <Badge color="error" badgeContent={notifications.length}>
            <BellIcon fontSize="small" />
          </Badge>
        </IconButton>
      </Tooltip>
      <Popover
        anchorEl={anchorRef.current}
        anchorOrigin={{
          horizontal: 'center',
          vertical: 'bottom',
        }}
        onClose={handleClose}
        open={open}
        PaperProps={{
          sx: { width: 320 },
        }}
      >
        <Box sx={{ p: 2 }}>
          <Typography color="textPrimary" variant="h6">
            Notificações
          </Typography>
        </Box>
        {notifications.length === 0 ? (
          <Box sx={{ p: 2 }}>
            <Typography color="textPrimary" variant="subtitle2">
              Não há notificações
            </Typography>
          </Box>
        ) : (
          <>
            <List disablePadding>
              {notifications.map((notification) => (
                <ListItem divider key={notification.id}>
                  <ListItemAvatar>
                    <Avatar
                      sx={{
                        backgroundColor: 'primary.main',
                        color: 'primary.contrastText',
                      }}
                    />
                  </ListItemAvatar>
                  <ListItemText
                    primary={
                      <Link color="textPrimary" sx={{ cursor: 'pointer' }} underline="none" variant="subtitle2">
                        {notification.title}
                      </Link>
                    }
                    secondary={notification.description}
                  />
                </ListItem>
              ))}
            </List>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                p: 1,
              }}
            >
              <Button color="primary" size="small" variant="text">
                Marcar tudo como lido
              </Button>
            </Box>
          </>
        )}
      </Popover>
    </>
  );
};

export default NotificationsPopover;
