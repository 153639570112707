import { IBankIntegration } from '../interfaces/BankIntegration.interface';
import { axiosPATCH, CustomAxiosResponse } from './../../../services/axiosService';

interface PaymentEditProps {
  integrationUuid: string;
  enterpriseUuid: string;
  integration: Partial<IBankIntegration>
}

export const editBankIntegration = async ({
  integrationUuid,
  enterpriseUuid,
  integration,
}: PaymentEditProps): Promise<CustomAxiosResponse<IBankIntegration>> => {
  try {
    const endpoint = `${process.env.REACT_APP_BACKEND}/open-banking/integrations/${integrationUuid}`;

    const body: Partial<IBankIntegration> = {
      ...integration,
      uuid: integrationUuid,
    }

    const response = await axiosPATCH<IBankIntegration>(endpoint, body);

    return {
      ...response,
      data: response.data,
      message: 'A integração foi editada com sucesso.',
    };
  } catch (error: any) {
    throw new Error(error?.message ? error.message : 'Ocorreu um erro, tente novamente.');
  }
};
