import { UseQueryResult } from 'react-query';
import { CustomAxiosResponse } from 'src/services/axiosService';
import { MovementInterface } from '../../types/MovementInterface';
import { FormType, HANDLE_DRAWER_VISIBILITY, HANDLE_CALENDAR_VISIBILITY } from './actions/handleDrawerVisibilityAction';
import { UPDATE_STATE } from './actions/updateStateAction';

export type UseMovementQueryType = UseQueryResult<CustomAxiosResponse<MovementInterface[]>, unknown> | undefined;
export interface MovementContextInterface {
  isDrawerOpen: boolean;
  isCalendarOpen: boolean;
  movements: UseMovementQueryType | undefined;
  formType?: FormType;
}

export type MovementAction = UPDATE_STATE | HANDLE_DRAWER_VISIBILITY | HANDLE_CALENDAR_VISIBILITY;

export enum MovementTypesEnum {
  UPDATE_STATE,
  HANDLE_DRAWER_VISIBILITY,
  HANDLE_CALENDAR_VISIBILITY,
}
