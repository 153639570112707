import { TextField } from '@material-ui/core';
import {
  Control,
  DeepMap,
  DeepPartial,
  FieldError,
  RegisterOptions,
  useController,
  UseControllerProps,
} from 'react-hook-form';

/**
 * Extract from MDN docs
 *
 * @link https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input#Form_%3Cinput%3E_types
 */
type FormInputTypes =
  | 'button'
  | 'checkbox'
  | 'color'
  | 'date'
  | 'datetime-local'
  | 'email'
  | 'file'
  | 'hidden'
  | 'image'
  | 'month'
  | 'number'
  | 'password'
  | 'radio'
  | 'range'
  | 'search'
  | 'submit'
  | 'tel'
  | 'text'
  | 'time'
  | 'url'
  | 'week';

interface PercentageFieldControllerInterface extends Omit<UseControllerProps<any, any>, 'control' | 'rules'> {
  placeholder?: string;
  control: Control<any>;
  rules: Omit<RegisterOptions<any, any>, 'valueAsNumber' | 'valueAsDate' | 'setValueAs'>;
  label?: string;
  disabled?: boolean;
  readonly?: boolean;
  variant?: 'filled' | 'outlined' | 'standard';
  helperMessage?: string;
  mask?: MaskInterface;
  type?: FormInputTypes;
  inputComponent?: any;
  multiline?: boolean;
  rows?: number;
  rowsMax?: number;
  size?: 'small' | 'medium';
  currency?: 'none' | 'cents' | 'float';
  isBill?: boolean;
  maskChar?: boolean | undefined;
  style?: any;
  endIcon?: any;
}

/**
 * @param mask for numbers, use "9", for letters use "a", for everything use "*"
 * @param maskChar Character to cover unfilled parts of the mask.
 *    Default character is "_". If set to null or empty string, unfilled parts
 *    will be empty as in ordinary input.
 * @param formatChars Defines format characters with characters as a keys and
 *    corresponding RegExp strings as a values
 * @param alwaysShowMask Show mask when input is empty and has no focus
 * @param inputRef Use inputRef instead of ref if you need input node to manage
 *    focus, selection, etc.
 *
 */
interface MaskInterface {
  mask: string;
  formatChars?: {};
  maskChar?: string;
  alwaysShowMask?: boolean;
  inputRef?: Function;
}

export const PercentageFieldController: React.FC<PercentageFieldControllerInterface> = ({
  control,
  name,
  rules,
  defaultValue,
  placeholder,
  label,
  helperMessage,
  disabled = false,
  readonly = false,
  type,
  variant = 'outlined',
  multiline = false,
  rows = 1,
  size = 'medium',
  style,
  endIcon,
}) => {
  const {
    field: { ref, onChange, ...fieldProps },
    fieldState: { error },
    formState: { isSubmitting },
  } = useController({
    name,
    control,
    rules,
    defaultValue,
  });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    const formattedValue = value.replace(/\D/g, '')
    .replace(/(\d{2})(\d)/, '$1.$2')
    .replace(/(.\d{2})\d+?$/, '$1');
    onChange(formattedValue);
  };

  return (
    <TextField
      key={`field-name-${fieldProps.name}`}
      inputRef={ref}
      id={`field-${name}`}
      placeholder={placeholder || ''}
      defaultValue={defaultValue}
      label={label}
      InputLabelProps={{ shrink: !!fieldProps.value }}
      disabled={isSubmitting || disabled}
      variant={variant}
      fullWidth
      type={type || 'text'}
      size={size}
      error={!!error}
      helperText={getHelperMessage(error || helperMessage)}
      multiline={multiline}
      rows={rows}
      style={style}
      InputProps={{
        endAdornment: endIcon && endIcon,
        readOnly: readonly,
      }}
      onChange={handleChange}
      value={fieldProps.value}
    />
  );
};

export function getHelperMessage(
  message: string | FieldError | DeepMap<DeepPartial<any>, FieldError> | undefined,
): string | undefined {
  if (!message) return undefined;
  if (typeof message === 'string') return message;
  if (message?.message) return message.message;

  const DEFAULT_MSG = 'Ooops, campo inválido!';

  switch (message?.type) {
    case 'max':
      return 'O campo ultrapassou o limite máximo de caracteres';

    case 'maxLength':
      return 'O campo ultrapassou o tamanho máximo';

    case 'min':
      return 'O campo não atingiu o limite mínimo de caracteres';

    case 'minLength':
      return 'O campo não atingiu o tamanho mínimo';

    case 'pattern':
      return 'O campo não segue o padrão necessário';

    case 'required':
      return 'O campo é obrigatório';

    case 'setValueAs':
      return DEFAULT_MSG;

    case 'shouldUnregister':
      return DEFAULT_MSG;

    case 'validate':
      return DEFAULT_MSG;

    case 'value':
      return DEFAULT_MSG;

    case 'valueAsDate':
      return 'O campo precisa ser uma data';

    case 'valueAsNumber':
      return 'O campo precisa ser um número';

    default:
      return DEFAULT_MSG;
  }
}
