import { Box, Switch, Typography } from '@material-ui/core';
import React from 'react';
import { useDashboard } from 'src/pages/dashboard/hooks/useDashboard';

interface BalanceTypeChangeProps {}

export enum BalanceType {
  realizado,
  previsto,
}

const BalanceTypeChangeSwitch: React.FC<BalanceTypeChangeProps> = () => {
  const { selectBalanceType, toggleSelectBalanceType } = useDashboard();

  return (
    <Box display="flex" justifyContent="center" alignContent="center" width="100%">
      <Typography variant="caption" mr={0.25}>
        Exibir previstos
      </Typography>
      <Box mt={-1}>
        <Switch
          checked={selectBalanceType === BalanceType.previsto}
          onChange={toggleSelectBalanceType}
          color="primary"
          required={false}
        />
      </Box>
    </Box>
  );
};

export default BalanceTypeChangeSwitch;
