import { format, set } from 'date-fns';
import { DEFAULT_LOCALE } from '../config';
import { END_TIME, START_TIME } from '../pages/bills/context/BillsContext';

const FORMAT = 'yyyy-MM-dd HH:mm:ss';

export const formatedStartDate = (date: Date) =>
  format(set(new Date(date), START_TIME), FORMAT, { locale: DEFAULT_LOCALE });

export const formatedEndDate = (date: Date) =>
  format(set(new Date(date), END_TIME), FORMAT, { locale: DEFAULT_LOCALE });
