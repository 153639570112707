import { EnterpriseInterface } from '../../../types/EnterpriseInterface';
import { EnterpriseTypesEnum, EnterpriseContextInterface } from './../EnterpriseInterface';

export type UPDATE_ENTERPRISE_AVAILABLE = {
  type: EnterpriseTypesEnum.UPDATE_ENTERPRISE_AVAILABLE;
  enterprises: EnterpriseInterface[];
};

export function updateEnterpriseAvailableAction(
  state: EnterpriseContextInterface,
  action: UPDATE_ENTERPRISE_AVAILABLE,
): EnterpriseContextInterface {
  return {
    ...state,
    availableEnterprise: action.enterprises,
  };
}
