import { capitalizeFirstLetter, isPlural } from '../../../utils/stringHelper';

interface FormatClientProviderLabelInterface {
  singular: string;
  plural: string;
  lowercasePlural: string;
  lowercaseSingular: string;
}

const isProvider = (str: string) => str.includes('Forneced');

export function formatClientProviderLabel(str: string | undefined): FormatClientProviderLabelInterface {
  if (str === undefined)
    return {
      plural: '',
      singular: '',
      lowercasePlural: '',
      lowercaseSingular: '',
    };

  const capitalized = capitalizeFirstLetter(str);
  const isStrPlural = isPlural(capitalized);

  const isProviderBool = isProvider(capitalized);

  const pluralProvider = `${capitalized}es`;
  const pluralClient = `${capitalized}s`;
  const singularProvider = capitalized.substr(0, capitalized.length - 2);
  const singularClient = capitalized.substr(0, capitalized.length - 1);

  const plural = isStrPlural ? capitalized : isProviderBool ? pluralProvider : pluralClient;

  const singular = isStrPlural ? (isProviderBool ? singularProvider : singularClient) : capitalized;

  return {
    plural,
    singular,
    lowercasePlural: plural.toLowerCase(),
    lowercaseSingular: singular.toLowerCase(),
  };
}
