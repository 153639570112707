import { axiosGET, CustomAxiosResponse } from '../../../services/axiosService';
import { MovimentTypesInterface } from '../types/MovementInterface';

export const listAllMovementsTypes = async (): Promise<CustomAxiosResponse<MovimentTypesInterface[]>> => {
  try {
    const endpoint = `${process.env.REACT_APP_BACKEND}/movement/types`;

    const response = await axiosGET<MovimentTypesInterface[]>(endpoint);

    return {
      ...response,
      data: response.data,
      message: 'Pronto!',
    };
  } catch (error: any) {
    throw new Error(error?.message ? error.message : 'Ocorreu um erro, tente novamente.');
  }
};
