import { BillInterface } from "src/pages/bills/types/BillInterface";
import { axiosGET, axiosPOST, CustomAxiosResponse } from "src/services/axiosService";

export type IPluggyToken = string;

export interface IPluggyTokenResponse {
}

export interface IPluggyTokenRequest {
  itemId: string;
  enterpriseUuid: string;
}

export const createPluggyItem = async (
  body: IPluggyTokenRequest,
): Promise<CustomAxiosResponse<IPluggyTokenResponse>> => {
  try {
    const endpoint = `${process.env.REACT_APP_BACKEND}/open-banking/integrations`;

    const response = await axiosPOST<IPluggyTokenResponse>(endpoint, body);

    return {
      ...response,
      data: response.data,
      message: 'Pronto!',
    };
  } catch (error: any) {
    throw new Error(error?.message ? error.message : 'Ocorreu um erro, tente novamente.');
  }
};
