import React, { createContext, useState } from 'react';

interface UserProviderInterface {
  userPlan: number;
  isProModalOpen: boolean;
  setIsProModalOpen: (state: boolean) => void;
  setUserPlan: (state: number) => void;
}

export const UserContext = createContext<UserProviderInterface>({} as UserProviderInterface);

const UserProvider: React.FC = ({ children }) => {
  const [userPlan, setUserPlan] = useState<number>(1);
  const [isProModalOpen, setIsProModalOpen] = useState<boolean>(false);

  const globals = {
    isProModalOpen,
    setIsProModalOpen,
    userPlan,
    setUserPlan,
  };

  return <UserContext.Provider value={globals}>{children}</UserContext.Provider>;
};

export default UserProvider;
