import { TextField } from '@material-ui/core';
import {
  Control,
  DeepMap,
  DeepPartial,
  FieldError,
  RegisterOptions,
  useController,
  UseControllerProps,
} from 'react-hook-form';
/**
 * Extract from MDN docs
 *
 * @link https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input#Form_%3Cinput%3E_types
 */

interface TextfieldControllerInterface extends Omit<UseControllerProps<any, any>, 'control' | 'rules'> {
  placeholder?: string;
  control: Control<any>;
  rules: Omit<RegisterOptions<any, any>, 'valueAsNumber' | 'valueAsDate' | 'setValueAs'>;
  label?: string;
  disabled?: boolean;
  readonly?: boolean;
  variant?: 'filled' | 'outlined' | 'standard';
  helperMessage?: string;
  size?: 'small' | 'medium';
  mask: 'cpf' | 'cnpj' | 'cpfCnpj';
}

/**
 * @param mask for numbers, use "9", for letters use "a", for everything use "*"
 * @param maskChar Character to cover unfilled parts of the mask.
 *    Default character is "_". If set to null or empty string, unfilled parts
 *    will be empty as in ordinary input.
 * @param formatChars Defines format characters with characters as a keys and
 *    corresponding RegExp strings as a values
 * @param alwaysShowMask Show mask when input is empty and has no focus
 * @param inputRef Use inputRef instead of ref if you need input node to manage
 *    focus, selection, etc.
 *
 */

export const InputDocTypeController: React.FC<TextfieldControllerInterface> = ({
  control,
  name,
  rules,
  defaultValue,
  placeholder,
  label,
  helperMessage,
  disabled = false,
  readonly = false,
  variant = 'outlined',
  size = 'medium',
  mask,
  ...props
}) => {
  const {
    field: { ref, onChange, ...fieldProps },
    fieldState: { error },
    formState: { isSubmitting },
  } = useController({
    name,
    control,
    rules,
    defaultValue,
  });

  function formatCPF(value: string) {
    const onlyLetters = value.replace(/[^0-9]/g, '');
    return onlyLetters
      .replace(/\D/g, '')
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d{1,2})/, '$1-$2')
      .replace(/(-\d{2})\d+?$/, '$1');
  }

  function formatCNPJ(value: string) {
    const onlyLetters = value.replace(/[^0-9]/g, '');
    return onlyLetters
      .replace(/\D/g, '')
      .replace(/(\d{2})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d)/, '$1/$2')
      .replace(/(\d{4})(\d)/, '$1-$2')
      .replace(/(-\d{2})\d+?$/, '$1');
  }

  function validateMask(value: string) {
    return value.length > 11 ? formatCNPJ(value) : formatCPF(value);
  }

  function formatCorrectDoc(value: string, inputMask: string) {
    const onlyValue = value.replace(/\D+/g, '');
    switch (inputMask) {
      case 'cpf':
        return formatCPF(onlyValue);
      case 'cnpj':
        return formatCNPJ(onlyValue);
      case 'cpfCnpj':
        return validateMask(onlyValue);
      default:
        return validateMask(onlyValue);
    }
  }

  return (
    <TextField
      key={`field-name-${fieldProps.name}`}
      inputRef={ref}
      id={`field-${name}`}
      placeholder={placeholder || ''}
      defaultValue={defaultValue}
      label={label}
      InputLabelProps={{ shrink: !!fieldProps.value }}
      disabled={isSubmitting || disabled}
      variant={variant}
      fullWidth
      type="text"
      size={size}
      error={!!error}
      helperText={getHelperMessage(error || helperMessage)}
      InputProps={{
        // @ts-ignore
        readOnly: readonly,
        onChange: (e) => {
          const { value } = e.target;
          onChange(formatCorrectDoc(value, mask));
        },
      }}
      {...props}
      {...fieldProps}
      value={formatCorrectDoc(fieldProps.value, mask)}
    />
  );
};

export function getHelperMessage(
  message: string | FieldError | DeepMap<DeepPartial<any>, FieldError> | undefined,
): string | undefined {
  if (!message) return undefined;
  if (typeof message === 'string') return message;
  if (message?.message) return message.message;

  const DEFAULT_MSG = 'Ooops, campo inválido!';

  switch (message?.type) {
    case 'max':
      return 'O campo ultrapassou o limite máximo de caracteres';

    case 'maxLength':
      return 'O campo ultrapassou o tamanho máximo';

    case 'min':
      return 'O campo não atingiu o limite mínimo de caracteres';

    case 'minLength':
      return 'O campo não atingiu o tamanho mínimo';

    case 'pattern':
      return 'O campo não segue o padrão necessário';

    case 'required':
      return 'O campo é obrigatório';

    case 'setValueAs':
      return DEFAULT_MSG;

    case 'shouldUnregister':
      return DEFAULT_MSG;

    case 'validate':
      return DEFAULT_MSG;

    case 'value':
      return DEFAULT_MSG;

    case 'valueAsDate':
      return 'O campo precisa ser uma data';

    case 'valueAsNumber':
      return 'O campo precisa ser um número';

    default:
      return DEFAULT_MSG;
  }
}
