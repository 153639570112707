import React, { useEffect, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useQuery } from 'react-query';
import {
  AutocompleteBusinessCreatable,
} from 'src/pages/businessContacts/forms/dynamicBusinessContactCreate/AutocompleteBusinessCreatable';
import { getAllBusinessContacts } from 'src/pages/businessContacts/services/getAllBusinessContacts';
import { CustomerOrProviderEnum } from 'src/pages/businessContacts/types/ProviderOrCustomer';
import { formatClientProviderLabel } from 'src/pages/businessContacts/utils/getFormattedProviderOrCustomerLabel';
import { getProviderOrCustomerParamsFromSlug } from 'src/pages/businessContacts/utils/getProviderOrCustomerFromParams';
import { EnterpriseInterface } from 'src/pages/enterprises/types/EnterpriseInterface';
import { MovimentTypesInterface, MovimentTypesSlugEnum } from 'src/pages/movements/types/MovementInterface';
import { BillInterface } from '../../types/BillInterface';

interface BillFormBusinessProps {
  isEditForm: boolean;
  billTypesData: MovimentTypesInterface[];
  selectedEnterprise: EnterpriseInterface | undefined;
  billToBeEdited: BillInterface | undefined;
}

export const BillFormBusiness: React.FC<BillFormBusinessProps> = ({
  isEditForm,
  billTypesData,
  selectedEnterprise,
  billToBeEdited,
}) => {
  const businessContactsCustomer = useQuery('getAllBusinessContacts-customer', () =>
    getAllBusinessContacts(selectedEnterprise, CustomerOrProviderEnum.customer),
  );

  const businessContactsProvider = useQuery('getAllBusinessContacts-provider', () =>
    getAllBusinessContacts(selectedEnterprise, CustomerOrProviderEnum.provider),
  );

  const businessContactCustomerData = useMemo(
    () => businessContactsCustomer?.data?.data || [],
    [businessContactsCustomer.data],
  );

  const businessContactProviderData = useMemo(
    () => businessContactsProvider?.data?.data || [],
    [businessContactsProvider.data],
  );

  const { control, watch, setValue } = useFormContext();
  const selectedId = watch('bill_types_uuid');

  const selected = useMemo(() => billTypesData?.find(({ uuid }) => uuid === selectedId), [billTypesData, selectedId]);

  const customersAndProviders = useMemo(
    () =>
      selected?.slug === MovimentTypesSlugEnum.invoice ? businessContactCustomerData : businessContactProviderData,
    [businessContactCustomerData, businessContactProviderData, selected],
  );

  const businessContact = useMemo(
    () =>
      customersAndProviders.map(({ fantasy_name, uuid }) => ({
        key: uuid,
        label: fantasy_name,
      })),
    [customersAndProviders],
  );

  const businessContactUuid = watch('business_contact_uuid');

  const formatedDefaultBusiness = billToBeEdited &&
    {
      key: billToBeEdited?.business_contacts?.uuid,
      label: billToBeEdited?.business_contacts?.fantasy_name,
    };

  const selectedBusinessContact = useMemo(() =>
    businessContact.find((business) => business.key === businessContactUuid),
    [businessContactUuid, businessContact],
  );

  useEffect(() => {
    if (billToBeEdited?.business_contacts) {
      setValue('business_contact_uuid', billToBeEdited.business_contacts.uuid);
    }
  }, [billToBeEdited?.business_contacts, setValue]);

  const customerOrProvider = getProviderOrCustomerParamsFromSlug(selected?.slug);

  const formattedLabel = formatClientProviderLabel(customerOrProvider);

  return (
    <AutocompleteBusinessCreatable
      control={control}
      rules={{ required: !isEditForm }}
      name="business_contact_uuid"
      size="small"
      label={`Selecione um ${formattedLabel.singular}`}
      customerOrProvider={customerOrProvider}
      values={businessContact}
      onSuccess={() => {
        businessContactsCustomer.refetch();
        businessContactsProvider.refetch();
      }}
      setValue={setValue}
      defaultValue={billToBeEdited ? formatedDefaultBusiness : selectedBusinessContact}
    />
  );
};

export default BillFormBusiness;
