import { Box, IconButton, List } from '@material-ui/core';
import { Add } from '@material-ui/icons';
import React, { useEffect, useMemo } from 'react';
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import { useQuery } from 'react-query';
import EmptyPlaceholder from 'src/components/layouts/EmptyPlaceholder';
import TitleSection from 'src/components/TitleSection';
import { useEnterprise } from 'src/pages/enterprises/hooks/useEnterprise';
import ItemForm from 'src/pages/movements/forms/ItemForm';
import { getAllItemTags } from 'src/pages/tags/itemTags/services/getAllItemTags';
import { convertFormattedMoneyToCents } from 'src/utils/moneyConverter';
import { CreateBillInteface } from '../../services/createBill';

interface BillFormProductsOrServicesProps {
  isEditForm: boolean;
}

export const BillFormProductsOrServices: React.FC<BillFormProductsOrServicesProps> = ({ isEditForm }) => {
  const { selectedEnterprise } = useEnterprise();
  const { control, watch, setValue, register } = useFormContext<CreateBillInteface>();
  const fieldArray = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: 'bill_items',
  });
  const { fields, append } = fieldArray;
  const watchFieldArray = watch('bill_items');
  const totalInstallments = watch('installments');
  const controlledFields = fields.map((field, index) => ({
    ...field,
    ...watchFieldArray[index],
  }));

  const itemTagsQuery = useQuery('itemTags', () => getAllItemTags(selectedEnterprise?.uuid || ''));

  const itemTagData = useMemo(() => itemTagsQuery?.data?.data || [], [itemTagsQuery.data]);

  const itemTagOptions = useMemo(
    () =>
      itemTagData.map(({ title, uuid }) => ({
        key: uuid,
        label: title,
      })),
    [itemTagData],
  );

  const billItemsArray = useWatch({
    name: 'bill_items',
    control,
  });

  useEffect(() => {
    if (totalInstallments && billItemsArray.length > 0) {
      const installmentValue = billItemsArray.reduce(
        (acc, currentValue) =>
          // eslint-disable-next-line no-param-reassign
          (acc += convertFormattedMoneyToCents(currentValue.unit_price) * Number(currentValue.quantity)),
        0,
      );
      const totalValue = Number(totalInstallments) * installmentValue;
      setValue('totalValue', String(totalValue));
      setValue('installmentValue', String(installmentValue));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalInstallments, billItemsArray]);

  return (
    <>
      <TitleSection
        title="Produtos/Serviços"
        button={
          <IconButton
            color="inherit"
            onClick={() =>
              append({
                name: '',
                description: '',
                quantity: 1,
                unit_price: 0,
                total: 0,
                item_tag_uuid: '',
              })
            }
          >
            <Add fontSize="small" />
          </IconButton>
        }
        tooltip={[
          'Neste campo, você irá registrar produtos e/ou serviços. que geram faturamento ou despesa.',
          'Sabe o que é legal? Você poderá gerenciá-los depois!',
          'Para criar uma conta, você precisa adicionar pelo menos um item!',
        ]}
      />

      <List disablePadding component="nav" aria-labelledby="nested-list-subheader" style={{ width: '100%' }}>
        {fields.length > 0 ? (
          <>
            {controlledFields.map((field, index) => (
              <Box mb={1}>
                <ItemForm
                  index={index}
                  field={field}
                  fieldArray={fieldArray}
                  control={control}
                  watch={watch}
                  type="bill"
                  tags={itemTagOptions}
                  tagQuery={itemTagsQuery}
                  setValue={setValue}
                  register={register}
                  isEditForm={isEditForm}
                  shouldBeOpen={!isEditForm && index === 0}
                />
              </Box>
            ))}
          </>
        ) : (
          <EmptyPlaceholder
            showSvg={false}
            title="Nenhum item"
            variant="h6"
            subtitle="Clique no botão + para adicionar"
          />
        )}
      </List>
    </>
  );
};

export default BillFormProductsOrServices;
