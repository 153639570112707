import { BillShouldSeattleEnum, MovimentTypesSlugEnum } from 'src/pages/movements/types/MovementInterface';
import { MovementContextInterface, MovementTypesEnum } from '../MovementInterface';

export type FormType = MovimentTypesSlugEnum;

export type HANDLE_DRAWER_VISIBILITY = {
  type: MovementTypesEnum.HANDLE_DRAWER_VISIBILITY;
  open: boolean;
  formType?: FormType;
};

export type HANDLE_CALENDAR_VISIBILITY = {
  type: MovementTypesEnum.HANDLE_CALENDAR_VISIBILITY;
  open: boolean;
  formType?: FormType;
};

export function handleDrawerVisibilityAction(
  state: MovementContextInterface,
  action: HANDLE_DRAWER_VISIBILITY,
): MovementContextInterface {
  return {
    ...state,
    isDrawerOpen: action.open,
    formType: action.formType,
  };
}
