import type { FC, ReactNode } from 'react';
import { useState } from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { useEnterprise } from '../hooks/useEnterprise';
import SelectedEnterprisePage from '../CreateNewEnterprisePage';
import SelectEnterprise from '../SelectEnterprise';

interface EnterpriseGuardProps {
  children: ReactNode;
}

const EnterpriseGuard: FC<EnterpriseGuardProps> = (props) => {
  const navigate = useNavigate();
  const { children } = props;
  const { hasSelectedEnterprise, availableEnterprises } = useEnterprise();
  const location = useLocation();
  const [requestedLocation, setRequestedLocation] = useState<string | null>('/dashboard/empresas/selecionar');

  if (availableEnterprises.length === 0) {
    if (location.pathname !== requestedLocation) {
      setRequestedLocation(location.pathname);
      navigate('/empresas/criar-nova-empresa');
    }

    return <SelectedEnterprisePage />;
  }

  if (!hasSelectedEnterprise) {
    if (location.pathname !== requestedLocation) {
      setRequestedLocation(location.pathname);
      navigate('/empresas/selecionar');
    }

    return <SelectEnterprise />;
  }

  // This is done so that in case the route changes by any chance through other
  // means between the moment of request and the render we navigate to the initially
  // requested route.
  if (requestedLocation && location.pathname !== requestedLocation) {
    setRequestedLocation(null);
    return <Navigate to={requestedLocation} />;
  }

  return <>{children}</>;
};

export default EnterpriseGuard;
