import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';

export type Endpoint = string;
export type Body = Record<string, unknown>;
export type Token = string | undefined;
export type Otherheaders = AxiosRequestConfig;

export const AUTH_TOKEN = 'accessToken';

export enum VariantEnum {
  default = 'info',
  error = 'error',
  warning = 'warning',
  info = 'info',
  success = 'success',
}

export interface CustomAxiosResponse<T> extends AxiosResponse<T> {
  message: string[] | string;
  variant: VariantEnum;
}

export interface CustomAxiosErrorResponse {
  statusCode: number;
  message: string[] | string;
  error: string;
}

axios.interceptors.response.use(
  function (response) {
    if (response.status === 401) {
      window.localStorage.clear();
      window.location.reload();
    }
    return response;
  },
  function (error) {
    return Promise.reject(error);
  },
);

/**
 * A facade axios PUT implementation
 *
 * @param {string} endpoint the endpoint to be called on request
 * @param {object} body the body of the request
 * @param {object} otherHeaders use this if there is more headers than Authorization. Default: {}
 */
export const axiosPUT = async <T>(
  endpoint: Endpoint,
  body: any,
  otherHeaders: Otherheaders = {},
): Promise<CustomAxiosResponse<T>> => {
  try {
    const token = window.localStorage.getItem(AUTH_TOKEN);

    const response = await axios.put<T | CustomAxiosErrorResponse>(endpoint, body, {
      validateStatus,
      headers: {
        Authorization: `Bearer ${token}`,
        ...otherHeaders,
      },
    });

    const succeed = checkIfRequestWasSuccessful(response.status);
    if (!succeed) throw handleError(response);

    return getResponseObj(response);
  } catch (error: unknown) {
    throw handleErrorCatch(error);
  }
};

/**
 * A facade axios PUT implementation
 *
 * @param {string} endpoint the endpoint to be called on request
 * @param {object} body the body of the request
 * @param {object} otherHeaders use this if there is more headers than Authorization. Default: {}
 */
export const axiosPATCH = async <T>(
  endpoint: Endpoint,
  body: any,
  otherHeaders: Otherheaders = {},
): Promise<CustomAxiosResponse<T>> => {
  try {
    const token = window.localStorage.getItem(AUTH_TOKEN);

    const response = await axios.patch<T | CustomAxiosErrorResponse>(endpoint, body, {
      validateStatus,
      headers: {
        Authorization: `Bearer ${token}`,
        ...otherHeaders,
      },
    });

    const succeed = checkIfRequestWasSuccessful(response.status);
    if (!succeed) throw handleError(response);

    return getResponseObj(response);
  } catch (error: unknown) {
    throw handleErrorCatch(error);
  }
};

/**
 * Use to get
 *
 * @param {string} endpoint the endpoint to be called on request
 * @param {object} otherHeaders use this if there is more headers than Authorization. Default: {}
 */
export const axiosGET = async <T>(
  endpoint: Endpoint,
  otherHeaders: Otherheaders = {},
): Promise<CustomAxiosResponse<T>> => {
  try {
    const token = window.localStorage.getItem(AUTH_TOKEN);

    const response = await axios.get<T | CustomAxiosErrorResponse>(endpoint, {
      validateStatus,
      headers: {
        Authorization: `Bearer ${token}`,
        ...otherHeaders,
      },
    });

    const succeed = checkIfRequestWasSuccessful(response.status);
    if (!succeed) throw handleError(response);

    return getResponseObj(response);
  } catch (error: unknown) {
    throw handleErrorCatch(error);
  }
};

export const axiosPOST = async <T>(
  endpoint: Endpoint,
  body: any,
  otherHeaders: Otherheaders = {},
): Promise<CustomAxiosResponse<T>> => {
  try {
    const token = window.localStorage.getItem(AUTH_TOKEN);

    const response = await axios.post<T | CustomAxiosErrorResponse>(endpoint, body, {
      validateStatus,
      headers: {
        ...otherHeaders.headers,
        Authorization: `Bearer ${token}`,
      },
    });

    const succeed = checkIfRequestWasSuccessful(response.status);
    if (!succeed) throw handleError(response);

    return getResponseObj(response);
  } catch (error: unknown) {
    throw handleErrorCatch(error);
  }
};

/**
 * Use to delete
 *
 * @param {string} endpoint the endpoint to be called on request
 * @param {object} otherHeaders use this if there is more headers than Authorization. Default: {}
 * @param {object} otherData
 */
export const axiosDelete = async <T>(
  endpoint: Endpoint,
  otherHeaders: Otherheaders = {},
  otherData: object = {},
): Promise<CustomAxiosResponse<T>> => {
  try {
    const token = window.localStorage.getItem(AUTH_TOKEN);

    const response = await axios.delete<T | CustomAxiosErrorResponse>(endpoint, {
      validateStatus,
      headers: {
        Authorization: `Bearer ${token}`,
        ...otherHeaders,
      },
      data: otherData,
    });

    const succeed = checkIfRequestWasSuccessful(response.status);
    if (!succeed) throw handleError(response);

    return getResponseObj(response);
  } catch (error: unknown) {
    throw handleErrorCatch(error);
  }
};

export function checkIfRequestWasSuccessful(status: number): boolean {
  return status >= 200 && status < 300;
}

function handleError<T>(response: AxiosResponse<CustomAxiosErrorResponse | T>): void {
  const { message } = response.data as CustomAxiosErrorResponse;

  // check if status is 404
  if (response.status >= 404) {
    throw 'Ocorreu um erro desconhecido, tente novamente ou entre em contato com o suporte.';
  }

  // Check if message is an array and get the first element, otherwise return the message
  const messageToShow = Array.isArray(message) ? message : [message];

  throw messageToShow;
}

function validateStatus(status: number) {
  return status >= 200 && status < 500;
}

function getResponseObj<T>(response: AxiosResponse<CustomAxiosErrorResponse | T>): CustomAxiosResponse<T> {
  const succeed = checkIfRequestWasSuccessful(response.status);

  const variant: VariantEnum = succeed ? VariantEnum.success : VariantEnum.error;

  const _data = succeed ? (response.data as T) : undefined;

  return {
    ...response,
    message: ['Sucesso!'],
    config: response.config,
    data: _data as T,
    headers: response.headers,
    status: response.status,
    statusText: response.statusText,
    variant,
  };
}

function handleErrorCatch(error: unknown) {
  if (typeof error === 'string') throw new Error(error);

  if (Array.isArray(error)) throw error;

  throw new Error('Ooops, aconteceu um problema, tente novamente!');
}
