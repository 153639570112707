import { BillInterface } from "src/pages/bills/types/BillInterface";
import { axiosGET, CustomAxiosResponse } from "src/services/axiosService";

export type IPluggyToken = string;

export interface IPluggyTokenResponse {
  token: IPluggyToken;
}

export const generateOpenBankingToken = async (
  enterpriseUUID: string,
): Promise<CustomAxiosResponse<IPluggyTokenResponse>> => {
  try {
    const endpoint = `${process.env.REACT_APP_BACKEND}/open-banking/token`;

    const response = await axiosGET<IPluggyTokenResponse>(endpoint);

    return {
      ...response,
      data: response.data,
      message: 'Pronto!',
    };
  } catch (error: any) {
    throw new Error(error?.message ? error.message : 'Ocorreu um erro, tente novamente.');
  }
};
