import React, { createContext, useEffect } from 'react';
import { useQuery, UseQueryResult } from 'react-query';
import { CustomAxiosResponse } from 'src/services/axiosService';
import useLocalStorageReducer from 'src/hooks/useLocalStorageReducer';
import Reducer from './EnterpriseReducer';
import { enterpriseInitialState } from './EnterpriseInitializer';
import { EnterpriseAction, EnterpriseContextInterface, EnterpriseTypesEnum } from './EnterpriseInterface';
import { getAllEnterprises } from '../../services/getAllEnterprises';
import { EnterpriseInterface } from '../../types/EnterpriseInterface';

export type EnterpriseDispatchType = React.Dispatch<EnterpriseAction>;

interface EnterpriseProviderInterface {
  stateEnterprise: EnterpriseContextInterface;
  dispatchEnterprise: EnterpriseDispatchType;
  query: UseQueryResult<CustomAxiosResponse<EnterpriseInterface[]>, unknown> | undefined;
}

export const EnterpriseContext = createContext<EnterpriseProviderInterface>({
  stateEnterprise: enterpriseInitialState,
  dispatchEnterprise: () => console.warn('EnterpriseDispatch not ready'),
  query: undefined,
});

interface EnterpriseProviderMock {
  injectMock?: EnterpriseContextInterface;
}

const EnterpriseProvider: React.FC<EnterpriseProviderMock> = ({ children, injectMock }) => {
  const query = useQuery('getAllEnterprises', getAllEnterprises);
  const [stateEnterprise, dispatchEnterprise] = useLocalStorageReducer(
    'selected-enterprise',
    Reducer,
    enterpriseInitialState,
  );

  useEffect(() => {
    if (query.isLoading) return;
    dispatchEnterprise({
      type: EnterpriseTypesEnum.UPDATE_ENTERPRISE_AVAILABLE,
      enterprises: query?.data?.data || [],
    });
  }, [dispatchEnterprise, query.isLoading, query.data]);

  const globals = {
    stateEnterprise: injectMock || stateEnterprise,
    dispatchEnterprise,
    query,
  };

  return <EnterpriseContext.Provider value={globals}>{children}</EnterpriseContext.Provider>;
};

export default EnterpriseProvider;
