import { axiosPOST, CustomAxiosResponse } from './../../../services/axiosService';
import { EnterpriseInterface } from './../types/EnterpriseInterface';

export interface CreateEnterpriseInteface extends Partial<EnterpriseInterface> {}

export const createEnterprise = async (
  enterpriseToBeCreated: CreateEnterpriseInteface,
): Promise<CustomAxiosResponse<EnterpriseInterface>> => {
  try {
    const endpoint = `${process.env.REACT_APP_BACKEND}/enterprises`;

    const response = await axiosPOST<EnterpriseInterface>(endpoint, enterpriseToBeCreated);

    return response;
  } catch (error: unknown) {
    throw error;
  }
};
