import React from 'react';
import { useFormContext } from 'react-hook-form';
import { TextfieldController } from 'src/components/forms/TextfieldController';

interface BillFormInstallmentsProps {
  isEditForm: boolean;
}

export const BillFormInstallments: React.FC<BillFormInstallmentsProps> = ({ isEditForm }) => {
  const { control } = useFormContext();

  return (
    <TextfieldController
      control={control}
      disabled={isEditForm}
      name="installments"
      size="small"
      type="number"
      label="Qtd. parcelas"
      rules={{ required: false, min: 0, max: 999 }}
    />
  );
};

export default BillFormInstallments;
