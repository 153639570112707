/* eslint-disable @typescript-eslint/naming-convention */
import {
  Box,
  Card,
  Collapse,
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
} from '@material-ui/core';
import { Delete, ExpandLess, ExpandMore, FolderRounded } from '@material-ui/icons';
import React, { useEffect, useMemo, useState } from 'react';
import {
  Control,
  FieldArrayWithId,
  UseFieldArrayReturn,
  useForm,
  UseFormSetValue,
  UseFormWatch,
} from 'react-hook-form';
import { useQuery, UseQueryResult } from 'react-query';
import CurrencyInput from 'src/components/forms/MaskedTextField/types/Currency';
import { TextfieldController } from 'src/components/forms/TextfieldController';
import { CreateBillInteface } from 'src/pages/bills/services/createBill';
import { ItemTag } from 'src/pages/bills/types/BillInterface';
import { useEnterprise } from 'src/pages/enterprises/hooks/useEnterprise';
import {
  AutocompleteServiceItemCreate,
} from 'src/pages/service-item/forms/autocompleteInput/AutocompleteServiceItemCreate';
import { AutocompleteItemTagCreate } from 'src/pages/tags/itemTags/forms/autocompleteInput/AutocompleteItemTagCreate';
import { CustomAxiosResponse } from 'src/services/axiosService';
import { convertFormattedMoneyToCents, convertCentsToLocale } from '../../../utils/moneyConverter';
import { CreateMovementInteface } from '../services/createMovement';
import { getAllServiceItem } from '../../service-item/services/getAllServiceItem';

interface KeyLabel {
  key: string;
  label: string;
}

interface ItemFormInterface {
  control: Control<CreateMovementInteface, object> | Control<CreateBillInteface, object>;
  watch: UseFormWatch<CreateMovementInteface> | UseFormWatch<CreateBillInteface>;
  field:
    | FieldArrayWithId<CreateMovementInteface, 'items', 'id'>
    | FieldArrayWithId<CreateBillInteface, 'bill_items', 'id'>;
  fieldArray:
    | UseFieldArrayReturn<CreateMovementInteface, 'items', 'id'>
    | UseFieldArrayReturn<CreateBillInteface, 'bill_items', 'id'>;
  index: number;
  type: 'bill' | 'movement';
  tags: KeyLabel[];
  tagQuery: UseQueryResult<CustomAxiosResponse<ItemTag[]>, unknown>;
  setValue: UseFormSetValue<any>;
  register: any;
  shouldBeOpen: boolean;
  isEditForm: boolean;
}

export const ItemForm: React.FC<ItemFormInterface> = ({
  control,
  fieldArray,
  index,
  watch,
  type,
  tags,
  tagQuery,
  setValue,
  register,
  shouldBeOpen = false,
}) => {
  const { trigger } = useForm();
  const { selectedEnterprise } = useEnterprise();
  const allServicesItemsQuery = useQuery('servicesItems', () => getAllServiceItem(selectedEnterprise?.uuid || ''));
  const [open, setOpen] = useState<boolean>(shouldBeOpen);
  const [totalValueItem, setTotalValueItem] = useState(0);

  const servicesItems = useMemo(() => allServicesItemsQuery?.data?.data || [], [allServicesItemsQuery.data]);

  const servicesItemsOptions = useMemo(
    () =>
      servicesItems.map(({ name, uuid }) => ({
        key: uuid || '',
        label: name || '',
      })),
    [servicesItems],
  );

  const fieldName = type === 'bill' ? 'bill_items' : 'items';

  const {
    name,
    quantity,
    unit_price,
    item_tag,
    serviceItem_uuid,
    description,
    total,
    // @ts-ignore
  } = watch(fieldName)[index];

  const handleRemove = () => fieldArray.remove(index);

  const clearAllInputInformation = () => {
    setValue(`${fieldName}.${index}.description`, '');
    setValue(`${fieldName}.${index}.unit_price`, 0);
    setValue(`${fieldName}.${index}.name`, '');
  };

  useEffect(() => {
    const localUnitPrice = convertFormattedMoneyToCents(unit_price);
    const localQuantity = Number(quantity);
    const totalConverted = localUnitPrice * localQuantity;
    setTotalValueItem(totalConverted);
    setValue(`${fieldName}.${index}.total`, totalConverted);

    // fieldArray.update(index, {
    //   name,
    //   item_tag,
    //   unit_price: convertFormattedMoneyToCents(unit_price),
    //   quantity: Number(quantity),
    //   total: totalConverted,
    // });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quantity, unit_price]);

  useEffect(() => {
    if (serviceItem_uuid) {
      const selectedServiceItem = servicesItems.find((service) => service.uuid === serviceItem_uuid);

      setValue(
        `${fieldName}.${index}.description`, description || selectedServiceItem?.description || '',
      );
      trigger(`${fieldName}.${index}.description`);
      setValue(
        `${fieldName}.${index}.unit_price`, unit_price || selectedServiceItem?.value || 0,
      );
      setValue(`${fieldName}.${index}.name`, selectedServiceItem?.name);
      setValue(
        `${fieldName}.${index}.total`, total || selectedServiceItem?.value || 0 * quantity,
      );
    }
    // eslint-disable-next-line
  }, [serviceItem_uuid, servicesItems]);

  const renderUnitPrice = (value: string | number) => {
    if (typeof value === 'string') {
      return value;
    }

    return convertCentsToLocale(value);
  };

  return (
    <Card style={{ marginBottom: 0 }}>
      <ListItem button onClick={() => setOpen(!open)} selected={open}>
        <ListItemIcon>
          <FolderRounded />
        </ListItemIcon>
        <ListItemText
          primary={name || ''}
          secondary={
            <Box
              display="flex"
              flexDirection="row"
              alignItems="center"
              alignContent="center"
              justifyContent="space-between"
            >
              <Typography>{`${quantity}x ${renderUnitPrice(unit_price) || 'R$ 0,00'}`}</Typography>
              <Typography mr={3}>{convertCentsToLocale(totalValueItem)}</Typography>
            </Box>
          }
        />

        {open ? <ExpandLess /> : <ExpandMore />}

        {index > 0 && (
          <ListItemSecondaryAction>
            <IconButton edge="end" aria-label="delete" onClick={handleRemove}>
              <Delete />
            </IconButton>
          </ListItemSecondaryAction>
        )}
      </ListItem>
      <Collapse in={open} timeout="auto">
        <Box p={2}>
          <Box>
            <form autoComplete="off">
              <AutocompleteServiceItemCreate
                control={control}
                rules={{ required: true }}
                name={`${fieldName}.${index}.serviceItem_uuid`}
                size="small"
                label="Nome do item / serviço"
                values={servicesItemsOptions}
                setValue={setValue}
                defaultServiceItem={{ key: serviceItem_uuid, label: name }}
                onSuccess={() => allServicesItemsQuery.refetch()}
                clearAllInputInformation={clearAllInputInformation}
              />
              <Box display="flex" flexDirection="row">
                <Box mr={1} width="100%">
                  <TextfieldController
                    control={control}
                    type="number"
                    name={`${fieldName}.${index}.quantity`}
                    size="small"
                    variant="outlined"
                    label="Quantidade"
                    rules={{ required: false, min: 0, max: 999 }}
                    {...register(`${fieldName}.${index}.quantity`)}
                  />
                </Box>
                <Box mr={1} width="100%">
                  <TextfieldController
                    control={control}
                    name={`${fieldName}.${index}.unit_price`}
                    variant="outlined"
                    currency="cents"
                    size="small"
                    // label="Valor unitário"
                    // placeholder="R$"
                    inputComponent={CurrencyInput}
                    rules={{ required: false }}
                    {...register(`${fieldName}.${index}.unit_price`)}
                  />
                </Box>
                <Box width="100%">
                  <AutocompleteItemTagCreate
                    control={control}
                    rules={{ required: false }}
                    name={`${fieldName}.${index}.item_tag_uuid`}
                    size="small"
                    label="Tag"
                    values={tags}
                    onSuccess={(createdTag: string) => {
                      tagQuery.refetch();
                      setValue(`${fieldName}.${index}.item_tag_uuid`, createdTag);
                    }}
                    defaultTag={item_tag}
                  />
                </Box>
              </Box>
              <TextfieldController
                control={control}
                type="text"
                name={`${fieldName}.${index}.description`}
                variant="outlined"
                size="small"
                label="Descrição"
                rules={{ required: false }}
                {...register(`${fieldName}.${index}.description`)}
              />
            </form>
          </Box>
        </Box>
      </Collapse>
    </Card>
  );
};

export default ItemForm;
