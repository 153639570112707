import React from 'react';
import { useFormContext } from 'react-hook-form';
import { SelectController } from 'src/components/forms/SelectController';
import { PaymentProviderInterface } from 'src/pages/paymentOptions/types/PaymentOptionInterface';

interface BillFormPaymentProviderProps {
  isEditForm: boolean;
  paymentsProviders: PaymentProviderInterface[];
}

export const BillFormPaymentProvider: React.FC<BillFormPaymentProviderProps> = ({ isEditForm, paymentsProviders }) => {
  const { control } = useFormContext();

  return (
    <SelectController
      control={control}
      rules={{ required: false }}
      disabled={isEditForm}
      name="payment_provider_uuid"
      size="small"
      label="Provedor de pagamento"
      values={paymentsProviders.map(({ uuid, label, logo_square }) => ({
        key: uuid,
        avatar: logo_square,
        label,
      }))}
    />
  );
};

export default BillFormPaymentProvider;
