import { useContext } from 'react';
import { useNavigate } from 'react-router';
import { UseQueryResult } from 'react-query';
import { CustomAxiosResponse } from 'src/services/axiosService';
import { Avatar, EnterpriseInterface } from '../types/EnterpriseInterface';
import { EnterpriseTypesEnum } from '../contexts/EnterpriseContext/EnterpriseInterface';
import { EnterpriseContext } from '../contexts/EnterpriseContext/EnterpriseContext';

interface UseEnterpriseInterface {
  hasSelectedEnterprise: boolean;
  selectedEnterprise: EnterpriseInterface | undefined;
  availableEnterprises: EnterpriseInterface[];
  selectEnterprise: (enterprise: EnterpriseInterface) => void;
  addNewEnterprise: (enterprise: EnterpriseInterface) => void;
  updateEnterpriseInfo: (enterprise: EnterpriseInterface) => void;
  removeEnterpriseInfo: () => void;
  changeEnterpriseAvatar: (avatar: Avatar) => void;
  query: UseQueryResult<CustomAxiosResponse<EnterpriseInterface[]>, unknown> | undefined;
}

export function useEnterprise(): UseEnterpriseInterface {
  const navigate = useNavigate();
  const { stateEnterprise, dispatchEnterprise, query } = useContext(EnterpriseContext);

  return {
    hasSelectedEnterprise: !!stateEnterprise.selectedEnterprise,
    selectedEnterprise: stateEnterprise.selectedEnterprise,
    availableEnterprises: stateEnterprise.availableEnterprise,
    selectEnterprise: (enterprise: EnterpriseInterface) => {
      dispatchEnterprise({
        type: EnterpriseTypesEnum.SELECT_ENTERPRISE,
        enterprise,
      });
      navigate('/');
    },
    addNewEnterprise: (enterprise: EnterpriseInterface) => {
      dispatchEnterprise({
        type: EnterpriseTypesEnum.ADD_ENTERPRISE_TO_STATE,
        enterprise,
      });
    },
    changeEnterpriseAvatar: (avatar: Avatar) => {
      dispatchEnterprise({
        type: EnterpriseTypesEnum.CHANGE_ENTERPRISE_AVATAR,
        avatar,
      });
    },
    updateEnterpriseInfo: (enterprise: EnterpriseInterface) => {
      dispatchEnterprise({
        type: EnterpriseTypesEnum.UPDATE_ENTERPRISE_INFO,
        enterprise,
      });
    },
    removeEnterpriseInfo: () => {
      dispatchEnterprise({
        type: EnterpriseTypesEnum.REMOVE_ENTERPRISE_INFO,
      });
    },
    query,
  };
}
