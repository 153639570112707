import { MovementContextInterface, MovementTypesEnum } from '../MovementInterface';

export type HANDLE_CALENDAR_VISIBILITY = {
  type: MovementTypesEnum.HANDLE_CALENDAR_VISIBILITY;
  open: boolean;
};

export function handleCalendarVisibilityAction(
  state: MovementContextInterface,
  action: HANDLE_CALENDAR_VISIBILITY,
): MovementContextInterface {
  return {
    ...state,
    isCalendarOpen: action.open,
  };
}
