import { MovimentTypesSlugEnum } from 'src/pages/movements/types/MovementInterface';
import { CustomerOrProviderEnum } from '../types/ProviderOrCustomer';

export function getProviderOrCustomerFromParams(urlParam: string | CustomerOrProviderEnum) {
  switch (urlParam) {
    case CustomerOrProviderEnum.customer:
      return 'customer';

    case CustomerOrProviderEnum.provider:
      return 'provider';

    default:
      return '';
  }
}

export function getProviderOrCustomerParamsFromSlug(slug: MovimentTypesSlugEnum | undefined) {
  switch (slug) {
    case MovimentTypesSlugEnum.expense:
      return CustomerOrProviderEnum.provider;

    case MovimentTypesSlugEnum.invoice:
      return CustomerOrProviderEnum.customer;

    default:
      return CustomerOrProviderEnum.customer;
  }
}
