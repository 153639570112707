import { axiosGET, CustomAxiosResponse } from '../../../services/axiosService';
import { PaymentTypeInterface } from '../types/paymentTypeInterface';

export const listAllMovementsPaymentTypes = async (): Promise<CustomAxiosResponse<PaymentTypeInterface[]>> => {
  try {
    const endpoint = `${process.env.REACT_APP_BACKEND}/movement/payment_types`;

    const response = await axiosGET<PaymentTypeInterface[]>(endpoint);

    return {
      ...response,
      data: response.data,
      message: 'Pronto!',
    };
  } catch (error: any) {
    throw new Error(error?.message ? error.message : 'Ocorreu um erro, tente novamente.');
  }
};
