import type { FC, ReactNode } from 'react';
import { Outlet } from 'react-router-dom';
import { experimentalStyled } from '@material-ui/core/styles';
import { MobileView } from 'react-device-detect';
import DashboardNavbar from './DashboardNavbar';
import DashboardSidebar from './DashboardSidebar';
import { BottomNavigation } from '../layouts/BottomNavigation';

interface DashboardLayoutProps {
  children?: ReactNode;
}

export const DashboardLayoutRoot = experimentalStyled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  display: 'flex',
  height: '100%',
  overflow: 'hidden',
  width: '100%',
}));

export const DashboardLayoutWrapper = experimentalStyled('div')(({ theme }) => ({
  display: 'flex',
  flex: '1 1 auto',
  overflow: 'hidden',
  paddingTop: '1em',
  [theme.breakpoints.up('lg')]: {
    paddingLeft: '280px',
  },
}));

export const DashboardLayoutContainer = experimentalStyled('div')({
  display: 'flex',
  flex: '1 1 auto',
  overflow: 'hidden',
});

export const DashboardLayoutContent = experimentalStyled('div')({
  flex: '1 1 auto',
  height: '100%',
  overflow: 'auto',
  position: 'relative',
  WebkitOverflowScrolling: 'touch',
});

const DashboardLayout: FC<DashboardLayoutProps> = () => (
  <>
    <DashboardLayoutRoot>
      <>
        <DashboardNavbar />
        <DashboardSidebar />
      </>

      <DashboardLayoutWrapper>
        <DashboardLayoutContainer>
          <DashboardLayoutContent>
            <Outlet />
          </DashboardLayoutContent>
        </DashboardLayoutContainer>
      </DashboardLayoutWrapper>
    </DashboardLayoutRoot>
    {/* @ts-ignore */}
    <MobileView>
      <BottomNavigation />
    </MobileView>
  </>
);

export default DashboardLayout;
