import { EnterpriseInterface } from '../../enterprises/types/EnterpriseInterface';

export interface UserInterface {
  uuid: string;
  name: string;
  phone?: string;
  email: string;
  role: RoleInterface;
  plan: number;
  enterprises: EnterpriseInterface[];
  firebase_uid: string;
  created_at: string;
  updated_at: string;
}

export interface RoleInterface {
  uuid: string;
  slug: RolesEnum | string;
  label: string;
  created_at: string;
  updated_at: string;
}

export enum RolesEnum {
  admin = 'admin',
}

export enum PlansEnum {
  FREE = 1,
  BASIC = 2,
  PRO = 3,
  ENTERPRISE = 4,
}
