import {
  EnterpriseContextInterface,
  EnterpriseTypesEnum,
} from 'src/pages/enterprises/contexts/EnterpriseContext/EnterpriseInterface';

export type REMOVE_ENTERPRISE_INFO = {
  type: EnterpriseTypesEnum.REMOVE_ENTERPRISE_INFO;
};

export function removeEnterpriseInfo(
  state: EnterpriseContextInterface,
  _action: REMOVE_ENTERPRISE_INFO,
): EnterpriseContextInterface {

  return {
    ...state,
    selectedEnterprise: undefined,
    availableEnterprise: [],
    isManagingEnterprise: false,
    enterpriseToDelete: undefined,
  };
}
