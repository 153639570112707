import {
  InputLabel,
  Typography,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';
import { Control, RegisterOptions, useController, UseControllerProps } from 'react-hook-form';
import useAuth from 'src/hooks/useAuth';
import { useUser } from 'src/hooks/useUser';
import { PlansEnum } from 'src/pages/users/types/UserInterface';
import styled, { css } from 'styled-components';

interface CheckboxControllerInterface extends Omit<UseControllerProps<any, any>, 'control' | 'rules'> {
  placeholder?: string;
  control: Control<any>;
  rules: Omit<RegisterOptions<any, any>, 'valueAsNumber' | 'valueAsDate' | 'setValueAs'>;
  label?: string;
  disabled?: boolean;
  fullWidth?: boolean;
  variant?: 'filled' | 'outlined';
  helperMessage?: string;
  size?: 'small' | 'medium';
  checked: boolean;
}

interface CustomInputLabelInterface {
  isSelected: boolean;
  isDarkMode: boolean;
}

export const CustomInputLabel = styled(InputLabel)<CustomInputLabelInterface>`
  margin: -5px;
  padding-left: 5px;
  padding-right: 5px;
  background-color: ${({ isDarkMode }) => (isDarkMode ? '#222b36' : 'white')};

  ${({ isSelected }) =>
    isSelected &&
    css`
      margin: 0px;
      left: -5px;
    `}
`;

export const CheckboxController: React.FC<CheckboxControllerInterface> = ({
  control,
  name,
  rules,
  defaultValue,
  disabled = false,
  checked = false,
}) => {
  const {
    field: { onChange },
    formState: { isSubmitting },
  } = useController({
    name,
    control,
    rules,
    defaultValue,
  });

  const { user } = useAuth();
  const { handleOpenProModal } = useUser();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    user?.plan === PlansEnum.FREE
      ? handleOpenProModal()
      : onChange(event.target.checked);

  return (
    <FormControlLabel
      label={
        <Typography color="textPrimary" variant="body1">
          Emitir Nota Fiscal
        </Typography>
      }
      control={
        <Checkbox
          color="primary"
          onChange={handleChange}
          inputProps={{ 'aria-label': 'controlled' }}
          disabled={disabled || isSubmitting}
          checked={checked}
        />
      }
    />
  );
};
