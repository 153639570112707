import {
  EnterpriseContextInterface,
  EnterpriseTypesEnum,
} from 'src/pages/enterprises/contexts/EnterpriseContext/EnterpriseInterface';
import { EnterpriseInterface } from 'src/pages/enterprises/types/EnterpriseInterface';

export type UPDATE_ENTERPRISE_INFO = {
  type: EnterpriseTypesEnum.UPDATE_ENTERPRISE_INFO;
  enterprise: EnterpriseInterface;
};

export function updateEnterpriseInfo(
  state: EnterpriseContextInterface,
  action: UPDATE_ENTERPRISE_INFO,
): EnterpriseContextInterface {
  if (!state.selectedEnterprise) {
    return state;
  }

  return {
    ...state,
    selectedEnterprise: {
      ...action.enterprise,
    },
  };
}
