import React from 'react';
import { useFormContext } from 'react-hook-form';
import { DateController } from 'src/components/forms/DateController';

interface BillFormDueDateProps {
  isEditForm: boolean;
}

export const BillFormDueDate: React.FC<BillFormDueDateProps> = ({ isEditForm }) => {
  const { control } = useFormContext();

  return (
    <DateController
      control={control}
      disabled={isEditForm}
      name="due_date"
      size="small"
      label="Data de vencimento"
      rules={{ required: false }}
    />
  );
};

export default BillFormDueDate;
