import { Box, Button, Fab, Grid, Typography } from '@material-ui/core';
import { Save } from '@material-ui/icons';
import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import MutatingButton from 'src/components/forms/MutatingButton';
import BillFiscalForm from './BillFiscalForm';
import BillFormGroup, { BillFormGroupInterface } from './BillFormGroup';
import BillFormOverview from './BillFormOverview';

const steps = {
  withFiscal: [
    {
      description: 'Criação',
      value: 1,
    },
    {
      description: 'Fiscal',
      value: 2,
    },
    {
      description: 'Revisão',
      value: 3,
    },
  ],
  withNoFiscal: [
    {
      description: 'Criação',
      value: 1,
    },
    {
      description: 'Revisão',
      value: 2,
    },
  ],
};

const BillMobileForm: React.FC<BillFormGroupInterface> = ({
  billToBeEdited,
  fullWidth,
  isDisabledSaveButton,
}) => {
  const { watch } = useFormContext();
  const [currentStep, setCurrentStep] = useState(1);
  const shouldEmitFiscal = watch('emitNfse');

  return (
    <Box>
      <Box mt={1}>
        <Grid display="flex" justifyContent="center" gap="30px">
          {steps[shouldEmitFiscal ? 'withFiscal' : 'withNoFiscal'].map((step) => (
            <Box display="flex" alignItems="center" key={step.value}>
              <Fab
                color="primary"
                disabled={step.value !== currentStep && currentStep < step.value}
                sx={{
                  height: '25px',
                  minHeight: '25px',
                  width: '25px',
                }}
              >
                {step.value}
              </Fab>
              <Box ml={1}>
                <Typography
                  color={step.value !== currentStep && currentStep < step.value ? 'text.disabled' : 'primary'}
                >
                  {step.description}
                </Typography>
              </Box>
            </Box>
          ))}
        </Grid>
      </Box>

      <Box mt={1.5} sx={{ maxHeight: '485px', minHeight: '485px', overflow: 'auto' }}>
        <Box mt={1.5}>
          {currentStep === 1 && <BillFormGroup billToBeEdited={billToBeEdited} fullWidth={fullWidth} />}
          {currentStep === 2 && shouldEmitFiscal &&
            <BillFiscalForm billToBeEdited={billToBeEdited} fullWidth={fullWidth} />
          }
          {(currentStep === 3 || (!shouldEmitFiscal && currentStep === 2)) &&
            <BillFormOverview />
          }
        </Box>
      </Box>

      <Box mt={2} display="flex" alignItems="center" justifyContent="space-between">
        <Button
          size="medium"
          disabled={currentStep === 1}
          onClick={() => setCurrentStep(currentStep - 1)}
        >
          Voltar
        </Button>
        {currentStep === steps[shouldEmitFiscal ? 'withFiscal' : 'withNoFiscal'].length ? (
          <MutatingButton
            startIcon={<Save fontSize="small" />}
            type="submit"
            color="primary"
            variant="contained"
            loadingText="Salvando..."
            disabled={isDisabledSaveButton}
          >
            Salvar
          </MutatingButton>
        ) : (
          <Button
            size="medium"
            variant="contained"
            onClick={() => setCurrentStep(currentStep + 1)}
          >
            Avançar
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default BillMobileForm;
