// Generated by https://quicktype.io
//
// To change quicktype's target language, run command:
//
//   "Set quicktype target language"

import { ItemTag } from 'src/pages/bills/types/BillInterface';
import { BusinessContactInterface } from 'src/pages/businessContacts/types/BusinessContactInterface';
import { TagInterface } from 'src/pages/tags/types/TagInterface';

export enum MovimentTypesSlugEnum {
  expense = 'expense',
  invoice = 'invoice',
}

export enum BillShouldSeattleEnum {
  RECEIVED = 'RECEIVED',
  NON_RECEIVED = 'NON_RECEIVED',
}

export interface MovementInterface {
  uuid: string;
  num_ref: string;
  payment_date: Date | null;
  comments: string;
  grand_total: number;
  created_at: string;
  updated_at: string;
  movement_items: MovementItem[];
  business_contact_uuid: BusinessContactInterface | null;
  movement_payment_type_uuid: MovimentPaymentTypesInterface | null;
  movement_type_uuid: MovimentTypesInterface | null;
  tag: TagInterface;
  tax: number;
  interest: number;
  checkInfo: string;
}

export interface MovementItem {
  uuid?: string;
  name: string;
  description?: string;
  quantity: number;
  unit_price: number;
  total: number;
  item_tag?: ItemTag;
  item_tag_uuid?: string;
  serviceItem_uuid?: string;
}

export interface MovimentTypesInterface {
  uuid: string;
  slug: MovimentTypesSlugEnum;
  label: string;
  created_at: string;
  updated_at: string;
}

export interface MovimentPaymentTypesInterface {
  uuid: string;
  slug: string;
  label: string;
  created_at?: string;
  updated_at?: string;
}
