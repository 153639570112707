import { useEffect, useState } from 'react';
import { Box, Button, ListItem } from '@material-ui/core';
import { Chat } from '@material-ui/icons';

const OctakdeskMenu: React.FC = () => {
  const [isOctadeskOpen, setIsOctadeskOpen] = useState<boolean>(false);

  useEffect(() => {
    const octaChat = document.querySelector<HTMLElement>('#octadesk-octachat-appchat')!;
    if (isOctadeskOpen) {
      window?.octadesk?.chat?.showApp();
    } else {
      if (octaChat) {
        octaChat.style.height = '0px';
        octaChat.style.width = '0px';
      }
      window?.octadesk?.chat?.hideApp();
    }
  }, [isOctadeskOpen]);

  return (
    <ListItem
      disableGutters
      sx={{
        display: 'block',
        py: 0,
      }}
    >
      <Button
        onClick={() => setIsOctadeskOpen(!isOctadeskOpen)}
        startIcon={<Chat fontSize="small" />}
        sx={{
          color: 'text.secondary',
          fontWeight: 'fontWeightMedium',
          justifyContent: 'flex-start',
          pl: '16px',
          pr: '8px',
          py: '12px',
          textAlign: 'left',
          textTransform: 'none',
          width: '100%',
        }}
        variant="text"
      >
        <Box sx={{ flexGrow: 1 }}>Suporte</Box>
      </Button>
    </ListItem>
  );
};

export default OctakdeskMenu;
