import { MovementContextInterface, MovementTypesEnum } from '../MovementInterface';

export type UPDATE_STATE = {
  type: MovementTypesEnum.UPDATE_STATE;
  newState: Partial<MovementContextInterface>;
};

export function updateStateAction(state: MovementContextInterface, action: UPDATE_STATE): MovementContextInterface {
  return {
    ...state,
    ...action.newState,
  };
}
