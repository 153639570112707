import React from 'react';
import { useFormContext } from 'react-hook-form';
import { TextfieldController } from 'src/components/forms/TextfieldController';

interface BillFormInstallmentPrizeProps {
  isEditForm: boolean;
}

export const BillFormInstallmentPrize: React.FC<BillFormInstallmentPrizeProps> = ({ isEditForm }) => {
  const { control } = useFormContext();

  return (
    <TextfieldController
      control={control}
      disabled={isEditForm}
      name="installmentPrize"
      size="small"
      type="number"
      label="Prazo (dias) entre parcelas"
      rules={{ required: false, min: 0, max: 999 }}
    />
  );
};

export default BillFormInstallmentPrize;
