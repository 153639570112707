import { set, startOfMonth } from 'date-fns';
import React, { createContext, SetStateAction, useState } from 'react';
import { BalanceType } from 'src/pages/cashFlow/components/BalanceTypeChangeSwitch';

interface DashboardProviderInterface {
  startDate: Date;
  endDate: Date;
  todayDate: Date;
  todayEndDate: Date;
  setStartDate: React.Dispatch<SetStateAction<Date>>;
  setEndDate: React.Dispatch<SetStateAction<Date>>;
  selectBalanceType: BalanceType;
  setSelectBalanceType: React.Dispatch<React.SetStateAction<BalanceType>>;
}

const START_TIME = { hours: 0, minutes: 0, seconds: 0 };
const START_DAY = startOfMonth(set(new Date(), START_TIME));

const END_TIME = { hours: 23, minutes: 59, seconds: 59 };
const END_DAY = set(new Date(), END_TIME);

const TODAY_DATE = set(new Date(), START_TIME);
const TODAY_END_DATE = set(new Date(), END_TIME);

export const DashboardContext = createContext<DashboardProviderInterface>({
  startDate: START_DAY,
  endDate: END_DAY,
  todayDate: TODAY_DATE,
  todayEndDate: TODAY_END_DATE,
  setStartDate: () => console.warn('Ops, the dispatch is not ready'),
  setEndDate: () => console.warn('Ops, the dispatch is not ready'),
  selectBalanceType: BalanceType.realizado,
  setSelectBalanceType: () => console.warn('Ops, the dispatch is not ready'),
});

const DashboardProvider: React.FC = ({ children }) => {
  const [selectBalanceType, setSelectBalanceType] = useState<BalanceType>(BalanceType.realizado);
  const [startDate, setStartDate] = useState<Date>(START_DAY);
  const [endDate, setEndDate] = useState<Date>(END_DAY);
  const [todayDate, ,] = useState<Date>(TODAY_DATE);
  const [todayEndDate, ,] = useState<Date>(TODAY_END_DATE);

  const globals = {
    startDate,
    endDate,
    todayDate,
    todayEndDate,
    setStartDate,
    setEndDate,
    selectBalanceType,
    setSelectBalanceType,
  };

  return <DashboardContext.Provider value={globals}>{children}</DashboardContext.Provider>;
};

export default DashboardProvider;
