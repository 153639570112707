import type { FC } from 'react';

import { matchPath } from 'react-router-dom';
import { List } from '@material-ui/core';
import type { ListProps } from '@material-ui/core';
import NavItem from './NavItem';

interface Item {
  path: string;
  icon: JSX.Element;
  title: string;
}

// @ts-ignore
interface NavSectionProps extends ListProps {
  item: Item;
  pathname: string;
}

// const renderNavItems = ({
//   depth = 0,
//   items,
//   pathname,
// }: {
//   items: Item[];
//   pathname: string;
//   depth?: number;
// }): JSX.Element => (
//   <List disablePadding>
//     {items.reduce(
//       // eslint-disable-next-line @typescript-eslint/no-use-before-define, no-use-before-define
//       (acc, item) =>
//         // @ts-ignore
//         reduceChildRoutes({
//           acc,
//           item,
//           pathname,
//           depth,
//         }),
//       [],
//     )}
//   </List>
// );

// const reduceChildRoutes = ({
//   acc,
//   pathname,
//   item,
//   depth,
// }: {
//   acc: JSX.Element[];
//   pathname: string;
//   item: Item;
//   depth: number;
// }): Array<JSX.Element> => {
//   const key = `${item.title}-${depth}`;
//   const exactMatch = item.path
//     ? !!matchPath(
//         {
//           path: item.path,
//           end: true,
//         },
//         pathname,
//       )
//     : false;

//   if (item.children) {
//     const partialMatch = item.path
//       ? !!matchPath(
//           {
//             path: item.path,
//             end: false,
//           },
//           pathname,
//         )
//       : false;

//     acc.push(
//       <NavItem
//         active={partialMatch}
//         depth={depth}
//         icon={item.icon}
//         info={item.info}
//         key={key}
//         open={partialMatch}
//         path={item.path || ''}
//         title={item.title}
//       >
//         {renderNavItems({
//           depth: depth + 1,
//           items: item.children,
//           pathname,
//         })}
//       </NavItem>,
//     );
//   } else {
//     acc.push(
//       <NavItem
//         active={exactMatch}
//         depth={depth}
//         icon={item.icon}
//         info={item.info}
//         key={key}
//         path={item.path || ''}
//         title={item.title}
//       />,
//     );
//   }

//   return acc;
// };

function renderIconsSidebar(item: Item, pathname: string) {
  const exactMatch = pathname
    ? !!matchPath(
        {
          path: item.path,
          end: true,
        },
        pathname,
      )
    : false;

  return <NavItem active={exactMatch} depth={0} icon={item.icon} path={item.path || ''} title={item.title} />;
}

const NavSection: FC<NavSectionProps> = (props) => {
  const { item, pathname } = props;

  return (
    <>
      <List dense>{renderIconsSidebar(item, pathname)}</List>
    </>
  );
};

export default NavSection;
