/* eslint-disable no-param-reassign */
import type { FC } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Avatar, Box, Button, Card, CardActions, CardContent, CardMedia, Link, Typography } from '@material-ui/core';
import { useEnterprise } from '../hooks/useEnterprise';
import { EnterpriseInterface } from '../types/EnterpriseInterface';

interface EnterpriseCardProps {
  enterprise: EnterpriseInterface;
}

export const EnterpriseCard: FC<EnterpriseCardProps> = ({ enterprise }) => {
  const { selectEnterprise } = useEnterprise();

  // eslint-disable-next-line no-param-reassign
  const initials = enterprise?.fantasy_name
    ?.split(' ')
    .reduce((acc, val) => (acc += val.substring(0, 1)), '')
    .toUpperCase();

  return (
    <Card>
      <CardMedia
        image={
          enterprise?.avatar?.url || `https://source.unsplash.com/collection/1078224/400x250?sig=${enterprise.uuid}`
        }
        sx={{ height: 200 }}
      />
      <CardContent sx={{ pt: 0 }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            mb: 2,
            mt: '-50px',
          }}
        >
          <Avatar
            src={enterprise?.avatar?.url}
            sx={{
              border: '3px solid #FFFFFF',
              height: 100,
              width: 100,
            }}
          >
            {initials}
          </Avatar>
        </Box>
        <Link
          align="center"
          color="textPrimary"
          component={RouterLink}
          display="block"
          to="#"
          underline="none"
          variant="h6"
        >
          {enterprise.fantasy_name}
        </Link>
        <Typography align="center" variant="body2" color="textSecondary">
          {enterprise.company_name}
        </Typography>
      </CardContent>
      <CardActions>
        <Button onClick={() => selectEnterprise(enterprise)}>Selecionar empresa</Button>
      </CardActions>
    </Card>
  );
};

export default EnterpriseCard;
