import { FC } from 'react';

import { Drawer } from '@material-ui/core';
import type { Theme } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import {
  LocalOffer, PeopleRounded, Timeline, InsertDriveFile, Work,
  Settings, AccountBalance, Lightbulb, RequestQuoteRounded,
} from '@material-ui/icons';
import useLayout from 'src/hooks/useLayout';
import MenuPage from 'src/pages/menu/MenuPage';

import ExpenseIcon from 'src/pages/movements/components/ExpenseIcon';
import { InvoiceIcon } from '../../pages/movements/components/InvoiceIcon';

interface DashboardSidebarProps { }

export const sidebarRoutes = [
  {
    title: 'Insights',
    icon: <Lightbulb fontSize="small" />,
    path: '/',
  },
  {
    title: 'Fluxo de Caixa',
    icon: <Timeline fontSize="small" />,
    path: '/fluxo-de-caixa',
  },
  {
    title: 'Entradas',
    path: 'contas/receber',
    icon: <InvoiceIcon />,
  },
  {
    title: 'Saídas',
    path: 'contas/pagar',
    icon: <ExpenseIcon />,
  },
  {
    title: 'Notas Fiscais',
    path: 'notas-fiscais',
    icon: <RequestQuoteRounded />,
  },
  {
    title: 'Open Banking',
    path: '/open-banking',
    icon: <AccountBalance fontSize="small" />,
  },
  {
    title: 'Contatos',
    path: '/clientes',
    icon: <PeopleRounded fontSize="small" />,
  },
  {
    title: 'Produtos e Serviços',
    path: '/produtos-servicos',
    icon: <Work fontSize="small" />,
  },
  {
    title: 'Categorias',
    path: '/centro-de-custos',
    icon: <LocalOffer fontSize="small" />,
  },
  {
    title: 'Relatórios',
    path: '/relatorios',
    icon: <InsertDriveFile fontSize="small" />,
  },
  {
    title: 'Configurações',
    path: '/configuracoes',
    icon: <Settings fontSize="small" />,
  },
];

const DashboardSidebar: FC<DashboardSidebarProps> = () => {
  const { closeMenu, isSidebarMobileOpen } = useLayout();
  const lgUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'));

  // useEffect(() => {
  //   if (isSidebarMobileOpen && closeMenu) {
  //     closeMenu();
  //   }
  // }, [location.pathname, closeMenu, isSidebarMobileOpen]);

  const content = <MenuPage />;

  if (lgUp) {
    return (
      <Drawer
        anchor="left"
        open
        PaperProps={{
          sx: {
            backgroundColor: 'background.paper',
            height: 'calc(100% - 64px) !important',
            top: '64px !Important',
            width: 280,
          },
        }}
        variant="permanent"
      >
        {content}
      </Drawer>
    );
  }

  return (
    <Drawer
      anchor="left"
      onClose={closeMenu}
      open={isSidebarMobileOpen}
      PaperProps={{
        sx: {
          backgroundColor: 'background.paper',
          width: 280,
        },
      }}
      variant="temporary"
    >
      {content}
    </Drawer>
  );
};

export default DashboardSidebar;
