import { EnterpriseTypesEnum, EnterpriseContextInterface } from './../EnterpriseInterface';

export type UPDATE_STATE = {
  type: EnterpriseTypesEnum.UPDATE_STATE;
  newState: Partial<EnterpriseContextInterface>;
};

export function updateStateAction(state: EnterpriseContextInterface, action: UPDATE_STATE): EnterpriseContextInterface {
  return {
    ...state,
    ...action.newState,
  };
}
