import { BillInterface } from "src/pages/bills/types/BillInterface";
import { axiosGET, CustomAxiosResponse } from "src/services/axiosService";
import { IBankIntegration } from "../interfaces/BankIntegration.interface";

export type IPluggyToken = string;


export const getAllBankIntegrations = async (
  enterpriseUUID: string,
): Promise<CustomAxiosResponse<IBankIntegration[]>> => {
  try {
    const endpoint = `${process.env.REACT_APP_BACKEND}/open-banking/integrations/${enterpriseUUID}`;

    const response = await axiosGET<IBankIntegration[]>(endpoint);

    return {
      ...response,
      data: response.data,
      message: 'Pronto!',
    };
  } catch (error: any) {
    throw new Error(error?.message ? error.message : 'Ocorreu um erro, tente novamente.');
  }
};
