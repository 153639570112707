export function truncate(str: string | undefined, limit: number = 35) {
  if (!str) return '';

  try {
    if (str.length > limit) {
      return `${str.substring(0, limit)}...`;
    }

    return str;
  } catch (error) {
    return '';
  }
}
