import { EnterpriseInterface } from './../../../types/EnterpriseInterface';
import { EnterpriseContextInterface, EnterpriseTypesEnum } from '../EnterpriseInterface';

export type SELECT_ENTERPRISE_TO_DELETE = {
  type: EnterpriseTypesEnum.SELECT_ENTERPRISE_TO_DELETE;
  enterpriseToDelete: EnterpriseInterface | undefined;
};

export function selectEnterpriseToDeleteAction(
  state: EnterpriseContextInterface,
  action: SELECT_ENTERPRISE_TO_DELETE,
): EnterpriseContextInterface {
  return {
    ...state,
    enterpriseToDelete: action.enterpriseToDelete,
  };
}
