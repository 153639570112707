import React from 'react';
import { ArrowUpwardRounded } from '@material-ui/icons';
import styled from 'styled-components';

interface InvoiceIconProps {
  size?: number;
}

const CustomArrowUpwardRounded = styled(ArrowUpwardRounded)<InvoiceIconProps>`
  color: green;
  width: ${({ size }) => size && `${size}px`};
`;

export const InvoiceIcon: React.FC<InvoiceIconProps> = ({ size }: InvoiceIconProps) => (
  <>
    <CustomArrowUpwardRounded fontSize="medium" size={size} />
  </>
);

export default InvoiceIcon;
