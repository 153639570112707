import { Box, Button, Grid } from '@material-ui/core';
import { Add } from '@material-ui/icons';
import React, { useState } from 'react';
import { useQuery } from 'react-query';
import EmptyPlaceholder from 'src/components/layouts/EmptyPlaceholder';
import { Page } from 'src/components/layouts/Page';
import ProModal from 'src/components/ProModal';
import useAuth from 'src/hooks/useAuth';
import { useUser } from 'src/hooks/useUser';
import { useEnterprise } from 'src/pages/enterprises/hooks/useEnterprise';
import Pluggy from 'src/pages/paymentOptions/components/Pluggy';
import { PlansEnum } from 'src/pages/users/types/UserInterface';
import { OpenBankingIntegrationCard } from '../components/OpenBankingIntegrationCard';
import { getAllBankIntegrations } from '../services/getAllBankIntegrations';

interface OpenBankingProps {}

const OpenBanking: React.FC<OpenBankingProps> = () => {
  const { handleOpenProModal } = useUser();
  const { user } = useAuth();
  const [showPluggyIntegration, setShowPluggyIntegration] = useState<boolean>(false);
  const { selectedEnterprise } = useEnterprise();

  const integrationsQuery = useQuery(
    `getAllBankIntegrations-${selectedEnterprise?.uuid}`,
    () => getAllBankIntegrations(`${selectedEnterprise?.uuid}`),
  );

  const integrations = integrationsQuery.data?.data || [];

  const refetchIntegrations = () => integrationsQuery.refetch();

  const handleAddBankAccount = () => user?.plan === PlansEnum.FREE
    ? handleOpenProModal()
    : setShowPluggyIntegration(true);

  return (
    <Page
      title="Open banking"
      breadcrumbs={[
        { to: '/dashboard', label: 'Dashboard' },
        { to: 'PaymentOptions', label: 'Configurações' },
      ]}
      query={integrationsQuery}
      button={(
        <Box display="flex" flexDirection="row">
          <Box mr={2}>
            <Button
              color="secondary"
              variant="outlined"
              onClick={handleAddBankAccount}
              startIcon={<Add fontSize="small" />}
              sx={{ m: 1 }}
            >
              Adicionar nova conta
            </Button>
          </Box>
        </Box>
      )}
    >
      <>
        {showPluggyIntegration && (
          <Pluggy
            showPluggyIntegration={showPluggyIntegration}
            setShowPluggyIntegration={setShowPluggyIntegration}
            onSuccess={() => integrationsQuery.refetch()}
          />
        )}

        <Grid container spacing={2} alignItems="stretch">
          {integrations.map((integration) => (
            <Grid item xs={12} sm={6} md={3} key={integration.uuid} style={{ display: 'flex' }}>
              <OpenBankingIntegrationCard
                integration={integration}
                refetchCallback={refetchIntegrations}
              />
            </Grid>
          ))}
        </Grid>

        {integrations.length === 0 && (
          (
            <EmptyPlaceholder
              title="Oops!"
              subtitle="Você ainda não possui contas bancárias conciliadas"
              buttonTitle="Adicionar minha conta"
              onClick={handleAddBankAccount}
              showSvg
            />
          )
        )}
      </>
      <ProModal />
    </Page>
  );
};

export default OpenBanking;
