import { ItemTag } from 'src/pages/bills/types/BillInterface';
import { axiosPOST, CustomAxiosResponse } from 'src/services/axiosService';

export interface ItemTagToBeCreatedInterface {
  title: string;
  description?: string;
}

interface CreateTagInteface {
  enterpriseUuid: string;
  tag: ItemTagToBeCreatedInterface;
}

export const CreateItemTag = async ({
  enterpriseUuid,
  tag,
}: CreateTagInteface): Promise<CustomAxiosResponse<ItemTag>> => {
  try {
    const endpoint = `${process.env.REACT_APP_BACKEND}/item-tag/${enterpriseUuid}`;

    const response = await axiosPOST<ItemTag>(endpoint, tag);

    return {
      ...response,
      data: response.data,
      message: 'Pronto!',
    };
  } catch (error: any) {
    throw new Error(error?.message ? error.message : 'Ocorreu um erro, tente novamente.');
  }
};
