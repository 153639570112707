import type { FC } from 'react';

import { Link as RouterLink } from 'react-router-dom';
import { AppBar, Box, Button, Divider, IconButton, Toolbar } from '@material-ui/core';
import useLayout from 'src/hooks/useLayout';
import MenuIcon from '../icons/Menu';
import Logo from './Logo';

interface MainNavbarProps {}

const MainNavbar: FC<MainNavbarProps> = () => {
  const { openMenu } = useLayout();

  return (
    <AppBar
      elevation={0}
      sx={{
        backgroundColor: 'background.paper',
        color: 'text.secondary',
      }}
    >
      <Toolbar sx={{ minHeight: 64 }}>
        <IconButton
          color="inherit"
          onClick={openMenu}
          sx={{
            display: {
              md: 'none',
            },
          }}
        >
          <MenuIcon fontSize="small" />
        </IconButton>
        <RouterLink to="/">
          <Logo
            sx={{
              display: {
                md: 'inline',
                xs: 'none',
              },
              height: 40,
              width: 40,
            }}
          />
        </RouterLink>
        <Box sx={{ flexGrow: 1 }} />
        <Box
          sx={{
            alignItems: 'center',
            display: {
              md: 'flex',
              xs: 'none',
            },
          }}
        >
          <Button color="primary" component="a" href="/dashboard" size="small" target="_blank" variant="contained">
            Dashboard
          </Button>
        </Box>
      </Toolbar>
      <Divider />
    </AppBar>
  );
};

export default MainNavbar;
