// eslint-disable-next-line import/no-extraneous-dependencies
import { Item } from 'pluggy-js/dist/types';
import React from 'react';
import { PluggyConnect } from 'react-pluggy-connect';
import { useQuery } from 'react-query';
import EmptyPlaceholder from 'src/components/layouts/EmptyPlaceholder';
import { useMutationToast } from 'src/hooks/useMutationToast';
import { useEnterprise } from 'src/pages/enterprises/hooks/useEnterprise';
import { createPluggyItem } from 'src/pages/openBanking/services/createPluggyItem';
import { generateOpenBankingToken } from 'src/pages/openBanking/services/generatePluggyToken';

interface PluggyProps {
  setShowPluggyIntegration: React.Dispatch<React.SetStateAction<boolean>>;
  showPluggyIntegration: boolean;
  onSuccess?: () => void;
}

export const Pluggy: React.FC<PluggyProps> = ({
  showPluggyIntegration,
  setShowPluggyIntegration,
  onSuccess,
}) => {
  const { selectedEnterprise } = useEnterprise();

  const mutateCreate = useMutationToast(createPluggyItem, {
    onSuccess: (response) => console.log(response),
  });

  const onSuccessFunction = (item: Item): void => {
    mutateCreate.mutate({
      enterpriseUuid: `${selectedEnterprise?.uuid}`,
      itemId: item.id,
    }, {
      onSuccess,
    });
  };

  const onError = (errorResponse: any) => {
    console.log('🚀 ~ file: PaymentOptionList.tsx ~ line 40 ~ onError ~ error', errorResponse);
  };

  const { data, error, refetch } = useQuery(
    `generate-pluggy-token-${selectedEnterprise?.uuid}`,
    () => generateOpenBankingToken(`${selectedEnterprise?.uuid}`),
    {
      retry: 10,
      staleTime: 1000 * 60 * 25,
      cacheTime: 1000 * 60 * 25,
    },
  );

  if (error) {
    return <EmptyPlaceholder
      title="Ops..."
      subtitle="Não foi possível carregar os bancos"
      buttonTitle="Tentar novamente"
      onClick={refetch}
    />;
  }

  return showPluggyIntegration ? (
    <PluggyConnect
      connectToken={`${data?.data.token}`}
      includeSandbox
      onSuccess={({ item }) => {
        onSuccessFunction(item);
      }}
      onOpen={() => setShowPluggyIntegration(true)}
      onClose={() => setShowPluggyIntegration(false)}
      onError={onError}
    />
  ) : null;
};

export default Pluggy;
