import { useEffect } from 'react';
import type { FC } from 'react';
import { useNavigate } from 'react-router';
import { Helmet } from 'react-helmet-async';
import { Box, Button, Card, CardContent, Container, Divider, Typography } from '@material-ui/core';
import gtm from '../../lib/gtm';
import { LoginJWT } from './components/login';
import SignInGoogleButon from './components/SignInGoogleButon';

const Login: FC = () => {
  const navigate = useNavigate();
  useEffect(() => {
    gtm.push({ event: 'page_view' });
  }, []);

  return (
    <>
      {/* @ts-ignore */}
      <Helmet>
        <title>Login | Wivvi</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100vh',
        }}
      >
        <Container maxWidth="sm" sx={{ py: '40px' }}>
          <Card>
            <CardContent
              sx={{
                display: 'flex',
                flexDirection: 'column',
                p: 4,
              }}
            >
              <Box
                sx={{
                  alignItems: 'center',
                  display: 'flex',
                  justifyContent: 'space-between',
                  mb: 3,
                }}
              >
                <div>
                  <Typography color="textPrimary" gutterBottom variant="h4">
                    Login
                  </Typography>
                  <Typography color="textSecondary" variant="body2">
                    Acesse a área restrita
                  </Typography>
                </div>
                <Box
                  sx={{
                    height: 32,
                    '& > img': {
                      maxHeight: '100%',
                      width: 'auto',
                    },
                  }}
                >
                  {/* @ts-ignore */}
                  <img alt="Auth platform" src="/static/logos/logo_blue_dots_green.png" />
                </Box>
              </Box>
              <Box
                mb={1}
                sx={{
                  flexGrow: 1,
                  mt: 1,
                }}
              >
                <LoginJWT />
              </Box>
              <Box display="flex" alignItems="center" justifyContent="center" flexDirection="column">
                <Box display="flex" alignItems="center">
                  <Typography>
                    Primeiro acesso?
                  </Typography>
                  <Button onClick={() => navigate('/authentication/register')} size="medium" type="button">
                    Crie sua conta
                  </Button>
                </Box>
              </Box>
              <Box mt={2} display="flex" alignItems="center" justifyContent="center" flexDirection="column">
                <Box mb={3} display="flex" alignItems="center">
                  <Divider sx={{ width: '100px' }} />
                  <Typography mx={2}>Ou</Typography>
                  <Divider sx={{ width: '100px' }} />
                </Box>
                <SignInGoogleButon />
              </Box>
              <Box display="flex" mt={3}>
                <a
                  href="https://www.wivvi.com.br/c%C3%B3pia-pol%C3%ADtica-de-privacidade"
                  target="_blank"
                  style={{
                    textDecoration: 'none',
                  }}
                  rel="noreferrer"
                >
                  <Typography mr={1} fontSize={12} color="gray">Termos de Uso</Typography>
                </a>
                <Typography fontSize={12} color="gray">—</Typography>
                <a
                  href="https://www.wivvi.com.br/about-4"
                  target="_blank"
                  rel="noreferrer"
                  style={{
                    textDecoration: 'none',
                  }}
                >
                  <Typography ml={1} fontSize={12} color="gray">Política de Privacidade</Typography>
                </a>
              </Box>
            </CardContent>
          </Card>
        </Container>
      </Box>
      <Box position="fixed" right="1em" bottom="1em">
        <Typography variant="caption">Versão {process.env.REACT_APP_VERSION}</Typography>
      </Box>
    </>
  );
};

export default Login;
