import { HANDLE_MANAGE_ENTERPRISE } from './actions/handleManageEnterpriseAction';
import { UPDATE_ENTERPRISE_AVAILABLE } from './actions/updateEnterpriseAvailableAction';
import { ADD_ENTERPRISE_TO_STATE } from './actions/addEnterpriseToStateAction';
import { UNSELECT_ENTERPRISE } from './actions/unselectEnterpriseAction';
import { SELECT_ENTERPRISE } from './actions/selectEnterpriseAction';
import { UPDATE_STATE } from './actions/updateStateAction';
import { SELECT_ENTERPRISE_TO_DELETE } from './actions/selectEnterpriseToDeleteAction';
import { EnterpriseInterface } from '../../types/EnterpriseInterface';
import { CHANGE_ENTERPRISE_AVATAR } from './actions/changeEnterpriseAvatarAction';
import { UPDATE_ENTERPRISE_INFO } from 'src/pages/dashboard/contexts/EnterpriseContext/actions/updateEnterpriseInfo';
import { REMOVE_ENTERPRISE_INFO } from './actions/removeEnterprisesInfo';

export interface EnterpriseContextInterface {
  selectedEnterprise: EnterpriseInterface | undefined;
  availableEnterprise: EnterpriseInterface[];
  isManagingEnterprise: boolean;
  enterpriseToDelete: EnterpriseInterface | undefined;
}

export type EnterpriseAction =
  | UPDATE_STATE
  | SELECT_ENTERPRISE
  | ADD_ENTERPRISE_TO_STATE
  | UPDATE_ENTERPRISE_AVAILABLE
  | HANDLE_MANAGE_ENTERPRISE
  | SELECT_ENTERPRISE_TO_DELETE
  | UNSELECT_ENTERPRISE
  | CHANGE_ENTERPRISE_AVATAR
  | UPDATE_ENTERPRISE_INFO
  | REMOVE_ENTERPRISE_INFO;

export enum EnterpriseTypesEnum {
  UPDATE_STATE,
  SELECT_ENTERPRISE,
  UNSELECT_ENTERPRISE,
  UPDATE_ENTERPRISE_AVAILABLE,
  HANDLE_MANAGE_ENTERPRISE,
  ADD_ENTERPRISE_TO_STATE,
  SELECT_ENTERPRISE_TO_DELETE,
  CHANGE_ENTERPRISE_AVATAR,
  UPDATE_ENTERPRISE_INFO,
  REMOVE_ENTERPRISE_INFO,
}
