import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { TextfieldController } from 'src/components/forms/TextfieldController';
import { useMutationToast } from 'src/hooks/useMutationToast';
import { useEnterprise } from 'src/pages/enterprises/hooks/useEnterprise';
import { DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from '@material-ui/core';
import { KeyLabel } from 'src/components/forms/AutocompleteController';
import { InputDocTypeController } from 'src/components/forms/InputDocType';
import { createBusinessContact } from '../../services/createBusinessContact';
import { getProviderOrCustomerFromParams } from '../../utils/getProviderOrCustomerFromParams';

interface AutocompleteBusinessCreatableFormInterface {
  fieldValue: string;
  customerOrProvider: string;
  handleClose: () => void;
  updateValues: (newValue: KeyLabel) => void;
}

interface FormInterface {
  fantasy_name: string;
  vat_number: string;
  email: string;
  phone: string;
}

export const BUSINESS_CONTACT_TYPE = [
  {
    key: 'customer',
    label: 'Cliente',
  },
  {
    key: 'provider',
    label: 'Fornecedor',
  },
];

const AutocompleteBusinessCreatableForm: React.FC<AutocompleteBusinessCreatableFormInterface> = ({
  fieldValue,
  handleClose,
  updateValues,
  customerOrProvider,
}) => {
  const { selectedEnterprise } = useEnterprise();
  const mutateCreate = useMutationToast(createBusinessContact, {
    onSuccess: ({ data }) => {
      updateValues({ key: data.uuid, label: data.fantasy_name });
      handleClose();
    },
  });

  const selectedBusinessType = getProviderOrCustomerFromParams(customerOrProvider);

  const { handleSubmit, control, setValue } = useForm<FormInterface>({
    defaultValues: {
      fantasy_name: fieldValue,
      email: '',
      phone: '',
      vat_number: '',
    },
    mode: 'onChange',
    reValidateMode: 'onChange',
  });

  useEffect(() => {
    setValue('fantasy_name', fieldValue);
  }, [fieldValue, setValue]);

  const handleCreateBusinessContact = (businessContactToBeCreated: FormInterface) =>
    mutateCreate.mutate({
      businessContactToBeCreated,
      customerOrProvider,
      selectedEnterprise,
    });

  const onSubmit = (data: FormInterface) => handleCreateBusinessContact(data);

  return (
    <>
      <form>
        <DialogTitle id="form-dialog-title-business-contact">
          Novo {selectedBusinessType === 'customer' ? 'Cliente' : 'Fornecedor'}
        </DialogTitle>
        <DialogContent>
          <InputDocTypeController
            control={control}
            name="vat_number"
            mask="cpfCnpj"
            rules={{
              required: selectedBusinessType === 'customer',
            }}
            label="CPF/CNPJ"
          />
          <TextfieldController
            control={control}
            name="fantasy_name"
            label="Nome fantasia"
            disabled={mutateCreate.isLoading}
            rules={{
              required: true,
            }}
          />
          {selectedBusinessType === 'customer' && (
            <>
              <TextfieldController
                control={control}
                name="email"
                label="E-mail"
                rules={{
                  required: true,
                  pattern: /^\S+@\S+$/i,
                }}
              />
              <TextfieldController
                control={control}
                name="phone"
                label="Telefone"
                maskChar={false}
                rules={{
                  required: true,
                }}
                mask={{
                  mask: '99 99999-9999',
                }}
              />
            </>
          )}
          <DialogContentText>Não se preocupe, você pode adicionar mais informações depois!</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button disabled={mutateCreate.isLoading} onClick={handleClose} variant="text">
            Cancelar
          </Button>
          <Button
            disabled={mutateCreate.isLoading}
            type="button"
            color="primary"
            onClick={(e) => handleSubmit(onSubmit)(e)}
          >
            Criar
          </Button>
        </DialogActions>
      </form>
    </>
  );
};

export default AutocompleteBusinessCreatableForm;
