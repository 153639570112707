import { axiosGET, CustomAxiosResponse } from '../../../services/axiosService';
import { ServiceItemInterface } from '../types/ServiceItemInterface';

export const getAllServiceItem = async (
  enterpriseUuid: string,
): Promise<CustomAxiosResponse<ServiceItemInterface[]>> => {
  try {
    const endpoint = `${process.env.REACT_APP_BACKEND}/service-item/${enterpriseUuid}`;

    const response = await axiosGET<ServiceItemInterface[]>(endpoint);

    return {
      ...response,
      data: response.data,
      message: 'Pronto!',
    };
  } catch (error: any) {
    throw new Error(error?.message ? error.message : 'Ocorreu um erro, tente novamente.');
  }
};
