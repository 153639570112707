import React from 'react';
import { Outlet } from 'react-router';

interface OpenBankingRootPageProps {

}

const OpenBankingRootPage: React.FC<OpenBankingRootPageProps> = () => (
  <Outlet />
);

export default OpenBankingRootPage;
