import { Avatar } from '../../../types/EnterpriseInterface';
import { EnterpriseContextInterface, EnterpriseTypesEnum } from '../EnterpriseInterface';

export type CHANGE_ENTERPRISE_AVATAR = {
  type: EnterpriseTypesEnum.CHANGE_ENTERPRISE_AVATAR;
  avatar: Avatar;
};

export function changeEnterpriseAvatarAction(
  state: EnterpriseContextInterface,
  action: CHANGE_ENTERPRISE_AVATAR,
): EnterpriseContextInterface {
  if (!state.selectedEnterprise) {
    return state;
  }

  return {
    ...state,
    selectedEnterprise: {
      ...state.selectedEnterprise,
      avatar: action.avatar,
    },
  };
}
