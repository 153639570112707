import { axiosGET, CustomAxiosResponse } from 'src/services/axiosService';
import { TagInterface } from './../types/TagInterface';

export const getAllTags = async (enterpriseId: string): Promise<CustomAxiosResponse<TagInterface[]>> => {
  try {
    const endpoint = `${process.env.REACT_APP_BACKEND}/tag/all/${enterpriseId}`;

    const response = await axiosGET<TagInterface[]>(endpoint);

    return {
      ...response,
      data: response.data,
      message: 'Pronto!',
    };
  } catch (error: any) {
    throw new Error(error?.message ? error.message : 'Ocorreu um erro, tente novamente.');
  }
};
