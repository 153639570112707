/* eslint-disable @typescript-eslint/naming-convention */
import { Box, Typography } from '@material-ui/core';
import { add, format } from 'date-fns';
import React, { useEffect } from 'react';
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import { convertCentsToLocale, convertFormattedMoneyToCents } from 'src/utils/moneyConverter';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { TextfieldController } from 'src/components/forms/TextfieldController';
import CurrencyInput from 'src/components/forms/MaskedTextField/types/Currency';
import { InstallmentPrizeEnum } from './fields/BillFormPrizeType';
import { CreateBillInteface } from '../services/createBill';

interface BillFormOverviewProps { }

const BillFormOverview: React.FC<BillFormOverviewProps> = () => {
  const { watch, control, setValue, register } = useFormContext<CreateBillInteface>();
  const { fields, append } = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: 'installments_value',
  });

  const {
    installmentValue,
    installments,
    due_date,
    prize_type,
    installmentPrize,
    totalValue,
    installments_value,
  } = watch();

  const installmentsValueArray = useWatch({
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: 'installments_value',
  });

  const controlledFields = fields.map((field, index) => ({
    ...field,
    ...installments_value[index],
  }));

  const billItemsArray = useWatch({
    name: 'bill_items',
    control,
  });

  useEffect(() => {
    // if (Number(installmentValue) > 0) {
      setValue('installments_value', []);
      const newTotalValue = Number(installmentValue) * installments;
      setValue('totalValue', String(newTotalValue));
      for (let i = 0; i < installments; i++) {
        append({
          value: Number(installmentValue),
        });
      }
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [installments, installmentValue, billItemsArray]);

  useEffect(() => {
    if (controlledFields.length > 0 && Number(installmentValue) > 0) {
      const formatedFields = installmentsValueArray.map((field) => ({
        value: convertFormattedMoneyToCents(field.value),
      }));
      const newTotalValue = formatedFields.reduce((acc, currentValue) => acc + Number(currentValue.value), 0);
      setValue('totalValue', String(newTotalValue));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [installmentsValueArray]);

  const convertedPrizeType = Number(prize_type);
  const convertedInstallmentPrize = Number(installmentPrize);
  const isFixed = convertedPrizeType === InstallmentPrizeEnum.FixedDate;

  return (
    <Box sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
      <Box display="flex" flexDirection="column">
        <Typography gutterBottom variant="h4" component="div">
          Resumo
        </Typography>
      </Box>
      <Box mt={2}>
        <TableContainer component={Paper} sx={{ maxHeight: '90vh' }}>
          <Table size="small" stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <TableCell align="left">#</TableCell>
                <TableCell align="left">Data</TableCell>
                <TableCell align="right">Valor</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {installmentsValueArray.map((_, index) => {
                const date = isFixed
                ? add(new Date(due_date), { months: index })
                : add(new Date(due_date), { days: index * convertedInstallmentPrize });

                return (
                  <TableRow key={`installment-arr-${index + 1}`}>
                    <TableCell align="left" component="th" scope="row">
                      {index + 1}
                    </TableCell>
                    <TableCell align="left">{format(date, 'dd/MM/yy')}</TableCell>
                    <TableCell align="right">
                      <TextfieldController
                        control={control}
                        variant="outlined"
                        size="small"
                        disabled={Number(totalValue) === 0 && !billItemsArray[0]?.serviceItem_uuid}
                        currency="cents"
                        inputComponent={CurrencyInput}
                        rules={{ required: false }}
                        style={{ width: '120px', margin: '5px 0' }}
                        {...register(`installments_value.${index}.value`)}
                      />
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <Box display="flex" flexDirection="row" justifyContent="flex-end" mt={3}>
        <Box>
          <Typography variant="h5" component="div">
            Valor total
          </Typography>
          <Box>
            <Typography gutterBottom variant="h6" component="div">
              {convertCentsToLocale(Number(totalValue))}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default BillFormOverview;
