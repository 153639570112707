/* eslint-disable @typescript-eslint/no-unused-vars */
import PropTypes from 'prop-types';
import React, { forwardRef } from 'react';
import MaskedInput from 'react-text-mask';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import CurrencyInputLib from 'react-currency-input';

const defaultMaskOptions = {
  prefix: 'R$ ',
  suffix: '',
  includeThousandsSeparator: true,
  thousandsSeparatorSymbol: '.',
  allowDecimal: true,
  decimalSymbol: ',',
  decimalLimit: 2, // how many digits allowed after the decimal
  integerLimit: 12, // limit length of integer numbers
  allowNegative: false,
  allowLeadingZeroes: true,
};

const CurrencyInput = forwardRef((props, ref) => (
  <CurrencyInputLib
    prefix="R$ "
    decimalSeparator=","
    thousandSeparator="."
    inputType="tel"
    ref={ref}
    {...props}
  />
));

CurrencyInput.defaultProps = {
  maskOptions: {},
};

CurrencyInput.propTypes = {
  maskOptions: {
    prefix: PropTypes.string,
    suffix: PropTypes.string,
    includeThousandsSeparator: PropTypes.boolean,
    thousandsSeparatorSymbol: PropTypes.string,
    allowDecimal: PropTypes.boolean,
    decimalSymbol: PropTypes.string,
    decimalLimit: PropTypes.string,
    requireDecimal: PropTypes.boolean,
    allowNegative: PropTypes.boolean,
    allowLeadingZeroes: PropTypes.boolean,
    integerLimit: PropTypes.number,
  },
};

export default CurrencyInput;
