import { useContext } from "react";
import { UserContext } from "src/contexts/UserContext";
import { UserInterface } from "src/pages/users/types/UserInterface";

interface UseUserInterface {
  userPlan: number;
  isProModalOpen: boolean;
  handleOpenProModal: () => void;
  handleCloseProModal: () => void;
  handleChangeUserPlan: (user: UserInterface) => void;
}

export function useUser(): UseUserInterface {
  const { isProModalOpen, setIsProModalOpen, userPlan, setUserPlan } = useContext(UserContext);

  const handleOpenProModal = () => setIsProModalOpen(true);
  const handleCloseProModal = () => setIsProModalOpen(false);

  const handleChangeUserPlan = (user: UserInterface) => {
    setUserPlan(user.plan)
  };

  return {
    isProModalOpen,
    userPlan,
    handleOpenProModal,
    handleCloseProModal,
    handleChangeUserPlan,
  };
}