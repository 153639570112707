const LOCALE = 'pt-BR';
const OPTIONS = { style: 'currency', currency: 'BRL' };

export function convertMoneyToCents(money: number) {
  return Math.round(money * 100);
}

export function convertFloatToLocale(priceInCents: number): string {
  return priceInCents.toLocaleString(LOCALE, OPTIONS);
}

export function convertCentsToLocale(priceInCents: number): string {
  return (priceInCents / 100).toLocaleString(LOCALE, OPTIONS);
}

export function convertCentsToFloat(priceInCents: number): number {
  return priceInCents / 100;
}

/**
 * Converts a float number to locale
 */
export function convertNumberToLocale(price: number): string {
  return price.toLocaleString(LOCALE, OPTIONS);
}

export function convertFormattedMoneyToCents(money: string | number) {
  if (typeof money === 'number') return money;

  const str = money
    .replace(/(R\$ )/, '')
    .replace(/(\.)/, '')
    .replace(/(\,)/, '.');
  const floated = parseFloat(str);
  const fixed = parseFloat(floated.toFixed(2));
  return parseFloat((100 * fixed).toFixed(2));
}
