import { EnterpriseInterface } from '../../../types/EnterpriseInterface';
import { EnterpriseTypesEnum, EnterpriseContextInterface } from './../EnterpriseInterface';

export type ADD_ENTERPRISE_TO_STATE = {
  type: EnterpriseTypesEnum.ADD_ENTERPRISE_TO_STATE;
  enterprise: EnterpriseInterface;
};

export function addEnterpriseToStateAction(
  state: EnterpriseContextInterface,
  action: ADD_ENTERPRISE_TO_STATE,
): EnterpriseContextInterface {
  return {
    ...state,
    availableEnterprise: [...state.availableEnterprise, action.enterprise],
  };
}
