import { MovementAction, MovementContextInterface, MovementTypesEnum } from './MovementInterface';
import { updateStateAction } from './actions/updateStateAction';
import { handleDrawerVisibilityAction } from './actions/handleDrawerVisibilityAction';
import { handleCalendarVisibilityAction } from './actions/handleCalendarVisibilityAction';

function updateStateReducer(state: MovementContextInterface, action: MovementAction): MovementContextInterface {
  switch (action.type) {
    case MovementTypesEnum.UPDATE_STATE:
      return updateStateAction(state, action);

    case MovementTypesEnum.HANDLE_DRAWER_VISIBILITY:
      return handleDrawerVisibilityAction(state, action);
    
    case MovementTypesEnum.HANDLE_CALENDAR_VISIBILITY:
      return handleCalendarVisibilityAction(state, { open: action.open, type: action.type });

    default:
      return state;
  }
}

export default updateStateReducer;
