import { useContext } from 'react';
import { UseQueryResult } from 'react-query';
import { useNavigate } from 'react-router';
import { CustomAxiosResponse } from 'src/services/axiosService';
import { FormType } from '../contexts/MovementContext/actions/handleDrawerVisibilityAction';
import { MovementContext } from '../contexts/MovementContext/MovementContext';
import { MovementTypesEnum, UseMovementQueryType } from '../contexts/MovementContext/MovementInterface';
import { MovementInterface } from '../types/MovementInterface';

interface UseMovementsInterface {
  isDrawerOpen: boolean;
  isCalendarOpen: boolean;
  openDrawer: (formType: FormType) => void;
  closeDrawer: () => void;
  openCalendar: () => void;
  closeCalendar: () => void;
  addMovementsToState: (movements: UseMovementQueryType) => void;
  movements: MovementInterface[];
  queryMovement: UseMovementQueryType;
  formType?: FormType;
}

export function useMovements(): UseMovementsInterface {
  const { stateMovement, dispatchMovement } = useContext(MovementContext);

  const openDrawer = (formType: FormType): void =>
    dispatchMovement({
      type: MovementTypesEnum.HANDLE_DRAWER_VISIBILITY,
      open: true,
      formType,
    });
  const closeDrawer = (): void =>
    dispatchMovement({
      type: MovementTypesEnum.HANDLE_DRAWER_VISIBILITY,
      open: false,
    });

  const openCalendar = (): void =>
    dispatchMovement({
      type: MovementTypesEnum.HANDLE_CALENDAR_VISIBILITY,
      open: true,
  });

  const closeCalendar = (): void =>
    dispatchMovement({
      type: MovementTypesEnum.HANDLE_CALENDAR_VISIBILITY,
      open: false,
  });

  const addMovementsToState = (movements: UseMovementQueryType): void =>
    dispatchMovement({
      type: MovementTypesEnum.UPDATE_STATE,
      newState: { movements },
    });

  return {
    isDrawerOpen: stateMovement.isDrawerOpen,
    queryMovement: stateMovement.movements,
    isCalendarOpen: stateMovement.isCalendarOpen,
    movements: stateMovement.movements?.data?.data || [],
    openDrawer,
    closeDrawer,
    openCalendar,
    closeCalendar,
    addMovementsToState,
    formType: stateMovement.formType,
  };
}

export default useMovements;
