import React from 'react';
import { useFormContext } from 'react-hook-form';
import { TextfieldController } from 'src/components/forms/TextfieldController';

interface BillFormCommentsProps {}

export const BillFormComments: React.FC<BillFormCommentsProps> = () => {
  const { control } = useFormContext();

  return (
    <TextfieldController
      control={control}
      name="comments"
      size="small"
      label="Comentários"
      multiline
      rows={3}
      rules={{ required: false }}
    />
  );
};

export default BillFormComments;
