import { Box, Container, Typography, Button } from '@material-ui/core';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@material-ui/core/styles';
import { Variant } from '@material-ui/core/styles/createTypography';

interface EmptyPlaceholderInterface {
  title: string;
  subtitle: string;
  path?: string;
  buttonTitle?: string;
  staticPath?: string;
  onClick?: () => void;
  showSvg?: boolean;
  variant?: Variant;
}

const EmptyPlaceholder: React.FC<EmptyPlaceholderInterface> = ({
  title,
  subtitle,
  path,
  buttonTitle,
  staticPath = '/static/error/error404_',
  onClick,
  variant = 'h3',
  showSvg = true,
}) => {
  const theme = useTheme();
  const navigate = useNavigate();

  return (
    <Container>
      <Box
        display="flex"
        minHeight={showSvg ? '20em' : '0'}
        justifyContent="center"
        flexDirection="column"
        alignItems="center"
        sx={{ maxWidth: 480, margin: 'auto', textAlign: 'center' }}
      >
        <Box mb={2}>
          {showSvg && <img height="200px" width="auto" src={`${staticPath}${theme.palette.mode}.svg`} alt="" />}
        </Box>

        <Typography variant={variant} gutterBottom>
          {title}
        </Typography>

        <Box mb={3}>
          <Typography sx={{ color: 'text.secondary' }}>{subtitle}</Typography>
        </Box>

        {/* <Box
            component={motion.img}
            variants={varBounceIn}
            alt="404"
            src="/static/illustrations/illustration_register.svg"
            sx={{ width: '100%', maxHeight: 240, my: { xs: 5, sm: 10 } }}
          /> */}

        {buttonTitle && (
          <Button
            size="large"
            variant="contained"
            onClick={() => {
              if (onClick) onClick();
              if (path) navigate(path);
            }}
          >
            {buttonTitle}
          </Button>
        )}
      </Box>
    </Container>
  );
};

export default EmptyPlaceholder;
