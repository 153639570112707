import { useEffect } from 'react';
import type { FC } from 'react';
import { useRoutes } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';
import { CssBaseline, ThemeProvider } from '@material-ui/core';
import brLocale from 'date-fns/locale/pt-BR';
import './i18n';
import { LocalizationProvider } from '@material-ui/lab';
import AdapterDateFns from '@material-ui/lab/AdapterDateFns';
import { gtmConfig } from './config';
import useScrollReset from './hooks/useScrollReset';
import useSettings from './hooks/useSettings';
import gtm from './lib/gtm';
import routes from './routes';
import { createCustomTheme } from './theme';
import { useAuth } from './hooks/useAuth';
import SplashScreen from './components/SplashScreen';
import ProModal from './components/ProModal';

const App: FC = () => {
  const content = useRoutes(routes);
  const { settings } = useSettings();
  const { isLoading } = useAuth();

  useScrollReset();

  useEffect(() => {
    gtm.initialize(gtmConfig);
  }, []);

  const theme = createCustomTheme({
    direction: settings?.direction || 'ltr',
    responsiveFontSizes: settings?.responsiveFontSizes || true,
    roundedCorners: settings?.roundedCorners || true,
    theme: settings.theme,
  });

  return (
    <ThemeProvider theme={theme}>
      <LocalizationProvider dateAdapter={AdapterDateFns} locale={brLocale}>
        <CssBaseline />
        <Toaster position="top-center" />
        {!isLoading ? content : <SplashScreen />}
        <ProModal />
      </LocalizationProvider>
    </ThemeProvider>
  );
};

export default App;
