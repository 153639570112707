import { axiosGET, CustomAxiosResponse } from 'src/services/axiosService';

export interface FiscalServices {
  label: string;
  key: string;
};

export const getFiscalServices = async (): Promise<CustomAxiosResponse<FiscalServices[]>> => {
  try {
    const endpoint = `${process.env.REACT_APP_BACKEND}/nota-facil/get-services`;

    const response = await axiosGET<FiscalServices[]>(endpoint);

    return {
      ...response,
      data: response.data,
      message: 'Pronto!',
    };
  } catch (error: any) {
    throw new Error(error?.message ? error.message : 'Ocorreu um erro, tente novamente.');
  }
};
