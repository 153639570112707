import { InvoiceOrExpenseEnum } from '../types/InvoiceOrExpenseEnum';
import { MovimentTypesSlugEnum } from '../types/MovementInterface';

export function getInvoiceOrExpenseFromParams(urlParam: string | InvoiceOrExpenseEnum | undefined) {
  switch (urlParam) {
    case 'pagar':
      return 'expense';

    case 'receber':
      return 'invoice';

    case InvoiceOrExpenseEnum.invoice:
      return 'invoice';

    case InvoiceOrExpenseEnum.expense:
      return 'expense';

    default:
      return '';
  }
}

export function convertInvoiceOrExpenseEnumToString(str: string | undefined): MovimentTypesSlugEnum {
  const internal = getInvoiceOrExpenseFromParams(str);
  switch (internal) {
    case 'invoice':
      return MovimentTypesSlugEnum.invoice;

    case 'expense':
      return MovimentTypesSlugEnum.expense;

    default:
      return MovimentTypesSlugEnum.invoice;
  }
}
