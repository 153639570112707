import { Box, Typography, IconButton, Tooltip } from '@material-ui/core';
import { Help } from '@material-ui/icons';
import React from 'react';

interface TitleSectionProps {
  button?: JSX.Element;
  tooltip?: string | string[];
  title: string;
  subtitle?: string;
}

const TitleSection: React.FC<TitleSectionProps> = ({ button, tooltip, title, subtitle }) => (
  <Box width="100%" display="flex" flexDirection="row" alignItems="center" justifyContent="space-between">
    <Box display="flex" flexDirection="column">
      <Typography variant="h6">{title}</Typography>
      {subtitle && <Typography variant="subtitle1">{subtitle}</Typography>}
    </Box>
    <Box>
      {button}

      {tooltip && (
        <Tooltip
          title={
            typeof tooltip === 'string' ? (
              <Typography variant="body2">{tooltip}</Typography>
            ) : (
              tooltip.map((str) => (
                <Typography variant="body2" gutterBottom>
                  {str}
                </Typography>
              ))
            )
          }
        >
          <IconButton color="primary">
            <Help fontSize="small" />
          </IconButton>
        </Tooltip>
      )}
    </Box>
  </Box>
);

export default TitleSection;
