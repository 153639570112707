import React, { createContext, useReducer } from 'react';
import Reducer from './MovementReducer';
import { movementInitialState } from './MovementInitializer';
import { MovementContextInterface, MovementAction } from './MovementInterface';

export type MovementDispatchType = React.Dispatch<MovementAction>;

interface MovementProviderInterface {
  stateMovement: MovementContextInterface;
  dispatchMovement: MovementDispatchType;
}

export const MovementContext = createContext<MovementProviderInterface>({
  stateMovement: movementInitialState,
  dispatchMovement: () => console.warn('MovementDispatch not ready'),
});

const MovementProvider: React.FC = ({ children }) => {
  const [stateMovement, dispatchMovement] = useReducer(Reducer, movementInitialState);

  const globals = {
    stateMovement,
    dispatchMovement,
  };

  return <MovementContext.Provider value={globals}>{children}</MovementContext.Provider>;
};

export default MovementProvider;
