import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router';
import { Outlet } from 'react-router-dom';

interface MovementsRootPageInterface {}

const MovementsRootPage: React.FC<MovementsRootPageInterface> = () => {
  const navigate = useNavigate();
  const { invoiceOrExpense } = useParams();

  useEffect(() => {
    if (!invoiceOrExpense) {
      navigate('/movimentacoes/recebimentos');
    }
  }, [invoiceOrExpense, navigate]);

  return <Outlet />;
};

export default MovementsRootPage;

// name:string:Nome,
// num_ref:string:Referência,
// payment_date:string:Data,
// comments:string:Comentários,
// grand_total:number:Total,
// movement_type_uuid:string:movement_type_uuid,
// movement_payment_type_uuid:string:movement_payment_type_uuid,
// business_contact_uuid:string:business_contact_uuid,
