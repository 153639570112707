import React from 'react';
import { Button, CircularProgress, ButtonProps } from '@material-ui/core';
import { useIsMutating } from 'react-query';

interface LoadingButtonInterface extends Omit<ButtonProps, 'href' | 'classes'> {
  loadingText?: string;
  disabled?: boolean;
}

const MutatingButton: React.FC<LoadingButtonInterface> = ({ children, loadingText, disabled, ...props }) => {
  const isMutating = useIsMutating();

  return (
    <Button
      variant="contained"
      color="primary"
      disabled={disabled || !!isMutating}
      {...props}
      endIcon={isMutating ? <CircularProgress size={12} /> : null}
    >
      {isMutating ? loadingText || children : children}
    </Button>
  );
};

export default MutatingButton;
