import { EnterpriseInterface } from 'src/pages/enterprises/types/EnterpriseInterface';
import { CustomerOrProviderEnum } from '../types/ProviderOrCustomer';
import { getProviderOrCustomerFromParams } from '../utils/getProviderOrCustomerFromParams';
import { axiosGET, CustomAxiosResponse } from './../../../services/axiosService';
import { BusinessContactInterface } from './../types/BusinessContactInterface';

export const getAllBusinessContacts = async (
  selectedEnterprise: EnterpriseInterface | undefined,
  customerOrProvider: string,
): Promise<CustomAxiosResponse<BusinessContactInterface[]>> => {
  try {
    const urlParam = getProviderOrCustomerFromParams(customerOrProvider);
    const endpoint = `${process.env.REACT_APP_BACKEND}/business_contact/${urlParam}/${selectedEnterprise?.uuid}`;

    const response = await axiosGET<BusinessContactInterface[]>(endpoint);

    return {
      ...response,
      data: response.data,
      message: 'Pronto!',
    };
  } catch (error: any) {
    throw new Error(error?.message ? error.message : 'Ocorreu um erro, tente novamente.');
  }
};
