import { EnterpriseTypesEnum, EnterpriseContextInterface } from './../EnterpriseInterface';

export type UNSELECT_ENTERPRISE = {
  type: EnterpriseTypesEnum.UNSELECT_ENTERPRISE;
};

export function unselectEnterpriseAction(
  state: EnterpriseContextInterface,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  _action: UNSELECT_ENTERPRISE,
): EnterpriseContextInterface {
  return {
    ...state,
    selectedEnterprise: undefined,
  };
}
