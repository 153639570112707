import React, { useEffect, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { ButtonController } from 'src/components/forms/ButtonController';
import useMovements from 'src/pages/movements/hooks/useMovements';
import { MovimentTypesInterface, MovimentTypesSlugEnum } from 'src/pages/movements/types/MovementInterface';
import { CreateBillInteface } from '../../services/createBill';

interface BillFormCommentsProps {
  isEditForm: boolean;
  billTypesData: MovimentTypesInterface[];
}

export const BillFormBillTypes: React.FC<BillFormCommentsProps> = ({ isEditForm, billTypesData }) => {
  const { formType } = useMovements();
  const { control, setValue } = useFormContext<CreateBillInteface>();

  const billsType = useMemo(() => billTypesData || [], [billTypesData]);

  useEffect(() => {
    const searched = billsType.find(({ slug }) => slug === formType);

    if (searched?.uuid) {
      setValue('bill_types_uuid', searched.uuid);
    }
  }, [billsType, formType, setValue]);

  return (
    <ButtonController
      disabled={isEditForm}
      control={control}
      rules={{ required: true }}
      name="bill_types_uuid"
      size="small"
      values={billsType.map(({ uuid, slug }) => ({
        key: uuid,
        label: slug === MovimentTypesSlugEnum.invoice ? 'Entrada' : 'Saída',
      }))}
    />
  );
};

export default BillFormBillTypes;
