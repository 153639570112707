import React from 'react';
import { useFormContext } from 'react-hook-form';
import { SelectController } from 'src/components/forms/SelectController';

interface BillFormPrizeTypeProps {
  isEditForm: boolean;
}

export enum InstallmentPrizeEnum {
  FixedDate = 1,
  VariableDate = 2,
}

const INSTALLMENTS_PRIZE = [
  { key: InstallmentPrizeEnum.FixedDate, label: 'Data fixa' },
  { key: InstallmentPrizeEnum.VariableDate, label: 'Data variável' },
];

export const BillFormPrizeType: React.FC<BillFormPrizeTypeProps> = ({ isEditForm }) => {
  const { control } = useFormContext();

  return (
    <SelectController
      control={control}
      disabled={isEditForm}
      rules={{ required: true }}
      name="prize_type"
      label="Tipo de vencimento"
      variant="outlined"
      fullWidth
      values={INSTALLMENTS_PRIZE}
    />
  );
};

export default BillFormPrizeType;
