import { useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { AppBar, Paper, Tab, Tabs } from '@material-ui/core';
import { useLocation } from 'react-router';
import styled, { css } from 'styled-components';

export interface PageTabsInterface {
  label: string;
  path: string;
  icon?: JSX.Element;
}

interface InternalPageTabsInterface {
  tabs: PageTabsInterface[];
}

interface StyledTabs {
  hasIcon: boolean;
}

const CustomTab = styled(Tabs)<StyledTabs>`
  ${({ hasIcon }) =>
    hasIcon &&
    css`
      .MuiButtonBase-root {
        height: 48px;
        min-height: 48px;
        margin-top: -2px;
      }

      .MuiTab-wrapper > *:first-child {
        margin: 0;
        margin-right: 6px;
      }

      .MuiTab-wrapper {
        flex-direction: row;
        display: flex;
        align-items: center;

        svg {
          margin: 0;
          font-size: 0.875rem;
          margin-right: 6px;
        }
      }
    `}
`;

const PageTabs: React.FC<InternalPageTabsInterface> = ({ tabs }) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [tabValue, setTabValue] = useState<string>(pathname);

  useEffect(() => {
    setTabValue(pathname);
  }, [pathname]);

  return (
    <Paper square elevation={1}>
      <AppBar position="relative" color="transparent">
        <CustomTab
          hasIcon={!!tabs.find(({ icon }) => !!icon)}
          value={tabValue}
          onChange={(event: React.ChangeEvent<{}>, newValue: string) => {
            setTabValue(newValue);
          }}
          centered
          variant="fullWidth"
        >
          {tabs.map((tab) => (
            <Tab
              icon={tab.icon}
              component="a"
              value={tab.path}
              onClick={(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
                event.preventDefault();
                navigate(tab.path);
              }}
              label={tab.label}
            />
          ))}
        </CustomTab>
      </AppBar>
    </Paper>
  );
};

export default PageTabs;
