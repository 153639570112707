import { Backdrop, Box, Button, Fade, makeStyles, Modal, Typography } from '@material-ui/core';
import { useUser } from 'src/hooks/useUser';

const classes = makeStyles(() => ({
  root: {},
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

export default function ProModal() {
  const { handleCloseProModal, isProModalOpen } = useUser();
  const className = classes();
  // const mutateNotify = useMutationToast(notifyUser, { onSuccess: handleCloseProModal });

  return (
    <Modal
      className={className.modal}
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      closeAfterTransition
      onClose={handleCloseProModal}
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
      open={isProModalOpen}
    >
      <Fade in={isProModalOpen} style={{ border: 'none', position: 'absolute', outline: 'none' }}>
        <Box p={3} sx={{ backgroundColor: 'white' }}>
          <Typography variant="h5">
            Está chegando em nosso Plano Pro, aguarde!
          </Typography>
          <Box mt={2}>
            <Typography variant="body1">
              Em breve, essa e outras funcionalidades estarão disponíveis para você.
            </Typography>
          </Box>
          <Box mt={4} display="flex" justifyContent="center">
            <Button
              onClick={handleCloseProModal}
              variant="contained"
              size="large"
            >
              Me avise quando estiver disponível
            </Button>
          </Box>
        </Box>
      </Fade>
    </Modal>
  );
}
