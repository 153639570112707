import {
  InputLabel,
  FormControl,
  FormHelperText,
  Box,
  FormControlLabel,
  Radio,
  RadioGroup,
  FormLabel,
} from '@material-ui/core';
import { Control, RegisterOptions, useController, UseControllerProps } from 'react-hook-form';
import styled, { css } from 'styled-components';
import { getHelperMessage } from './TextfieldController';

interface KeyLabel {
  key: string | number;
  label: string;
  avatar?: string;
}

interface RadioControllerInterface extends Omit<UseControllerProps<any, any>, 'control' | 'rules'> {
  placeholder?: string;
  control: Control<any>;
  rules: Omit<RegisterOptions<any, any>, 'valueAsNumber' | 'valueAsDate' | 'setValueAs'>;
  values: KeyLabel[];
  label?: string;
  disabled?: boolean;
  fullWidth?: boolean;
  variant?: 'filled' | 'outlined';
  helperMessage?: string;
  size?: 'small' | 'medium';
}

interface CustomInputLabelInterface {
  isSelected: boolean;
}

export const CustomInputLabel = styled(InputLabel)<CustomInputLabelInterface>`
  margin: -5px;
  padding-left: 5px;
  padding-right: 5px;
  background-color: white;

  ${({ isSelected }) =>
    isSelected &&
    css`
      margin: 0px;
      left: -5px;
    `}
`;

export const RadioController: React.FC<RadioControllerInterface> = ({
  control,
  name,
  rules,
  values,
  defaultValue,
  placeholder,
  label,
  helperMessage,
  fullWidth = true,
  disabled = false,
  variant = 'outlined',
}) => {
  const {
    field: { value, onChange, ...fieldProps },
    fieldState: { error },
    formState: { isSubmitting },
  } = useController({
    name,
    control,
    rules,
    defaultValue,
  });

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    onChange(event.target.value || undefined);
  };

  return (
    <Box mb={2}>
      <FormControl error={!!error} variant={variant} fullWidth={fullWidth}>
        {label && <FormLabel error={!!error}>{label}</FormLabel>}
        <RadioGroup
          defaultValue={defaultValue}
          onChange={handleChange}
          placeholder={placeholder}
          // input={<OutlinedInput />}
          value={value || undefined}
          {...fieldProps}
        >
          {values.map((entry) => (
            <FormControlLabel
              // fullWidth={fullWidth}
              value={entry.key}
              control={<Radio disabled={disabled || isSubmitting} />}
              label={entry.label}
            />
          ))}
        </RadioGroup>
        <FormHelperText error={!!error}>{getHelperMessage(error || helperMessage)}</FormHelperText>
      </FormControl>
    </Box>
  );
};
