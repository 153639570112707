import { EnterpriseContextInterface, EnterpriseTypesEnum } from '../EnterpriseInterface';

export type HANDLE_MANAGE_ENTERPRISE = {
  type: EnterpriseTypesEnum.HANDLE_MANAGE_ENTERPRISE;
  isManagingEnterprise: boolean;
};

export function handleManageEnterpriseAction(
  state: EnterpriseContextInterface,
  action: HANDLE_MANAGE_ENTERPRISE,
): EnterpriseContextInterface {
  return {
    ...state,
    isManagingEnterprise: action.isManagingEnterprise,
  };
}
