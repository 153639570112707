import { FC, useState } from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import SpeedDial from '@material-ui/lab/SpeedDial';
import { Backdrop, SpeedDialAction, SpeedDialIcon } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    speedDial: {
      position: 'absolute',
      zIndex: 1500,
      bottom: theme.spacing(2),
      right: theme.spacing(3),
      '& .MuiSpeedDialAction-staticTooltipLabel': {
        fontSize: '0.9rem',
        textAlign: 'center',
        width: '90px',
        color: theme.palette.text.primary,
        cursor: 'pointer',
      },
      '& .MuiSpeedDialAction-staticTooltipLabel:hover': {
        backgroundColor: theme.palette.background.default,
      },
      '&.css-sox5kk-MuiBackdrop-root': {
        zIndex: 1500,
      },
    },
  }),
);

interface ActionsSpeedDial {
  icon: JSX.Element;
  name: string;
  open: () => void;
}

interface SpeedDialTooltipOpenProps {
  actions: ActionsSpeedDial[];
}

const SpeedDialTooltipOpen: FC<SpeedDialTooltipOpenProps> = ({ actions }) => {
  const classes = useStyles() as any;
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <>
      <Backdrop open={open} sx={{ zIndex: 1500 }} />
      <SpeedDial
        ariaLabel="SpeedDial example"
        className={classes.speedDial}
        hidden={false}
        icon={<SpeedDialIcon />}
        onClick={handleClick}
        open={open}
      >
        {actions.map((action: any) => (
          <SpeedDialAction
            className={classes.speedDialIcon}
            key={action.name}
            icon={action.icon}
            tooltipTitle={action.name}
            title={action.name}
            tooltipOpen
            onClick={action.open}
          />
        ))}
      </SpeedDial>
    </>
  );
};

export default SpeedDialTooltipOpen;
