export function capitalizeFirstLetter(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function isPlural(string: string): boolean {
  return string.charAt(string.length - 1) === 's';
}

export function isSingular(string: string): boolean {
  return !isPlural(string);
}
