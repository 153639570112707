import { Paper } from '@material-ui/core';
import React from 'react';
import MenuPage from './MenuPage';

interface MenuRootPageInterface {}

const MenuRootPage: React.FC<MenuRootPageInterface> = () => (
  <Paper style={{ height: 'calc(100vh - 48px)' }}>
    <MenuPage />
  </Paper>
);

export default MenuRootPage;
