import { UserInterface } from '../../users/types/UserInterface';
import { getLoggedUser } from './../../../services/getLoggedUser';
import { CustomAxiosResponse, VariantEnum, axiosPOST, AUTH_TOKEN } from './../../../services/axiosService';

export interface RegisterInterface {
  name: string;
  email: string;
  password: string;
  phone: string;
}

export interface RegisterResponseInterface {
  token: string;
  user: UserInterface;
  firebaseUser?: any;
}

export const registerUser = async (newUser: RegisterInterface): Promise<CustomAxiosResponse<RegisterResponseInterface>> => {
  try {
    const endpoint = `${process.env.REACT_APP_BACKEND}/auth/register`;

    const response = await axiosPOST<RegisterResponseInterface>(endpoint, newUser, undefined);

    window.localStorage.setItem(AUTH_TOKEN, response.data.token);

    return {
      ...response,
      data: {
        token: response.data.token,
        user: response.data.user,
      },
      message: `Bem vindo ${response.data.user.name}!`,
      variant: VariantEnum.success,
    };
  } catch (error: any) {
    throw new Error(error[0] ? error[0] : 'Ocorreu um erro, ao criar o usuário.');
  }
};
