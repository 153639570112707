import React from 'react';
import { LayoutContextInterface } from './LayoutInterface';

export const mockLayoutDialogContent1 = (
  <>
    <p>Content</p>
  </>
);

export const layoutInitialState: LayoutContextInterface = {
  title: 'Wivvi',
};
