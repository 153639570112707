import { Button, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import CurrencyInput from 'src/components/forms/MaskedTextField/types/Currency';
import { TextfieldController } from 'src/components/forms/TextfieldController';
import { useMutationToast } from 'src/hooks/useMutationToast';
import { useEnterprise } from 'src/pages/enterprises/hooks/useEnterprise';
import { createItemService, ServiceItemToBeCreated } from '../../services/createServiceItem';
import { ServiceItemInterface } from '../../types/ServiceItemInterface';

interface AutocompleteServiceItemCreatableFormInterface {
  fieldValue: string;
  handleClose: () => void;
  updateValues: (data: ServiceItemInterface) => void;
}

export const AutocompleteServiceItemCreateForm: React.FC<AutocompleteServiceItemCreatableFormInterface> = ({
  fieldValue,
  handleClose,
  updateValues,
}) => {
  const { selectedEnterprise } = useEnterprise();
  const mutateCreate = useMutationToast(createItemService, {
    onSuccess: (data) => {
      updateValues(data.data);
      handleClose();
    },
  });

  const onSubmit = (data: ServiceItemToBeCreated) => {
    mutateCreate.mutate({
      enterpriseUuid: selectedEnterprise?.uuid || '',
      serviceItem: {
        name: data.name,
        value: data.value,
        description: data.description,
      },
    });
  };

  const { handleSubmit, control, setValue } = useForm<ServiceItemToBeCreated>({
    defaultValues: {
      name: fieldValue,
      value: '',
    },
    mode: 'onChange',
    reValidateMode: 'onChange',
  });

  useEffect(() => {
    setValue('name', fieldValue);
  }, [fieldValue, setValue]);

  return (
    <>
      <form>
        <DialogTitle id="form-dialog-title-business-contact">Novo Produto/Serviço</DialogTitle>
        <DialogContent>
          <TextfieldController
            control={control}
            name="name"
            label="Nome"
            disabled={mutateCreate.isLoading}
            rules={{
              required: true,
            }}
          />
          <TextfieldController
            control={control}
            name="value"
            disabled={mutateCreate.isLoading}
            currency="cents"
            inputComponent={CurrencyInput}
            rules={{
              required: true,
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button disabled={mutateCreate.isLoading} onClick={handleClose} variant="text">
            Cancelar
          </Button>
          <Button
            disabled={mutateCreate.isLoading}
            type="button"
            color="primary"
            onClick={(e) => handleSubmit(onSubmit)(e)}
          >
            Criar
          </Button>
        </DialogActions>
      </form>
    </>
  );
};
