import { FormControl, FormHelperText, TextField } from '@material-ui/core';
import { Control, RegisterOptions, useController, UseControllerProps } from 'react-hook-form';
import { DatePicker } from '@material-ui/lab';
import { TodayRounded } from '@material-ui/icons';
import { getHelperMessage } from './TextfieldController';

interface DateControllerInterface extends Omit<UseControllerProps<any, any>, 'control' | 'rules'> {
  control: Control<any>;
  rules: Omit<RegisterOptions<any, any>, 'valueAsNumber' | 'valueAsDate' | 'setValueAs'>;
  label?: string;
  disabled?: boolean;
  fullWidth?: boolean;
  variant?: 'filled' | 'outlined';
  helperMessage?: string;
  size?: 'small' | 'medium';
}

export const DateController: React.FC<DateControllerInterface> = ({
  control,
  name,
  rules,
  defaultValue,
  label,
  helperMessage,
  fullWidth = true,
  variant = 'outlined',
  size = 'medium',
  disabled = false,
}) => {
  const {
    field: { value, onChange },
    fieldState: { error },
  } = useController({
    name,
    control,
    rules,
    defaultValue,
  });

  return (
    <FormControl error={!!error} variant={variant} fullWidth={fullWidth}>
      <DatePicker
        label={label}
        value={value}
        onChange={onChange}
        disabled={disabled}
        renderInput={(params) => (
          <TextField size={size} InputProps={{ endAdornment: <TodayRounded /> }} {...params} helperText="" />
        )}
      />
      {!!error && <FormHelperText error={!!error}>{getHelperMessage(error || helperMessage)}</FormHelperText>}
    </FormControl>
  );
};
