import { axiosGET, CustomAxiosResponse } from './../../../services/axiosService';
import { EnterpriseInterface } from './../types/EnterpriseInterface';

export const getAllEnterprises = async (): Promise<CustomAxiosResponse<EnterpriseInterface[]>> => {
  try {
    const endpoint = `${process.env.REACT_APP_BACKEND}/user/enterprises`;

    const response = await axiosGET<EnterpriseInterface[]>(endpoint);

    return {
      ...response,
      data: response.data,
      message: 'Pronto!',
    };
  } catch (error: any) {
    throw new Error(error?.message ? error.message : 'Ocorreu um erro, tente novamente.');
  }
};
