import React from 'react';
import { useFormContext } from 'react-hook-form';
import { DateController } from 'src/components/forms/DateController';

interface BillFormPaymentDateProps {
  isEditForm: boolean;
}

export const BillFormPaymentDate: React.FC<BillFormPaymentDateProps> = ({ isEditForm }) => {
  const { control } = useFormContext();

  return (
    <DateController
      control={control}
      disabled={isEditForm}
      name="payment_date"
      size="small"
      label="Data do pagamento"
      rules={{ required: false }}
    />
  );
};

export default BillFormPaymentDate;
