import { set, startOfMonth } from 'date-fns';
import React, { createContext, SetStateAction, useState } from 'react';

interface DashboardProviderInterface {
  startDate: Date;
  endDate: Date;
  setStartDate: React.Dispatch<SetStateAction<Date>>;
  setEndDate: React.Dispatch<SetStateAction<Date>>;
  open: boolean;
  handleClickOpen: (event: React.MouseEvent<HTMLButtonElement>) => void;
  handleClose: () => void;
  anchorEl: null | HTMLElement;
}

export const START_TIME = { hours: 0, minutes: 0, seconds: 0 };
const START_DAY = startOfMonth(set(new Date(), START_TIME));

export const END_TIME = { hours: 23, minutes: 59, seconds: 59 };
const END_DAY = set(new Date(), END_TIME);

export const BillsContext = createContext<DashboardProviderInterface>({
  startDate: START_DAY,
  endDate: END_DAY,
  setStartDate: () => console.warn('Ops, the dispatch is not ready'),
  setEndDate: () => console.warn('Ops, the dispatch is not ready'),
  handleClickOpen: () => console.warn('Ops, the dispatch is not ready'),
  handleClose: () => console.warn('Ops, the dispatch is not ready'),
  open: false,
  anchorEl: null,
});

const BillsProvider: React.FC = ({ children }) => {
  const [startDate, setStartDate] = useState<Date>(START_DAY);
  const [endDate, setEndDate] = useState<Date>(END_DAY);
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClickOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(false);
    setAnchorEl(null);
  };

  const globals = {
    startDate,
    endDate,
    setStartDate,
    setEndDate,
    open,
    handleClickOpen,
    handleClose,
    anchorEl,
  };

  return <BillsContext.Provider value={globals}>{children}</BillsContext.Provider>;
};

export default BillsProvider;
