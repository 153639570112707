import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Control, RegisterOptions, useController, UseControllerProps } from 'react-hook-form';
import { Autocomplete, Chip, Dialog, FormControl, TextField, createFilterOptions } from '@material-ui/core';
import { getHelperMessage } from 'src/components/forms/TextfieldController';
import { ItemTag } from 'src/pages/bills/types/BillInterface';
import { AutocompleteItemTagCreateForm } from './AutocompleteItemTagCreateForm';

interface KeyLabel {
  key: string;
  label: string;
}

interface AutocompleteItemTagCreatableInterface extends Omit<UseControllerProps<any, any>, 'control' | 'rules'> {
  control: Control<any>;
  rules: Omit<RegisterOptions<any, any>, 'valueAsNumber' | 'valueAsDate' | 'setValueAs'>;
  values: KeyLabel[];
  label?: string;
  disabled?: boolean;
  fullWidth?: boolean;
  variant?: 'filled' | 'outlined';
  helperMessage?: string;
  size?: 'small' | 'medium';
  onSuccess?: (createdTag: string) => void;
  defaultTag?: ItemTag;
}

const CustomFormControl = styled(FormControl)`
  margin-bottom: 0 !important;

  input {
    font-size: 12px;
    height: 23px;
  }
`;

const CREATE_LABEL = 'Criar';
const filter = createFilterOptions<KeyLabel>();

export const AutocompleteItemTagCreate: React.FC<AutocompleteItemTagCreatableInterface> = ({
  control,
  name,
  rules,
  values,
  defaultValue,
  label,
  helperMessage,
  fullWidth = true,
  variant = 'outlined',
  size = 'medium',
  onSuccess,
  defaultTag,
}) => {
  const [internalValues, setInternalValues] = useState<KeyLabel[]>(values);
  const [internalValue, setInternalValue] = useState<KeyLabel | null>(
    defaultTag
      ? {
          key: String(defaultTag?.uuid),
          label: String(defaultTag?.title),
        }
      : null,
  );
  const [open, toggleOpen] = useState(false);
  const {
    field: { onChange },
    fieldState: { error },
  } = useController({
    name,
    control,
    rules,
    defaultValue,
  });

  const handleClose = () => toggleOpen(false);

  const updateValues = (newValue: KeyLabel) => {
    setInternalValues([newValue, ...internalValues]);
    setInternalValue(newValue);
    if (onSuccess) {
      onSuccess(newValue.key);
    }
    onChange(newValue.key);
  };

  useEffect(() => setInternalValues(values), [values]);

  return (
    <CustomFormControl error={!!error} variant={variant} fullWidth={fullWidth}>
      <Autocomplete
        options={internalValues}
        value={internalValue}
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        freeSolo
        getOptionLabel={(option) => {
          // e.g value selected with enter, right from the input
          if (typeof option === 'string') {
            return option;
          }
          if (!option.key) {
            return option.label.toString();
          }
          return option.label.toString();
        }}
        fullWidth
        size={size}
        noOptionsText="Nenhum resultado"
        onInputChange={(_, change) => {
          const locatedValue = values.find((_value: any) => _value.label === change)?.key;
          onChange(locatedValue);
        }}
        onChange={(event, keyLabel) => {
          if (typeof keyLabel === 'string') {
            // timeout to avoid instant validation of the dialog's form.
            setTimeout(() => {
              toggleOpen(true);
              setInternalValue({ key: '', label: keyLabel });
            });
          } else if (keyLabel?.key === '' || keyLabel?.label.includes(CREATE_LABEL)) {
            toggleOpen(true);
            setInternalValue({ key: keyLabel.key, label: keyLabel.key });
          } else {
            setInternalValue(keyLabel);
          }
        }}
        filterOptions={(options, params) => {
          const filtered = filter(options, params) as KeyLabel[];

          if (params.inputValue !== '') {
            filtered.push({
              key: params.inputValue,
              label: `${CREATE_LABEL} "${params.inputValue}"`,
            });
          }

          return filtered;
        }}
        renderTags={(options) =>
          options.map((option) => (
            <Chip variant="outlined" size={size} key={`chip-${option.key}`} label={option.label} />
          ))
        }
        renderInput={(params) => (
          <TextField
            {...params}
            label={label}
            size={size}
            variant="outlined"
            error={!!error}
            helperText={getHelperMessage(error || helperMessage)}
            fullWidth
          />
        )}
      />
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <AutocompleteItemTagCreateForm
          fieldValue={`${internalValue?.key}`}
          handleClose={handleClose}
          updateValues={(newTag: KeyLabel) => updateValues(newTag)}
        />
      </Dialog>
    </CustomFormControl>
  );
};
