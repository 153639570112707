import { MutationFunction, useMutation, UseMutationOptions, UseMutationResult } from 'react-query';
import toast from 'react-hot-toast';
import { CustomAxiosResponse } from 'src/services/axiosService';

const SUCCESS_MSG = 'Ocorreu um problema, tente novamente.';
const ERROR_MSG = 'Ocorreu um problema, tente novamente.';

export function useMutationToast<
  TData = CustomAxiosResponse<unknown>,
  TError = Error,
  TVariables = void,
  TContext = unknown,
>(
  mutationFn: MutationFunction<CustomAxiosResponse<TData>, TVariables>,
  options?: UseMutationOptions<CustomAxiosResponse<TData>, TError, TVariables, TContext>,
): UseMutationResult<CustomAxiosResponse<TData>, TError, TVariables, TContext> {
  const mutation = useMutation<CustomAxiosResponse<TData>, TError, TVariables, TContext>(mutationFn, {
    ...options,
    onMutate: (variables) => {
      toast.loading('Aguarde...', { id: 'save', duration: 5000 });

      return options?.onMutate ? options?.onMutate(variables) : undefined;
    },
    onSuccess: (data, variables, context) => {
      if (typeof data.message === 'string') {
        toast.success(data.message || SUCCESS_MSG);
      } else {
        data.message?.forEach((msg) => toast.success(msg));
      }

      if (options?.onSuccess) options.onSuccess(data, variables, context);
    },
    onError: (error: TError, variables, context) => {
      let msg: string | string[] = ERROR_MSG;

      if (error === null || error === undefined) msg = ERROR_MSG;
      else if (Array.isArray(error)) {
        msg = error.map((e) => {
          if (typeof e === 'string') return e;
          if (e.message) return e.message;
          return `${e}`;
        });
      } else if (typeof error === 'string') msg = error;
      else if (typeof error === 'object') {
        const castedError: any = error;
        console.log(castedError?.message);
        msg = castedError?.message || ERROR_MSG;
        if (typeof castedError?.message === 'string') {
          const tmp = castedError.message as string;
          // Check if includes request on request
          msg = tmp.includes('equest') ? ERROR_MSG : tmp;
        }

        if (Array.isArray(castedError?.message)) {
          msg = castedError?.message || [];
        }
      }

      if (typeof msg === 'string') {
        toast.error(msg);
      } else {
        msg.forEach((_msg) => toast.error(_msg));
      }

      if (options?.onError) options.onError(error, variables, context);
    },
    onSettled: () => {
      toast.dismiss('save');
    },
  });

  return mutation;
}
