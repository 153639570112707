import React from 'react';
import { useFormContext } from 'react-hook-form';
import { TextfieldController } from 'src/components/forms/TextfieldController';

interface BillFormCheckProps {}

export const BillFormCheck: React.FC<BillFormCheckProps> = () => {
  const { control } = useFormContext();

  return (
    <TextfieldController
      control={control}
      name="checkInfo"
      size="small"
      label="Informações do Cheque"
      rules={{ required: false }}
    />
  );
};

export default BillFormCheck;
