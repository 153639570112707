import React from 'react';
import { Box, Card, CardContent, Typography } from '@material-ui/core';
import EnterpriseForm from '../forms/EnterpriseForm';

interface FirstNewEnterpriseInterface {}

export const FirstNewEnterprise: React.FC<FirstNewEnterpriseInterface> = () => (
  <Card>
    <CardContent
      sx={{
        display: 'flex',
        flexDirection: 'column',
        p: 4,
      }}
    >
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'space-between',
          mb: 3,
        }}
      >
        <div>
          <Typography color="textPrimary" gutterBottom variant="h4">
            Crie a sua empresa
          </Typography>
          <Typography color="textSecondary" variant="body2">
            Preencha seus dados para começar a gerenciar o seu setor finânceiro!
          </Typography>
        </div>
      </Box>
      <Box
        mb={1}
        sx={{
          flexGrow: 1,
          mt: 3,
        }}
      >
        <EnterpriseForm saveButtonLabel="Criar empresa" enterpriseToBeEdited={undefined} />
      </Box>
    </CardContent>
  </Card>
);

export default FirstNewEnterprise;
