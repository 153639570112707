import { FC } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';

import { Avatar, Box, Divider, Typography } from '@material-ui/core';

import useAuth from 'src/hooks/useAuth';
import Scrollbar from 'src/components/Scrollbar';
import Logo from 'src/components/Logo';
import { sidebarRoutes } from 'src/components/dashboard/DashboardSidebar';
import NavSection from 'src/components/NavSection';
import OctakdeskMenu from 'src/components/dashboard/components/OctadeskMenu';
import { isDesktop } from 'react-device-detect';
import { SelectAutocompleteEnterprise } from '../enterprises/components/SelectAutocompleteEnterprise';
import { useEnterprise } from '../enterprises/hooks/useEnterprise';

interface MenuPageInterface {}

const MenuPage: FC<MenuPageInterface> = () => {
  const location = useLocation();
  const { user } = useAuth();
  const { selectedEnterprise } = useEnterprise();

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
      }}
    >
      <Scrollbar options={{ suppressScrollX: true }}>
        <>
          <Box
            sx={{
              display: {
                lg: 'none',
                xs: 'flex',
              },
              width: '100%',
              justifyContent: isDesktop ? 'center' : 'start',
              p: 2,
            }}
          >
            <RouterLink to="/">
              <Logo
                sx={{
                  height: 30,
                  width: 97,
                }}
                color="blue"
              />
            </RouterLink>
          </Box>
          {isDesktop && (
            <>
              <Box sx={{ p: 2 }}>
                <Box
                  sx={{
                    alignItems: 'center',
                    backgroundColor: 'background.default',
                    borderRadius: 1,
                    display: 'flex',
                    overflow: 'hidden',
                    p: 2,
                  }}
                >
                  <RouterLink to="/perfil">
                    <Avatar
                      // @ts-ignore
                      src={selectedEnterprise?.avatar?.url || ''}
                      sx={{
                        cursor: 'pointer',
                        height: 48,
                        width: 48,
                      }}
                    />
                  </RouterLink>

                  <Box sx={{ ml: 2 }}>
                    <Typography color="textPrimary" variant="subtitle2">
                      {user?.name}
                    </Typography>
                    <Typography color="textSecondary" variant="body2">
                      {user?.role.label}
                    </Typography>
                  </Box>
                </Box>
                <Box mt={2}>
                  <SelectAutocompleteEnterprise />
                </Box>
              </Box>
              <Divider />
            </>
          )}
        </>
        <Box sx={{ p: 2 }}>
          {sidebarRoutes.map((section) => (
            <NavSection
              key={section.title}
              pathname={location.pathname}
              item={section}
              sx={{
                '& + &': {
                  mt: 1,
                },
              }}
            />
          ))}
          {isDesktop && <OctakdeskMenu />}
          {/* {sidebarSections.map((section) => (
            <NavSection
              key={section.title}
              pathname={location.pathname}
              sx={{
                '& + &': {
                  mt: 3,
                },
              }}
              {...section}
            />
          ))} */}
        </Box>
      </Scrollbar>
    </Box>
  );
};

export default MenuPage;
