import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import IconButton from '@material-ui/core/IconButton';
import { red } from '@material-ui/core/colors';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Menu,
  MenuItem,
  Typography,
} from '@material-ui/core';
import { QueryObserverResult } from 'react-query';
import { CustomAxiosResponse } from 'src/services/axiosService';
import { SyncAltRounded } from '@material-ui/icons';
import { useMutationToast } from 'src/hooks/useMutationToast';
import { useEnterprise } from 'src/pages/enterprises/hooks/useEnterprise';
import { TextfieldController } from 'src/components/forms/TextfieldController';
import { PulsingBadge } from '../../../components/PulsingBadge';
import { editBankIntegration } from '../services/editBankIntegration';
import { IBankIntegration } from '../interfaces/BankIntegration.interface';
import { syncAllBankAccounts } from '../services/syncAllBankAccounts';
import { deleteBankIntegration } from '../services/deleteBankIntegration';

interface IBankIntegrationCard {
  integration: IBankIntegration;
  refetchCallback: () => Promise<QueryObserverResult<CustomAxiosResponse<IBankIntegration[]>, unknown>>;
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
  },
  media: {
    height: 'auto',
    paddingTop: '56.25%', // 16:9
    backgroundColor: '#f5f5f5',
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  avatar: {
    backgroundColor: red[500],
  },
}));

export const OpenBankingIntegrationCard: React.FC<IBankIntegrationCard> = ({
  integration,
  refetchCallback,
}) => {
  const { selectedEnterprise } = useEnterprise();
  const [isActive, setIsActive] = React.useState<boolean>(integration.is_active);
  const [isOpenConfirmationDialog, setIsOpenConfirmationDialog] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const classes = useStyles();

  const mutateSyncBank = useMutationToast(syncAllBankAccounts);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const { handleSubmit, control } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
  });

  const closeModal = () => setIsOpenConfirmationDialog(false);

  const onSubmit = (data: any) => {
    mutateSyncBank.mutate({
      ...data,
      enterpriseUUID: selectedEnterprise?.uuid || '',
      item_id: integration.item_id,
    });
  };

  // get the first two letters from name splitted by space
  const badgeColor = isActive ? 'success' : 'danger';

  const mutateUpdateBankIntegration = useMutationToast(() => editBankIntegration({
    enterpriseUuid: integration.enterprise.uuid,
    integration: { is_active: !isActive },
    integrationUuid: integration.uuid,
  }), {
    onSuccess: ({ data }) => setIsActive(data.is_active),
  });

  const mutateDeleteIntegration = useMutationToast(() => deleteBankIntegration(integration.uuid), {
    onSuccess: () => refetchCallback(),
  });

  const startSyncBank = () => integration?.connector.hasMFA
    ? setIsOpenConfirmationDialog(true)
    : mutateSyncBank.mutate({
      enterpriseUUID: selectedEnterprise?.uuid || '',
      item_id: integration.item_id,
    });

  return (
    <>
      <Card className={classes.root}>
        <CardMedia
          className={classes.media}
          image={integration.connector.imageUrl}
          title={integration.connector.name}
        />

        <Box display="flex" flexDirection="column" justifyContent="space-between" height="100%">
          <CardHeader
            // avatar={<PulsingBadge variant={badgeColor} />}
            action={
              <>
                <IconButton aria-label="settings" onClick={handleClick}>
                  <MoreVertIcon />
                </IconButton>
                <Menu id="simple-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
                  {integration.connector.institutionUrl && (
                    <MenuItem
                      onClick={() => {
                        handleClose();
                        window.open(integration.connector.institutionUrl, '_blank');
                      }}
                    >
                      Abrir site
                    </MenuItem>
                  )}
                  <MenuItem
                    onClick={() => {
                      handleClose();
                      mutateUpdateBankIntegration.mutate();
                    }}
                  >
                    {isActive ? 'Desativar' : 'Ativar'}
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      handleClose();
                      mutateDeleteIntegration.mutate();
                    }}
                  >
                    Excluir
                  </MenuItem>
                </Menu>
              </>
            }
            title={integration.nickname}
            subheader={integration.connector.name}
          />
          <Box />
          {integration.accounts.map((account) => (
            <Box width="100%" key={`integration-${integration.uuid}-account-${account.id}`}>
              <Divider />
              <Box px={2} py={1.5} display="flex" flexDirection="row" alignContent="center">
                <Box display="flex" mr={2}>
                  <PulsingBadge variant={badgeColor} />
                </Box>
                <Box display="flex" flexDirection="column">
                  <Typography variant="body1">{account.name}</Typography>
                  <Typography variant="body2">{account.bankData.transferNumber || account.number}</Typography>
                </Box>
              </Box>
              <Box>
                <Button
                  color="secondary"
                  variant="outlined"
                  onClick={startSyncBank}
                  startIcon={<SyncAltRounded fontSize="small" />}
                  sx={{ m: 1 }}
                  disabled={!isActive}
                >
                  Sincronizar
                </Button>
              </Box>
            </Box>
          ))}
        </Box>
      </Card>
      <Dialog
        open={isOpenConfirmationDialog}
        keepMounted
        onClose={closeModal}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      // disableEscapeKeyDown={mutateEditInstallment.isLoading}
      >
        <DialogTitle id="alert-dialog-slide-title">
          Necessitamos do seu TOKEN para continuar a sincronização
        </DialogTitle>
        <DialogContent>
          <form>
            <Grid container spacing={0}>
              {integration.connector.credentials.map((field) => (
                <Grid item xs={12} md={12} key={field.name}>
                  <TextfieldController
                    control={control}
                    name={field?.name || ''}
                    variant="outlined"
                    size="small"
                    label={field?.label || ''}
                    rules={{ required: !field?.optional }}
                  />
                </Grid>
              ))}
            </Grid>
          </form>
        </DialogContent>
        <DialogActions>
          <Button
            disabled={mutateSyncBank.isLoading}
            onClick={() => setIsOpenConfirmationDialog(false)}
            color="primary"
          >
            Cancelar
          </Button>
          <Button
            disabled={mutateSyncBank.isLoading}
            onClick={handleSubmit(onSubmit)}
            color="primary"
            variant="contained"
          >
            Continuar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default OpenBankingIntegrationCard;
