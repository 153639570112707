import { BillInterface } from "src/pages/bills/types/BillInterface";
import { axiosPOST, CustomAxiosResponse } from "src/services/axiosService";

export type IPluggyToken = string;

interface ISyncBankAccount {
  [key: string]: string;
  item_id: string,
  enterpriseUUID: string
}

export const syncAllBankAccounts = async (
  { enterpriseUUID, item_id, ...rest }: ISyncBankAccount,
): Promise<CustomAxiosResponse<void>> => {
  try {
    const endpoint = `${process.env.REACT_APP_BACKEND}/open-banking/sync/${enterpriseUUID}`;

    const data = {
      ...rest,
      item_id,
    }

    const response = await axiosPOST<void>(endpoint, data);

    return {
      ...response,
      data: response.data,
      message: 'Contas sincronizadas com sucesso, atualize a página.',
    };
  } catch (error: any) {
    throw new Error(error?.message ? error.message : 'Ocorreu um erro, tente novamente.');
  }
};
