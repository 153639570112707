import { Button, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { TextfieldController } from 'src/components/forms/TextfieldController';
import { useMutationToast } from 'src/hooks/useMutationToast';
import { useEnterprise } from 'src/pages/enterprises/hooks/useEnterprise';
import { CreateItemTag, ItemTagToBeCreatedInterface } from '../../services/createItemTag';

interface KeyLabel {
  key: string;
  label: string;
}

interface AutocompleteTagCreatableFormInterface {
  fieldValue: string;
  handleClose: () => void;
  updateValues: (newValue: KeyLabel) => void;
}

interface FormInterface extends Pick<ItemTagToBeCreatedInterface, 'title'> {}

export const AutocompleteItemTagCreateForm: React.FC<AutocompleteTagCreatableFormInterface> = ({
  fieldValue,
  handleClose,
  updateValues,
}) => {
  const { selectedEnterprise } = useEnterprise();
  const mutateCreate = useMutationToast(CreateItemTag, {
    onSuccess: ({ data }) => {
      updateValues({ key: data.uuid || '', label: data.title });
      handleClose();
    },
  });

  const onSubmit = (data: FormInterface) => {
    mutateCreate.mutate({
      enterpriseUuid: selectedEnterprise?.uuid || '',
      tag: {
        title: data.title,
      },
    });
  };

  const { handleSubmit, control, setValue } = useForm<FormInterface>({
    defaultValues: { title: fieldValue },
    mode: 'onChange',
    reValidateMode: 'onChange',
  });

  useEffect(() => {
    setValue('title', fieldValue);
  }, [fieldValue, setValue]);

  return (
    <>
      <form>
        <DialogTitle id="form-dialog-title-business-contact">Nova tag de item</DialogTitle>
        <DialogContent>
          <TextfieldController
            control={control}
            name="title"
            label="Nome da tag"
            disabled={mutateCreate.isLoading}
            rules={{
              required: true,
            }}
          />
          <DialogContentText>Não se preocupe, você pode adicionar mais informações depois!</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button disabled={mutateCreate.isLoading} onClick={handleClose} variant="text">
            Cancelar
          </Button>
          <Button
            disabled={mutateCreate.isLoading}
            type="button"
            color="primary"
            onClick={(e) => handleSubmit(onSubmit)(e)}
          >
            Criar
          </Button>
        </DialogActions>
      </form>
    </>
  );
};
