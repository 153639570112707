import React from 'react';
import { useFormContext } from 'react-hook-form';
import useMovements from 'src/pages/movements/hooks/useMovements';
import { MovimentTypesSlugEnum } from 'src/pages/movements/types/MovementInterface';
import { RadioController } from '../../../../components/forms/RadioController';
import { CreateBillInteface } from '../../services/createBill';

interface BillFormCheckMovementOrBillProps {
  isEditForm: boolean;
}

export const BillFormCheckMovementOrBill: React.FC<BillFormCheckMovementOrBillProps> = ({ isEditForm }) => {
  const { formType } = useMovements();
  const { control } = useFormContext<CreateBillInteface>();

  return (
    <RadioController
      control={control}
      disabled={isEditForm}
      values={[
        {
          key: 'movement',
          label:
            formType === MovimentTypesSlugEnum.invoice
              ? 'Já recebi, só quero registrar!'
              : 'Já paguei, só quero registrar!',
        },
        {
          key: 'bill',
          label:
            formType === MovimentTypesSlugEnum.invoice
              ? 'Ainda não recebi!'
              : 'Ainda não paguei!',
        },
      ]}
      rules={{ required: true }}
      name="bill_or_movement"
      variant="outlined"
    />
  );
  // const { formType } = useMovements();
  // const { control, watch, setValue } = useFormContext<CreateBillInteface>();

  // const dueDate = watch('due_date');

  // const isDueDateBeforeToday = isBefore(dueDate, new Date());
  // const isDueDateToday = isToday(dueDate);
  // const isDueDateAfterToday = isAfter(dueDate, new Date());

  // useEffect(() => {
  //   if (isDueDateBeforeToday) setValue('bill_or_movement', 'movement');
  //   if (isDueDateAfterToday) setValue('bill_or_movement', 'bill');
  // }, [dueDate, isDueDateAfterToday, isDueDateBeforeToday, setValue]);

  // return isDueDateToday ? (
  //   <>
  //     <RadioController
  //       control={control}
  //       disabled={isEditForm}
  //       values={[
  //         {
  //           key: 'movement',
  //           label:
  //             formType === MovimentTypesSlugEnum.invoice
  //               ? 'Já recebi, só quero registrar!'
  //               : 'Já paguei, só quero registrar!',
  //         },
  //         {
  //           key: 'bill',
  //           label:
  //             formType === MovimentTypesSlugEnum.invoice
  //               ? 'Ainda não recebi!'
  //               : 'Ainda não paguei!',
  //         },
  //       ]}
  //       rules={{ required: true }}
  //       name="bill_or_movement"
  //       variant="outlined"
  //     />
  //   </>
  // ) : null;
};

export default BillFormCheckMovementOrBill;
