import React, { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useQuery } from 'react-query';
import { useEnterprise } from 'src/pages/enterprises/hooks/useEnterprise';
import { AutocompleteTagCreate } from 'src/pages/tags/forms/autocompleteInput/AutocompleteTagCreate';
import { getAllTags } from 'src/pages/tags/services/getAllTags';
import { BillInterface } from '../../types/BillInterface';

interface BillFormTagProps {
  billToBeEdited: BillInterface | undefined;
}

export const BillFormTag: React.FC<BillFormTagProps> = ({ billToBeEdited }) => {
  const { selectedEnterprise } = useEnterprise();
  const { control, setValue } = useFormContext();

  const tagsQuery = useQuery('tags', () => getAllTags(selectedEnterprise?.uuid || ''));

  const tagData = useMemo(() => tagsQuery?.data?.data || [], [tagsQuery.data]);
  const tagsOptions = useMemo(
    () =>
      tagData.map(({ title, uuid }) => ({
        key: uuid,
        label: title,
      })),
    [tagData],
  );

  return (
    <AutocompleteTagCreate
      control={control}
      rules={{ required: false }}
      name="tag_uuid"
      size="small"
      label="Tag"
      values={tagsOptions}
      onSuccess={() => tagsQuery.refetch()}
      setValue={setValue}
      defaultTag={billToBeEdited?.tag}
    />
  );
};

export default BillFormTag;
