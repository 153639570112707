import { axiosDelete, CustomAxiosResponse } from "src/services/axiosService";

export const deleteBankIntegration = async (
  id: string,
): Promise<CustomAxiosResponse<unknown>> => {
  try {
    const endpoint = `${process.env.REACT_APP_BACKEND}/open-banking/${id}`;

    const response = await axiosDelete<unknown>(endpoint);

    return {
      ...response,
      data: response.data,
      message: 'Pronto!',
    };
  } catch (error: any) {
    throw new Error(error?.message ? error.message : 'Ocorreu um erro, tente novamente.');
  }
};
