import { FC, useContext, useState } from 'react';
import { Box, Container, Grid, Fade } from '@material-ui/core';
import EmptyPlaceholder from 'src/components/layouts/EmptyPlaceholder';
import LoadingScreen from 'src/components/LoadingScreen';
import { EnterpriseCard } from './components/EnterpriseCard';
import { FirstNewEnterprise } from './components/FirstNewEnterprise';
import { useEnterprise } from './hooks/useEnterprise';
import { EnterpriseContext } from './contexts/EnterpriseContext/EnterpriseContext';

const CreateNewEnterprisePage: FC = () => {
  const { availableEnterprises } = useEnterprise();
  const { query } = useContext(EnterpriseContext);
  const [showCreate, setShowCreate] = useState<boolean>(false);

  const EnterpriseMap = availableEnterprises?.map((_enterprise) => (
    <EnterpriseCard key={_enterprise.uuid} enterprise={_enterprise} />
  ));

  return (
    <Box mt={4} mb={4}>
      <Container maxWidth="sm">
        <Fade in={!showCreate} unmountOnExit>
          <div>
            <Box sx={{ mt: 3 }}>
              {query?.isError && (
                <EmptyPlaceholder title="Ocorreu um problema ao carregar" subtitle="Tente novamente" />
              )}
              {query?.isFetching ? (
                <LoadingScreen />
              ) : (
                (query?.isSuccess || query === undefined) && (
                  <Grid container spacing={4}>
                    <Grid item>
                      {availableEnterprises.length > 0 ? (
                        EnterpriseMap
                      ) : (
                        <EmptyPlaceholder
                          title="Ooops, você ainda não tem uma empresa cadastrada"
                          subtitle="Começar agora"
                          staticPath="/static/error/error404_"
                          buttonTitle="Cadastrar empresa"
                          onClick={() => setShowCreate(true)}
                        />
                      )}
                    </Grid>
                  </Grid>
                )
              )}
            </Box>
          </div>
        </Fade>
        <Fade
          in={showCreate}
          unmountOnExit
          timeout={{
            enter: 1000,
          }}
        >
          <div>
            <FirstNewEnterprise />
          </div>
        </Fade>
      </Container>
    </Box>
  );
};

export default CreateNewEnterprisePage;
