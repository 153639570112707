import { Box, Grid, Typography } from '@material-ui/core';
import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useQuery } from 'react-query';
import CurrencyInput from 'src/components/forms/MaskedTextField/types/Currency';
import { PercentageFieldController } from 'src/components/forms/PercentageInputController';
import { SelectController } from 'src/components/forms/SelectController';
import { TextfieldController } from 'src/components/forms/TextfieldController';
import TitleSection from 'src/components/TitleSection';
import { CreateBillInteface } from '../services/createBill';
import { getFiscalServices } from '../services/getFiscalServices';
import { BillInterface } from '../types/BillInterface';

const impostos = [
  { name: 'COFINS', value: 'cofins' },
  { name: 'CSLL', value: 'csll' },
  { name: 'INSS', value: 'inss' },
  { name: 'IR', value: 'ir' },
  { name: 'PIS', value: 'pis' },
];

const shouldRetainOptions = [
  { label: 'Sim', key: 'sim' },
  { label: 'Não', key: 'não' },
];

interface BillFiscalFormInterface {
  billToBeEdited: BillInterface | undefined;
  fullWidth?: boolean;
}

const BillFiscalForm: React.FC<BillFiscalFormInterface> = () => {
  const { control, setValue, watch } = useFormContext<CreateBillInteface>();

  const selectedFiscalService = watch('fiscal.service');
  const shouldEmitFiscal = watch('emitNfse');

  const fiscalServices = useQuery('listAllFiscalServices', getFiscalServices);

  useEffect(() => {
    if (selectedFiscalService) {
      const fiscalService = fiscalServices?.data?.data.find(({ key }) => key === selectedFiscalService);
      setValue('fiscal.description', fiscalService?.label || '');
    }
  }, [selectedFiscalService, fiscalServices?.data?.data, setValue]);

  return (
    <>
      <Grid item xs={12}>
        <TitleSection
          title="Serviço"
          tooltip={[
            'Neste campo, você irá registrar produtos e/ou serviços. que geram faturamento ou despesa.',
            'Sabe o que é legal? Você poderá gerenciá-los depois!',
            'Para criar uma conta, você precisa adicionar pelo menos um item!',
          ]}
        />
        <SelectController
          values={fiscalServices?.data?.data || []}
          control={control}
          name="fiscal.service"
          rules={{ required: shouldEmitFiscal }}
          truncateText
        />
      </Grid>

      <Grid item xs={12}>
        <TitleSection
          title="Descrição da atividade"
        />
        <Box mt={1}>
          <TextfieldController
            control={control}
            name="fiscal.description"
            rules={{ required: shouldEmitFiscal }}
            readonly
          />
        </Box>
      </Grid>

      <Grid item xs={12}>
        <TitleSection
          title="Descrição do serviço"
        />
        <Box mt={1}>
          <TextfieldController
            control={control}
            name="fiscal.descricao_nota"
            rules={{ required: shouldEmitFiscal }}
          />
        </Box>
      </Grid>

      <Grid container spacing={2} mt={2}>
        <Grid item xs={12} md={6}>
          <TitleSection
            title="Cliente deve reter ISS?"
          />
          <Box mt={1}>
            <SelectController
              values={shouldRetainOptions}
              control={control}
              name="fiscal.client_should_retain"
              rules={{ required: shouldEmitFiscal }}
              truncateText
            />
          </Box>
        </Grid>

        <Grid item xs={12} md={6}>
          <TitleSection
            title="Alíquota ISS"
          />
          <Box mt={1}>
            <PercentageFieldController
              control={control}
              name="fiscal.aliquota_iss"
              rules={{ required: shouldEmitFiscal }}
              size="small"
            />
          </Box>
        </Grid>
      </Grid>

      <Box mt={1}>
        <TitleSection
          title="Impostos retidos desta cobrança"
        />
      </Box>

      <Box mt={2}>
        {impostos.map((imposto) => (
          <Grid container spacing={3} key={imposto.value}>
            <Grid item xs={12} md={4}>
              <TitleSection
                title=""
                subtitle={`Porcentagem ${imposto.name}`}
              />
              <Box mt={1}>
                <PercentageFieldController
                  control={control}
                  name={`fiscal.${imposto.value}.aliquota`}
                  rules={{ required: shouldEmitFiscal, minLength: 5 }}
                  size="small"
                  endIcon={<Typography variant="body1">%</Typography>}
                />
              </Box>
            </Grid>

            <Grid item xs={12} md={4}>
              <TitleSection
                title=""
                subtitle={`Valor ${imposto.name}`}
              />
              <Box mt={1}>
                <TextfieldController
                  control={control}
                  name={`fiscal.${imposto.value}.valor`}
                  variant="outlined"
                  size="small"
                  currency="cents"
                  inputComponent={CurrencyInput}
                  rules={{ required: shouldEmitFiscal }}
                />
              </Box>
            </Grid>
          </Grid>
        ))}
      </Box>
    </>
  );
};

export default BillFiscalForm;
