import { RolesEnum, UserInterface } from '../pages/users/types/UserInterface';
import { login } from './../pages/authentication/services/login';
import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import firebase from 'firebase/app';
import { useContext } from 'react';
import { AuthContext } from 'src/contexts/AuthContext/AuthContext';
import { AuthTypesEnum } from 'src/contexts/AuthContext/AuthInterface';
import { AUTH_TOKEN, VariantEnum } from 'src/services/axiosService';
import { registerUser, RegisterInterface } from 'src/pages/authentication/services/register';
import { useUser } from './useUser';

interface LoginMethodInterface {
  email: string;
  password: string;
}

interface UseAuthInterface {
  method: string;
  user: UserInterface | undefined;
  isLoading: boolean;
  isAuthenticated: boolean;
  isAdmin: boolean;
  login: (props: LoginMethodInterface) => Promise<void>;
  loginWithGoogle: () => Promise<void> | undefined;
  loginWithFaceBook: () => Promise<firebase.auth.UserCredential> | undefined;
  loginWithTwitter: () => Promise<firebase.auth.UserCredential> | undefined;
  loginWithMicrosoft: () => Promise<firebase.auth.UserCredential> | undefined;
  register: (newUser: RegisterInterface) => Promise<void>;
  logout: () => Promise<void>;
  resetPassword: (email: string) => Promise<void> | undefined;
  updateProfile: () => Promise<void> | undefined;
}

export function useAuth(): UseAuthInterface {
  const token = window.localStorage.getItem(AUTH_TOKEN);
  const history = useNavigate();
  const { handleChangeUserPlan } = useUser();
  const { firebaseAuth, dispatchAuth, stateAuth } = useContext(AuthContext);

  const providerGoogle = new firebase.auth.GoogleAuthProvider();
  const providerTwitter = new firebase.auth.GoogleAuthProvider();
  const providerFacebook = new firebase.auth.GoogleAuthProvider();
  const providerMicrosoft = new firebase.auth.OAuthProvider('microsoft.com');

  const isAuthenticated = stateAuth.loggedUser !== undefined && !!token;

  return {
    method: 'JWT',
    user: stateAuth.loggedUser,
    isLoading: false,
    isAuthenticated,
    isAdmin: stateAuth.loggedUser?.role.slug === RolesEnum.admin,
    login: async (props: LoginMethodInterface) => {
      try {
        const firebaseUser = await firebaseAuth?.signInWithEmailAndPassword(props.email, props.password);

        const tokenFirebase = await firebaseUser?.user?.getIdToken();
        console.debug(tokenFirebase);

        if (!tokenFirebase) {
          toast.error('Não foi possível fazer login');
          return;
        }

        const { data, message, variant } = await login(tokenFirebase);

        dispatchAuth({
          type: AuthTypesEnum.AUTHENTICATE_USER,
          loggedUser: data.user,
          token: data.token,
          navigate: history,
        });

        handleChangeUserPlan(data.user);

        const msg = typeof message === 'string' ? message : message[0];

        variant === VariantEnum.success ? toast.success(msg) : toast.error(msg);
      } catch (error: any) {
        let errorMessage = '';
        switch (error.code) {
          case 'auth/internal-error':
            errorMessage = 'Ocorreu um problema, tente novamente.';
            break;

          case 'auth/invalid-credential':
            errorMessage = 'Email ou senha incorretos, tente novamente.';
            break;

          case 'auth/invalid-email	':
            errorMessage = 'O email informado é incorreto, tente novamente';
            break;

          case 'auth/invalid-email-verified	':
            errorMessage = 'O email informado é inválido.';
            break;

          case 'auth/user-not-found':
            errorMessage = 'O usuário não foi encontrado, tente novamente';
            break;

          case 'auth/wrong-password':
            errorMessage = 'A senha informada é incorreta ou o usúario não existe. Tente novamente';
            break;

          default:
            errorMessage = 'Não foi possível fazer login, tente novamente';
            break;
        }

        toast.error(errorMessage);
      }
    },
    loginWithGoogle: async () => {
      const firebaseUser = await firebaseAuth?.signInWithPopup(providerGoogle);

      const tokenFirebase = await firebaseUser?.user?.getIdToken();
      if (!tokenFirebase) {
        toast.error('Não foi possível fazer login');
        return;
      }

      const { data } = await login(tokenFirebase);

      dispatchAuth({
        type: AuthTypesEnum.AUTHENTICATE_USER,
        loggedUser: data.user,
        token: data.token,
        navigate: history,
      });
    },
    loginWithFaceBook: () => firebaseAuth?.signInWithPopup(providerFacebook),
    loginWithTwitter: () => firebaseAuth?.signInWithPopup(providerTwitter),
    loginWithMicrosoft: () => firebaseAuth?.signInWithPopup(providerMicrosoft),
    logout: async () => {
      localStorage.removeItem('selected-enterprise');
      localStorage.removeItem('accessToken');
      dispatchAuth({
        type: AuthTypesEnum.LOGOUT_USER,
      });
    },
    resetPassword: (email: string) => firebaseAuth?.sendPasswordResetEmail(email),
    // @ts-ignore
    register: async (newUser: RegisterInterface) => {
      try {
        const { data, message, variant } = await registerUser(newUser);
  
        dispatchAuth({
          type: AuthTypesEnum.AUTHENTICATE_USER,
          loggedUser: data.user,
          token: data.token,
          navigate: history,
        });
  
        const msg = typeof message === 'string' ? message : message[0];
  
        variant === VariantEnum.success ? toast.success(msg) : toast.error(msg);
      }catch(error: any) {
        let errorMessage = '';

        switch(error.message) {
          case 'The email address is already in use by another account.':
            errorMessage = 'O email informado já está sendo usado por outro usuário.';
            break;
        }
        toast.error(errorMessage);
      }
    },
    // @ts-ignore
    updateProfile: async (formData: any) => {
      // if (!stateAuth.loggedUser?.uid) return;
      // const response = await updateUser(
      //   stateAuth.loggedUser?.uid,
      //   formData,
      //   cookies[AUTH_TOKEN],
      // );
      // toast.error(errorMessage);
    },
  };
}

export default useAuth;
