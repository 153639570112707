import React from 'react';
import { useForm } from 'react-hook-form';
import { Box, Grid } from '@material-ui/core';
import { Save } from '@material-ui/icons';
import MutatingButton from 'src/components/forms/MutatingButton';
import { useMutationToast } from 'src/hooks/useMutationToast';
import { useNavigate } from 'react-router';
import { TextfieldController } from 'src/components/forms/TextfieldController';
import { InputDocTypeController } from 'src/components/forms/InputDocType';
import { createEnterprise, CreateEnterpriseInteface } from '../services/createEnterprise';
import { editEnterprise } from '../services/editEnterprise';
import { EnterpriseInterface } from '../types/EnterpriseInterface';
import { useEnterprise } from '../hooks/useEnterprise';

interface EnterpriseFormInterface {
  enterpriseToBeEdited: EnterpriseInterface | undefined;
  saveButtonLabel?: string;
}

const EnterpriseForm: React.FC<EnterpriseFormInterface> = ({ enterpriseToBeEdited, saveButtonLabel = 'Salvar' }) => {
  const { addNewEnterprise, query, updateEnterpriseInfo } = useEnterprise();
  const navigate = useNavigate();
  const isEditForm = !!enterpriseToBeEdited;
  const mutateEdit = useMutationToast(editEnterprise, {
    onSuccess: (data) => {
      updateEnterpriseInfo(data.data);
      query?.refetch();
      navigate('/perfil');
    },
  });
  const mutateCreate = useMutationToast(createEnterprise, {
    onSuccess: ({ data }) => {
      addNewEnterprise(data);
      navigate('/empresas/selecionar');
    },
  });

  const { handleSubmit, control, formState } = useForm<CreateEnterpriseInteface>({
    defaultValues: {
      vat_number: enterpriseToBeEdited?.vat_number || '',
      company_name: enterpriseToBeEdited?.company_name || '',
      fantasy_name: enterpriseToBeEdited?.fantasy_name || '',
      email: enterpriseToBeEdited?.email || '',
    },
    mode: 'onChange',
    reValidateMode: 'onChange',
  });

  const handleEditEnterprise = (data: CreateEnterpriseInteface) =>
    mutateEdit.mutate({
      ...enterpriseToBeEdited,
      ...data,
    });

  const onSubmit = (data: CreateEnterpriseInteface) =>
    isEditForm ? handleEditEnterprise(data) : mutateCreate.mutate(data);

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <InputDocTypeController
                control={control}
                name="vat_number"
                mask="cnpj"
                rules={{
                  required: true,
                }}
                label="CNPJ"
              />
            </Grid>

            <Grid item xs={12} md={12}>
              <TextfieldController
                control={control}
                name="company_name"
                label="Razão social"
                rules={{
                  required: true,
                }}
              />
            </Grid>

            <Grid item xs={12} md={12}>
              <TextfieldController
                control={control}
                name="fantasy_name"
                label="Nome fantasia"
                rules={{
                  required: false,
                }}
              />
            </Grid>

            <Grid item xs={12} md={12}>
              <TextfieldController
                control={control}
                name="email"
                label="E-mail"
                rules={{
                  required: true,
                  pattern: /^\S+@\S+$/i,
                }}
              />
            </Grid>
          </Grid>

          <Box display="flex" flexDirection="row-reverse">
            <MutatingButton
              startIcon={<Save fontSize="small" />}
              type="submit"
              variant="contained"
              disabled={!formState.isValid}
              loadingText="Salvando..."
            >
              {saveButtonLabel}
            </MutatingButton>
          </Box>
        </>
      </form>
    </>
  );
};

export default EnterpriseForm;
