import { axiosPOST, CustomAxiosResponse } from 'src/services/axiosService';
import { convertFormattedMoneyToCents } from 'src/utils/moneyConverter';
import { BillInterface, FiscalInterface } from './../types/BillInterface';

export interface CreateBillInteface
  extends Omit<BillInterface, 'payment_types' | 'entreprise' | 'business_contacts' | 'bill_types' | 'fiscal'> {
  payment_types_uuid: string;
  enterprise_uuid: string;
  business_contact_uuid: string;
  bill_types_uuid: string;
  prize_type?: string;
  installmentPrize?: string;
  tag_uuid?: string;
  payment_provider_uuid?: string;
  checkInfo: string;
  bill_or_movement: string;
  should_settle: boolean;
  fiscal: FiscalInterface;
  emitNfse: boolean;
}

interface CreateBillProps {
  billToBeCreated: CreateBillInteface;
  alreadyPaid: boolean;
}

export const createBill = async ({
  billToBeCreated,
  alreadyPaid,
}: CreateBillProps): Promise<CustomAxiosResponse<BillInterface[]>> => {
  try {
    const endpoint = `${process.env.REACT_APP_BACKEND}/bills`;

    const _billToBeCreated: CreateBillInteface = {
      ...billToBeCreated,
      installmentValue: billToBeCreated.installmentValue,
      should_settle: billToBeCreated.bill_or_movement !== 'bill',
      bill_items: billToBeCreated.bill_items.map((item) => ({
        ...item,
        unit_price: convertFormattedMoneyToCents(item.unit_price),
        total: convertFormattedMoneyToCents(item.unit_price) * Number(item.quantity),
        description: item.description || '',
      })),
      payment_date: alreadyPaid ? billToBeCreated.payment_date : null,
      installments_value: billToBeCreated.installments_value.map((item) => ({
        value: convertFormattedMoneyToCents(item.value),
      }))
    };

    const response = await axiosPOST<BillInterface[]>(endpoint, _billToBeCreated);

    return {
      ...response,
      data: response.data,
      message: 'Pronto!',
    };
  } catch (error: any) {
    throw new Error(error.length > 0 ? error[0] : 'Ocorreu um erro, tente novamente.');
  }
};
