import { ItemTag } from 'src/pages/bills/types/BillInterface';
import { axiosGET, CustomAxiosResponse } from 'src/services/axiosService';

export const getAllItemTags = async (enterpriseId: string): Promise<CustomAxiosResponse<ItemTag[]>> => {
  try {
    const endpoint = `${process.env.REACT_APP_BACKEND}/item-tag/${enterpriseId}`;

    const response = await axiosGET<ItemTag[]>(endpoint);

    return {
      ...response,
      data: response.data,
      message: 'Pronto!',
    };
  } catch (error: any) {
    throw new Error(error?.message ? error.message : 'Ocorreu um erro, tente novamente.');
  }
};
