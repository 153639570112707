import { authenticateUserAction } from './actions/authenticateUserAction';
import { logoutUserAction } from './actions/logoutUserAction';
import { setFirebaseUserAction } from './actions/setFirebaseUserAction';
import {
  UPDATE_STATE,
  AuthContextInterface,
  AuthTypesEnum,
  AUTHENTICATE_USER,
  LOGOUT_USER,
  SET_FIREBASE_USER,
} from './AuthInterface';

export type AuthAction = UPDATE_STATE | AUTHENTICATE_USER | LOGOUT_USER | SET_FIREBASE_USER; // | OTHER_TYPE_GOES_HERE;

function authReducer(state: AuthContextInterface, action: AuthAction): AuthContextInterface {
  switch (action.type) {
    case AuthTypesEnum.LOGOUT_USER:
      return logoutUserAction(state, action);

    case AuthTypesEnum.AUTHENTICATE_USER:
      return authenticateUserAction(state, action);

    case AuthTypesEnum.SET_FIREBASE_USER:
      return setFirebaseUserAction(state, action);

    default:
      return state;
  }
}

export default authReducer;
