import { Box, Grid } from '@material-ui/core';
import { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useQuery } from 'react-query';
import TitleSection from 'src/components/TitleSection';
import useAuth from 'src/hooks/useAuth';
import { useEnterprise } from 'src/pages/enterprises/hooks/useEnterprise';
import { listAllMovementsPaymentTypes } from 'src/pages/movements/services/listAllMovementsPaymentTypes';
import { listAllMovementsTypes } from 'src/pages/movements/services/listAllMovementsTypes';
import { getAllPaymentOptions } from 'src/pages/paymentOptions/services/getAllPaymentOptions';
import { PlansEnum } from 'src/pages/users/types/UserInterface';
import { BillInterface } from '../types/BillInterface';
import { BillFormBillTypes } from './fields/BillFormBillTypes';
import { BillFormBusiness } from './fields/BillFormBusiness';
import { BillFormCheck } from './fields/BillFormCheck';
import { BillFormCheckMovementOrBill } from './fields/BillFormCheckMovementOrBill';
import { BillFormComments } from './fields/BillFormComments';
import { BillFormDueDate } from './fields/BillFormDueDate';
import { BillFormInstallmentPrize } from './fields/BillFormInstallmentPrize';
import { BillFormInstallments } from './fields/BillFormInstallments';
import { BillFormNumRef } from './fields/BillFormNumRef';
import { BillFormPaymentDate } from './fields/BillFormPaymentDate';
import { BillFormPaymentProvider } from './fields/BillFormPaymentProvider';
import { BillFormPaymentTypes } from './fields/BillFormPaymentTypes';
import { BillFormPrizeType, InstallmentPrizeEnum } from './fields/BillFormPrizeType';
import { BillFormProductsOrServices } from './fields/BillFormProductsOrServices';
import { BillFormTag } from './fields/BillFormTag';

export interface BillFormGroupInterface {
  billToBeEdited: BillInterface | undefined;
  fullWidth?: boolean;
  isDisabledSaveButton?: boolean;
}

const BillFormGroup: React.FC<BillFormGroupInterface> = ({ billToBeEdited, fullWidth }) => {
  const { selectedEnterprise } = useEnterprise();
  const { user } = useAuth();
  const isEditForm = !!billToBeEdited;
  const { watch } = useFormContext();

  const paymentTypes = useQuery('listAllMovementsTypes', listAllMovementsTypes);
  const providersOptions = useQuery('getAllPaymentOptions', () => getAllPaymentOptions(`${selectedEnterprise?.uuid}`));
  const billPaymentsType = useQuery('listAllMovementsPaymentTypes', listAllMovementsPaymentTypes);
  const billTypesData = useMemo(() => paymentTypes?.data?.data || [], [paymentTypes.data]);
  const billPaymentsTypes = useMemo(() => billPaymentsType?.data?.data || [], [billPaymentsType.data]);
  const paymentsProviders = useMemo(() => providersOptions?.data?.data || [], [providersOptions.data]);

  const billOrMovement = watch('bill_or_movement');
  const selectedPaymentID = watch('payment_types_uuid');
  const selectedId = watch('bill_types_uuid');

  const alreadyPaid = billOrMovement === 'movement';

  const selectedBillType = useMemo(
    () => billTypesData?.find(({ uuid }) => uuid === selectedId), [billTypesData, selectedId],
  );

  const selectedPayment = useMemo(
    () => billPaymentsTypes?.find(({ uuid }) => uuid === selectedPaymentID),
    [selectedPaymentID, billPaymentsTypes],
  );

  const isBoleto = selectedPayment?.label === 'Boleto Bancário';
  const isCheque = selectedPayment?.label === 'Cheque';
  const hasOthersPaymentsOptions = isBoleto || isCheque;
  const isProUser = user?.plan !== PlansEnum.FREE;

  return (
    <>
      <Grid display="none" item xs={12} md={fullWidth || isEditForm ? 12 : 6}>
        <BillFormBillTypes isEditForm={isEditForm} billTypesData={billTypesData} />
      </Grid>

      <Grid container spacing={1}>
        <Grid item xs={12} md={!alreadyPaid ? 8 : 6}>
          <BillFormBusiness
            isEditForm={isEditForm}
            billTypesData={billTypesData}
            selectedEnterprise={selectedEnterprise}
            billToBeEdited={billToBeEdited}
          />
        </Grid>

        <Grid item xs={12} md={!alreadyPaid ? 4 : 3}>
          <BillFormDueDate isEditForm={isEditForm} />
        </Grid>

        {alreadyPaid && (
          <Grid item xs={12} md={3}>
            <BillFormPaymentDate isEditForm={isEditForm} />
          </Grid>
        )}
      </Grid>

      <Grid item xs={12} md={12}>
        <BillFormCheckMovementOrBill isEditForm={isEditForm} />
      </Grid>

      <Grid item xs={12}>
        <BillFormProductsOrServices isEditForm={isEditForm} />
      </Grid>

      <Grid item xs={12}>
        <TitleSection
          title="Forma de pagamento"
          tooltip={[
            'Aqui você irá registrar como você recebeu uma venda ou como pagou uma despesa.',
            'Não se esqueça que você pode integrar o seu meio de pagamento favorito!',
          ]}
        />
      </Grid>

      <Grid container spacing={1}>
        <Grid
          item
          xs={12}
          md={hasOthersPaymentsOptions && selectedBillType?.slug === 'invoice' && isProUser ? 6 : 12}
        >
          <BillFormPaymentTypes billPaymentsTypes={billPaymentsTypes} isEditForm={isEditForm} />
        </Grid>

        {
          isBoleto &&
          !isEditForm &&
          paymentsProviders.length > 0 &&
          selectedBillType?.slug === 'invoice'
          && isProUser &&
          (
            <Grid item xs={12} md={6}>
              <BillFormPaymentProvider
                paymentsProviders={paymentsProviders.map(({ payment_provider }) => payment_provider)}
                isEditForm={isEditForm}
              />
            </Grid>
          )
        }

        {isCheque && (
          <Grid item xs={12} md={6}>
            <BillFormCheck />
          </Grid>
        )}
      </Grid>

      {!isEditForm && billOrMovement === 'bill' && (
        <>
          <Grid item xs={12} md={Number(watch('prize_type')) === 2 ? 6 : 12} mt={2}>
            <Box mr={Number(watch('prize_type')) ? 1 : 0}>
              <BillFormPrizeType isEditForm={isEditForm} />
            </Box>
          </Grid>

          {Number(watch('prize_type')) === InstallmentPrizeEnum.VariableDate && (
            <Grid item xs={12} md={6}>
              <BillFormInstallmentPrize isEditForm={isEditForm} />
            </Grid>
          )}
        </>
      )}

      {billOrMovement === 'bill' && (
        <Grid item xs={6} md={fullWidth ? 6 : 6}>
          <Box mr={isEditForm ? 0 : 1}>
            <BillFormInstallments isEditForm={isEditForm} />
          </Box>
        </Grid>
      )}

      <Grid item xs={12}>
        <TitleSection
          title="Complementos"
          tooltip={[
            'Use Tags para organizar suas vendas e despesas por categorias.',
            'Use "Número de referência para identificar sua venda ou despesa por um identificador único."',
          ]}
        />
      </Grid>

      <Grid container spacing={1}>
        <Grid item xs={12} md={6}>
          <BillFormTag billToBeEdited={billToBeEdited} />
        </Grid>

        <Grid item xs={12} md={6}>
          <BillFormNumRef />
        </Grid>
      </Grid>

      <Grid item xs={12} md={fullWidth ? 12 : 6}>
        <BillFormComments />
      </Grid>
    </>
  );
};

export default BillFormGroup;
