import { MovimentTypesSlugEnum } from 'src/pages/movements/types/MovementInterface';

export enum IStatusSlug {
  pago,
  pagoComAtraso,
  aguardando,
  atrasado,
}

export interface IStatusCashflow {
  slug: IStatusSlug;
  label: string;
  color: string;
}

export function getStatusFromCashflow(
  paid: boolean,
  up_to_date: boolean,
  paid_up_to_date: boolean,
  type: MovimentTypesSlugEnum,
): IStatusCashflow {
  if (paid && paid_up_to_date) {
    return {
      slug: IStatusSlug.pago,
      label: type === MovimentTypesSlugEnum.invoice ? 'Recebido' : 'Pago',
      color: '#00C853',
    };
  }

  if (paid && paid_up_to_date === false) {
    return {
      slug: IStatusSlug.pago,
      label: type === MovimentTypesSlugEnum.invoice ? 'Recebido' : 'Pago',
      color: '#00C853',
    };
  }

  if (paid === false && up_to_date) {
    return {
      slug: IStatusSlug.aguardando,
      label: type === MovimentTypesSlugEnum.invoice ? 'Aguardando' : 'A pagar',
      color: '#FF9800',
    };
  }

  return {
    slug: IStatusSlug.atrasado,
    label: 'Atrasado',
    color: '#F44336',
  };
}
