import type { FC, ReactNode } from 'react';

import { Outlet } from 'react-router-dom';
import { experimentalStyled } from '@material-ui/core/styles';
import Footer from './Footer';
import MainNavbar from './MainNavbar';
import MainSidebar from './MainSidebar';

interface MainLayoutProps {
  children?: ReactNode;
}

const MainLayoutRoot = experimentalStyled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  height: '100%',
  paddingTop: 64,
}));

const MainLayout: FC<MainLayoutProps> = ({ children }) => (
  <MainLayoutRoot>
    <MainNavbar />
    <MainSidebar />
    {children || <Outlet />}
    <Footer />
  </MainLayoutRoot>
);

export default MainLayout;
