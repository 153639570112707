import { EnterpriseInterface } from 'src/pages/enterprises/types/EnterpriseInterface';
import { MovimentTypesSlugEnum } from 'src/pages/movements/types/MovementInterface';
import { CustomAxiosResponse, axiosGET } from 'src/services/axiosService';
import { getLoggedUser } from 'src/services/getLoggedUser';
import { formatedStartDate, formatedEndDate } from 'src/utils/formatDateQuery';
import mock from '../mocks/cashflow-mock.json';
import { ICashflow } from '../types/cashflow.interface';
import { getStatusFromCashflow } from '../utils/getStatus';

export interface GetDashboardParams {
  invoiceOrExpense?: MovimentTypesSlugEnum;
  selectedEnterprise: EnterpriseInterface | undefined;
  start_date: Date;
  end_date: Date;
}

interface IGetCashFlowResponse {
  dash: ICashflow[];
}

export const getCashFlow = async (body: GetDashboardParams): Promise<CustomAxiosResponse<ICashflow[]>> => {
  try {
    const uuid = body.selectedEnterprise?.uuid;
    const start_date = `start_date=${formatedStartDate(body.start_date)}`;
    const end_date = `end_date=${formatedEndDate(body.end_date)}`;
    const slug = body.invoiceOrExpense ? `&slug=${body.invoiceOrExpense}` : '';

    const query = `${start_date}&${end_date}${slug}`;

    const endpoint = encodeURI(`${process.env.REACT_APP_BACKEND}/dashboard/v2/${uuid}?${query}`);

    const response = await axiosGET<IGetCashFlowResponse>(endpoint);

    const formattedResponse: ICashflow[] = response.data.dash.map((item: ICashflow) => {
      const { paid, up_to_date, paid_up_to_date, type_slug } = item;
      const status = getStatusFromCashflow(paid, up_to_date, paid_up_to_date, type_slug);

      return ({
        ...item,
        status,
        status_label: status.label,
      });
    });

    return {
      ...response,
      data: formattedResponse,
      message: 'Pronto!',
    };
  } catch (error: any) {
    throw new Error(error?.message ? error.message : 'Ocorreu um erro, tente novamente.');
  }
};
