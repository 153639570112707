import React from 'react';
import { ArrowDownwardRounded } from '@material-ui/icons';
import styled from 'styled-components';

interface InvoiceIconProps {
  size?: number;
}

const CustomArrowDownwardRounded = styled(ArrowDownwardRounded)<InvoiceIconProps>`
  color: red;
  width: ${({ size }) => size && `${size}px`};
`;

export const ExpenseIcon: React.FC<InvoiceIconProps> = ({ size }: InvoiceIconProps) => (
  <CustomArrowDownwardRounded fontSize="medium" size={size} />
);

export default ExpenseIcon;
