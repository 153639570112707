import { axiosPATCH, CustomAxiosResponse } from 'src/services/axiosService';
import { convertFormattedMoneyToCents } from 'src/utils/moneyConverter';
import { BillInterface } from './../types/BillInterface';
import { CreateBillInteface } from './createBill';

export interface EditBillInteface extends CreateBillInteface {}

interface EditBillProps {
  bill: EditBillInteface;
  uuid: string;
}

export const editBill = async ({ bill, uuid }: EditBillProps): Promise<CustomAxiosResponse<BillInterface[]>> => {
  try {
    const endpoint = `${process.env.REACT_APP_BACKEND}/bills/${uuid}`;

    const _billToBeCreated: EditBillInteface = {
      ...bill,
      installmentValue: String(convertFormattedMoneyToCents(bill.installmentValue)),
      bill_items: bill.bill_items.map((item) => ({
        ...item,
        unit_price: convertFormattedMoneyToCents(item.unit_price),
      })),
      installments_value: bill.installments_value.map((item) => ({
        value: convertFormattedMoneyToCents(item.value)
      })),
    };

    const response = await axiosPATCH<BillInterface[]>(endpoint, _billToBeCreated);

    return {
      ...response,
      data: response.data,
      message: 'Movimentação editada com sucesso!',
    };
  } catch (error: any) {
    throw new Error(error?.message ? error.message : 'Ocorreu um erro, tente novamente.');
  }
};
