import { Helmet } from 'react-helmet-async';
import { Link as RouterLink } from 'react-router-dom';
import React, { forwardRef, useEffect, useCallback } from 'react';
import {
  Box,
  Breadcrumbs,
  Container,
  Grid,
  IconButton,
  Link,
  Tooltip,
  tooltipClasses,
  Typography,
} from '@material-ui/core';
import { HelpOutline } from '@material-ui/icons';
import useSettings from 'src/hooks/useSettings';
import { UseQueryResult } from 'react-query';
import useLayout from 'src/hooks/useLayout';
import { isDesktop } from 'react-device-detect';
import { capitalize } from 'lodash';
import styled from 'styled-components';
import { capitalizeFirstLetter } from 'src/utils/stringHelper';
import LoadingScreen from '../LoadingScreen';
import ChevronRightIcon from '../../icons/ChevronRight';
import EmptyPlaceholder from './EmptyPlaceholder';
import gtm from '../../lib/gtm';
import PageTabs, { PageTabsInterface } from './PageTabs';

// ----------------------------------------------------------------------

interface TooltipProps {
  tooltipSize: number;
}

const CustomWidthTooltip = styled(({ className, tooltipSize, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))<TooltipProps>(({ tooltipSize }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: tooltipSize,
  },
}));

interface BreadcrumbsInterface {
  to: string | undefined;
  label: string | undefined;
}

interface PageInterface {
  title: string;
  breadcrumbs?: BreadcrumbsInterface[];
  query?: UseQueryResult<any, any> | undefined;
  button?: JSX.Element;
  tabs?: PageTabsInterface[];
  tooltipTitle?: string | JSX.Element;
  tooltipSize?: number;
}

export const Page: React.FC<PageInterface> = forwardRef(
  ({ children, title, breadcrumbs, query, button, tabs, tooltipTitle, tooltipSize }, ref) => {
    const { setPageTitle, pageTitle } = useLayout();
    const { settings } = useSettings();
    // const isFetching = useIsFetching();
    const hasTabs = !!(tabs && tabs.length > 0);
    const formattedTitle = capitalize(title);

    const sendPageViewEvent = useCallback(() => {
      gtm.push({ event: 'page_view' });
    }, []);

    useEffect(() => {
      if (pageTitle !== formattedTitle) {
        setPageTitle(formattedTitle);
      }
    }, [pageTitle, setPageTitle, formattedTitle]);

    useEffect(() => {
      sendPageViewEvent();
    }, [sendPageViewEvent]);

    return (
      // @ts-ignore
      <div ref={ref}>
        {/* @ts-ignore */}
        <Helmet>
          <title>{formattedTitle} | Wivvi</title>
        </Helmet>
        <>
          {hasTabs && (
            <Box mt="48px">
              <PageTabs tabs={tabs} />
            </Box>
          )}
          <Box
            sx={{
              backgroundColor: 'background.default',
              minHeight: '100%',
              // eslint-disable-next-line no-nested-ternary
              pb: isDesktop ? 8 : 12,
              pt: hasTabs ? 4 : 8,
            }}
          >
            <Container maxWidth={settings.compact ? 'xl' : false}>
              <Grid container justifyContent="space-between" spacing={3}>
                {isDesktop && (
                  <Grid item>
                    <Box display="flex" alignItems="center">
                      <Typography color="textPrimary" variant="h5">
                        {formattedTitle}
                      </Typography>
                      {tooltipTitle && (
                        <Box>
                          <CustomWidthTooltip title={tooltipTitle} tooltipSize={tooltipSize}>
                            <IconButton color="primary">
                              <HelpOutline fontSize="small" />
                            </IconButton>
                          </CustomWidthTooltip>
                        </Box>
                      )}
                    </Box>
                    {breadcrumbs && (
                      <Breadcrumbs
                        aria-label="breadcrumb"
                        separator={<ChevronRightIcon fontSize="small" />}
                        sx={{ mt: 1 }}
                      >
                        {breadcrumbs.map(({ label }, index) => {
                          const isLast = index === breadcrumbs.length - 1;

                          const toLink = breadcrumbs
                            .slice(0, index + 1)
                            .reduce(
                              (acc, val) =>
                                /* eslint-disable-next-line no-param-reassign */
                                (acc += `${val.to ? val.to.replaceAll('/', '') : ''}/`),
                              '/',
                            )
                            .slice(0, -1);

                          return !isLast ? (
                            <Link
                              key={`link-${toLink}`}
                              color="textPrimary"
                              component={RouterLink}
                              to={toLink}
                              variant="subtitle2"
                            >
                              {capitalizeFirstLetter(label || '')}
                            </Link>
                          ) : (
                            <Typography color="textSecondary" variant="subtitle2">
                              {capitalizeFirstLetter(label || '')}
                            </Typography>
                          );
                        })}
                      </Breadcrumbs>
                    )}
                  </Grid>
                )}
                {button && (
                  <Grid item>
                    <Box sx={{ m: -1 }}>{button}</Box>
                  </Grid>
                )}
              </Grid>
              <Box sx={{ mt: 3 }}>
                {query?.isError && (
                  <EmptyPlaceholder title="Ocorreu um problema ao carregar" subtitle="Tente novamente" />
                )}
                {query?.isFetching ? <LoadingScreen /> : <>{(query?.isSuccess || query === undefined) && children}</>}
              </Box>
            </Container>
          </Box>
        </>
      </div>
    );
  },
);
