import { updateEnterpriseInfo } from 'src/pages/dashboard/contexts/EnterpriseContext/actions/updateEnterpriseInfo';
import { handleManageEnterpriseAction } from './actions/handleManageEnterpriseAction';
import { EnterpriseTypesEnum, EnterpriseContextInterface, EnterpriseAction } from './EnterpriseInterface';
import { updateEnterpriseAvailableAction } from './actions/updateEnterpriseAvailableAction';
import { updateStateAction } from './actions/updateStateAction';
import { selectEnterpriseAction } from './actions/selectEnterpriseAction';
import { unselectEnterpriseAction } from './actions/unselectEnterpriseAction';
import { addEnterpriseToStateAction } from './actions/addEnterpriseToStateAction';
import { selectEnterpriseToDeleteAction } from './actions/selectEnterpriseToDeleteAction';
import { changeEnterpriseAvatarAction } from './actions/changeEnterpriseAvatarAction';
import { removeEnterpriseInfo } from './actions/removeEnterprisesInfo';

function updateStateReducer(state: EnterpriseContextInterface, action: EnterpriseAction): EnterpriseContextInterface {
  switch (action.type) {
    case EnterpriseTypesEnum.UPDATE_STATE:
      return updateStateAction(state, action);

    case EnterpriseTypesEnum.SELECT_ENTERPRISE:
      return selectEnterpriseAction(state, action);

    case EnterpriseTypesEnum.UNSELECT_ENTERPRISE:
      return unselectEnterpriseAction(state, action);

    case EnterpriseTypesEnum.ADD_ENTERPRISE_TO_STATE:
      return addEnterpriseToStateAction(state, action);

    case EnterpriseTypesEnum.HANDLE_MANAGE_ENTERPRISE:
      return handleManageEnterpriseAction(state, action);

    case EnterpriseTypesEnum.UPDATE_ENTERPRISE_AVAILABLE:
      return updateEnterpriseAvailableAction(state, action);

    case EnterpriseTypesEnum.SELECT_ENTERPRISE_TO_DELETE:
      return selectEnterpriseToDeleteAction(state, action);

    case EnterpriseTypesEnum.CHANGE_ENTERPRISE_AVATAR:
      return changeEnterpriseAvatarAction(state, action);

    case EnterpriseTypesEnum.UPDATE_ENTERPRISE_INFO:
      return updateEnterpriseInfo(state, action);

    case EnterpriseTypesEnum.REMOVE_ENTERPRISE_INFO:
      return removeEnterpriseInfo(state, action);
    default:
      return state;
  }
}

export default updateStateReducer;
