import { FormControl, FormHelperText, ButtonGroup, Button, Box } from '@material-ui/core';
import { Control, RegisterOptions, useController, UseControllerProps } from 'react-hook-form';
import styled, { css } from 'styled-components';
import { Theme } from '@material-ui/core/styles';
import { getHelperMessage } from './TextfieldController';

interface KeyLabel {
  key: string | number;
  label: string;
}

interface ButtonControllerInterface extends Omit<UseControllerProps<any, any>, 'control' | 'rules'> {
  control: Control<any>;
  rules: Omit<RegisterOptions<any, any>, 'valueAsNumber' | 'valueAsDate' | 'setValueAs'>;
  values: KeyLabel[];
  label?: string;
  disabled?: boolean;
  fullWidth?: boolean;
  variant?: 'filled' | 'outlined';
  helperMessage?: string;
  size?: 'small' | 'medium';
}

interface CustomButtonInterface {
  error: boolean;
  theme: Theme;
}

const CustomButton = styled(Button)<CustomButtonInterface>`
  ${({ error }) =>
    error &&
    css`
      border-color: #f44336;
    `};
  ${({ error }) =>
    error &&
    css`
      color: #f44336;
    `};
`;

export const ButtonController: React.FC<ButtonControllerInterface> = ({
  control,
  name,
  rules,
  values,
  defaultValue,
  helperMessage,
  fullWidth = true,
  disabled = false,
  variant = 'outlined',
}) => {
  const {
    field: { value, onChange },
    fieldState: { error },
    formState: { isSubmitting },
  } = useController({
    name,
    control,
    rules,
    defaultValue,
  });

  const handleChange = (slug: string | number) => onChange(slug);

  return (
    <Box mb={1.5}>
      <FormControl error={!!error} variant={variant} fullWidth={fullWidth}>
        {/* <InputLabel error={!!error}>{label}</InputLabel> */}
        <ButtonGroup
          aria-label="outlined primary button group"
          disabled={disabled || isSubmitting}
          color="primary"
          fullWidth
        >
          {values.map((keyValue) => (
            <CustomButton
              error={!!error}
              onClick={() => handleChange(keyValue.key)}
              variant={value === keyValue.key ? 'contained' : 'outlined'}
            >
              {keyValue.label}
            </CustomButton>
          ))}
        </ButtonGroup>
        <FormHelperText error={!!error}>{getHelperMessage(error || helperMessage)}</FormHelperText>
      </FormControl>
    </Box>
  );
};
