import React, { useState } from 'react';
import {
  BottomNavigation as MaterialBottomNavigation,
  AppBar,
  BottomNavigationAction,
  Fab,
  Grid,
  Tooltip,
} from '@material-ui/core';
import {
  AddCircleOutlineRounded,
  CloseRounded,
  GroupRounded,
  InsertInvitation,
  LocalAtmRounded,
  LocalOffer,
  Menu,
  PeopleRounded,
  Receipt,
  ShowChartRounded,
} from '@material-ui/icons';
import useMovements from 'src/pages/movements/hooks/useMovements';
import { useNavigate } from 'react-router';
import useLayout from 'src/hooks/useLayout';
// eslint-disable-next-line import/no-extraneous-dependencies
import { styled } from '@material-ui/styles';
import { MovimentTypesSlugEnum } from 'src/pages/movements/types/MovementInterface';

interface BottomNavigationInterface {}

const StyledFab = styled(Fab)({
  position: 'absolute',
  zIndex: 1,
  top: -30,
  left: 0,
  right: 0,
  margin: '0 auto',
});

const StyledMovementIcon = styled(Fab)({
  position: 'absolute',
  zIndex: 1,
  top: -65,
  left: -80,
  right: '80px',
  margin: '0 auto',
});

const StyledBillIcon = styled(Fab)({
  position: 'absolute',
  zIndex: 1,
  top: -100,
  left: '-60px',
  right: 0,
  margin: '0 auto',
});

const StyledBusinessIcon = styled(Fab)({
  position: 'absolute',
  zIndex: 1,
  top: -65,
  left: 165,
  right: 0,
  margin: '0 auto',
});

const StyledTagIcon = styled(Fab)({
  position: 'absolute',
  zIndex: 1,
  top: -100,
  left: '70px',
  right: 0,
  margin: '0 auto',
});

export const BottomNavigation: React.FC<BottomNavigationInterface> = () => {
  const navigate = useNavigate();
  const { openMenu } = useLayout();
  const { openDrawer } = useMovements();
  const [value, setValue] = React.useState(0);
  const [isOpenDrawerActions, setIsOpenDrawerActions] = useState(false);

  return (
    <AppBar
      position="fixed"
      color="transparent"
      style={{
        top: 'auto',
        bottom: -1,
        border: '1px solid transparent',
        borderTopLeftRadius: '12px',
        borderTopRightRadius: '12px',
      }}
    >
      <MaterialBottomNavigation
        value={value}
        onChange={(event, newValue) => {
          setValue(newValue);
        }}
        showLabels
      >
        <BottomNavigationAction label="Caixa" onClick={() => navigate('/')} icon={<ShowChartRounded />} />
        <BottomNavigationAction
          label="Contas"
          onClick={() => navigate('/contas/receber')}
          icon={<LocalAtmRounded />}
        />
        <BottomNavigationAction style={{ visibility: 'hidden' }} label="Novo" icon={<AddCircleOutlineRounded />} />

        {!isOpenDrawerActions ? (
          <StyledFab onClick={() => setIsOpenDrawerActions(true)} color="primary" aria-label="add">
            <AddCircleOutlineRounded />
          </StyledFab>
        ) : (
          <StyledFab onClick={() => setIsOpenDrawerActions(false)} color="primary" aria-label="close">
            <CloseRounded />
          </StyledFab>
        )}

        {isOpenDrawerActions && (
          <Grid>
            <Tooltip title="Entrada">
              <StyledMovementIcon
                size="medium"
                color="primary"
                onClick={() => {
                  setIsOpenDrawerActions(false);
                  openDrawer(MovimentTypesSlugEnum.invoice);
                }}
              >
                <Receipt />
              </StyledMovementIcon>
            </Tooltip>

            <Tooltip title="Saída">
              <StyledBillIcon
                size="medium"
                color="primary"
                onClick={() => {
                  setIsOpenDrawerActions(false);
                  openDrawer(MovimentTypesSlugEnum.expense);
                }}
              >
                <InsertInvitation />
              </StyledBillIcon>
            </Tooltip>

            <Tooltip title="Contato">
              <StyledBusinessIcon
                size="medium"
                color="primary"
                onClick={() => {
                  setIsOpenDrawerActions(false);
                  navigate('/clientes/novo');
                }}
              >
                <PeopleRounded />
              </StyledBusinessIcon>
            </Tooltip>

            <Tooltip title="Tag">
              <StyledTagIcon
                size="medium"
                color="primary"
                onClick={() => {
                  setIsOpenDrawerActions(false);
                  navigate('centro-de-custos/novo');
                }}
              >
                <LocalOffer />
              </StyledTagIcon>
            </Tooltip>
          </Grid>
        )}
        <BottomNavigationAction label="Cadastros" onClick={() => navigate('/clientes')} icon={<GroupRounded />} />
        <BottomNavigationAction label="Menu" onClick={openMenu} icon={<Menu />} />
      </MaterialBottomNavigation>
    </AppBar>
  );
};

export default BottomNavigation;
