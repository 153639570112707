import { EnterpriseTypesEnum, EnterpriseContextInterface } from './../EnterpriseInterface';
import { EnterpriseInterface } from '../../../types/EnterpriseInterface';

export type SELECT_ENTERPRISE = {
  type: EnterpriseTypesEnum.SELECT_ENTERPRISE;
  enterprise: EnterpriseInterface;
  redirect?: () => void;
};

export function selectEnterpriseAction(
  state: EnterpriseContextInterface,
  action: SELECT_ENTERPRISE,
): EnterpriseContextInterface {
  if (action.redirect) action.redirect();

  return {
    ...state,
    selectedEnterprise: action.enterprise,
  };
}
