import React, { ChangeEvent, useContext, useState } from 'react';
import { useLocation, useParams } from 'react-router';
import { FormProvider, useForm } from 'react-hook-form';
import { Box, Divider, Grid, Tab, Tabs, Theme, useMediaQuery } from '@material-ui/core';
import { Save } from '@material-ui/icons';
import MutatingButton from 'src/components/forms/MutatingButton';
import { useMutationToast } from 'src/hooks/useMutationToast';
import { useQuery } from 'react-query';
import { useEnterprise } from 'src/pages/enterprises/hooks/useEnterprise';
import useMovements from 'src/pages/movements/hooks/useMovements';
import { getCashFlow } from 'src/pages/cashFlow/services/getCashFlow';
import { convertInvoiceOrExpenseEnumToString } from 'src/pages/movements/utils/getInvoiceOrExpenseFromParams';
import { DashboardContext } from 'src/pages/dashboard/contexts/DashboardContext';
import { MovimentTypesSlugEnum } from 'src/pages/movements/types/MovementInterface';
import { isDesktop } from 'react-device-detect';
import { BillInterface } from '../types/BillInterface';
import { editBill } from '../services/editBill';
import { createBill, CreateBillInteface } from '../services/createBill';
import BillFormOverview from './BillFormOverview';
import BillFormGroup from './BillFormGroup';
import BillFiscalForm from './BillFiscalForm';
import BillMobileForm from './BillMobileForm';

export interface BillFormInterface {
  billToBeEdited: BillInterface | undefined;
  fullWidth?: boolean;
}

enum BillTabsEnum {
  bill,
  fiscal,
}

const billTabs: { label: string; value: BillTabsEnum }[] = [
  { label: 'Conta', value: BillTabsEnum.bill },
  { label: 'Nota Fiscal', value: BillTabsEnum.fiscal },
];

const BillForm: React.FC<BillFormInterface> = ({ billToBeEdited, fullWidth }) => {
  const upXl = useMediaQuery((theme: Theme) => theme?.breakpoints.up('xl'));
  const { startDate, endDate } = useContext(DashboardContext);
  const { pathname } = useLocation();
  const { invoiceOrExpense } = useParams();
  const { selectedEnterprise } = useEnterprise();
  const { closeDrawer, formType } = useMovements();
  const [currentBillTab, setCurrentBillTab] = useState(0);
  const isEditForm = !!billToBeEdited;
  const convertedInvoiceOrExpense = convertInvoiceOrExpenseEnumToString(invoiceOrExpense);
  const cashFlowQuery = useQuery(`getAllBills-${invoiceOrExpense}`, () =>
    getCashFlow({
      selectedEnterprise,
      invoiceOrExpense: convertedInvoiceOrExpense,
      start_date: startDate,
      end_date: endDate,
    }),
  );

  const mutateBillEdit = useMutationToast(editBill, { onSuccess: closeDrawer });
  const mutateBillCreate = useMutationToast(
    createBill,
    {
      onSuccess: async () => {
        if (pathname.includes('/contas')) {
          await cashFlowQuery.refetch();
        }
        return closeDrawer();
      },
    },
  );

  const methods = useForm<CreateBillInteface>({
    defaultValues: {
      num_ref: billToBeEdited?.num_ref || '',
      due_date: billToBeEdited?.due_date || new Date(),
      payment_date: billToBeEdited?.payment_date || new Date(),
      comments: billToBeEdited?.comments || '',
      installmentValue: billToBeEdited?.installmentValue ? String(billToBeEdited?.installmentValue) : '0',
      grand_total: billToBeEdited?.grand_total || 0,
      bill_types_uuid: billToBeEdited?.bill_types?.uuid || '',
      payment_types_uuid: billToBeEdited?.payment_types?.uuid || '',
      business_contact_uuid: billToBeEdited?.business_contacts ? billToBeEdited?.business_contacts?.uuid : '',
      bill_items: billToBeEdited?.bill_items
        ? billToBeEdited?.bill_items
        : [
            {
              name: '',
              description: '',
              quantity: 1,
              unit_price: 0,
              total: 0,
              item_tag_uuid: '',
              serviceItem_uuid: '',
              uuid: '',
            },
          ],
      installments: billToBeEdited?.installments || 1,
      enterprise_uuid: billToBeEdited?.enterprise.uuid || `${selectedEnterprise?.uuid}`,
      prize_type: '',
      installmentPrize: '',
      totalValue: '0',
      tag_uuid: billToBeEdited?.tag?.uuid || '',
      payment_provider_uuid: billToBeEdited?.paymentProvider?.uuid || '',
      checkInfo: billToBeEdited?.checkInfo || '',
      bill_or_movement: 'movement',
      installments_value: [{ value: 0 }],
      emitNfse: false,
      fiscal: {
        service: '',
        description: '',
        descricao_nota: '',
        client_should_retain: 'não',
        aliquota_iss: '',
        cofins: {
          aliquota: '00.00',
          valor: 0,
          reter: false,
          tipo: 'COFINS',
        },
        csll: {
          aliquota: '00.00',
          valor: 0,
          reter: false,
          tipo: 'CSLL',
        },
        inss: {
          aliquota: '00.00',
          valor: 0,
          reter: false,
          tipo: 'INSS',
        },
        ir: {
          aliquota: '00.00',
          valor: 0,
          reter: false,
          tipo: 'IR',
        },
        pis: {
          aliquota: '00.00',
          valor: 0,
          reter: false,
          tipo: 'PIS',
        },
      },
    },
    mode: 'onChange',
    reValidateMode: 'onChange',
  });
  const { handleSubmit, watch, setError, formState } = methods;

  const billOrMovement = watch('bill_or_movement');
  const installmentValue = watch('totalValue');
  const shouldEmitFiscal = watch('emitNfse');
  const fiscalService = watch('fiscal.service');
  const fiscalServiceDescription = watch('fiscal.description');
  const isDisabledSaveButton = Number(installmentValue) <= 0 || !formState.isValid;

  // //////////////////
  // Payments options
  // //////////////////

  const alreadyPaid = billOrMovement === 'movement';

  const onSubmit = (data: CreateBillInteface) => {
    const billFormContainer = document.getElementById('bill-form-container');

    if (shouldEmitFiscal && (fiscalService === '' || fiscalServiceDescription === '')) {
      billFormContainer!.scrollTop = 0;
      setError('fiscal.service', { type: 'required' });
      setCurrentBillTab(BillTabsEnum.fiscal);
    } else {
      submitBill(data);
    }
  };

  const submitBill = (data: CreateBillInteface) => {
    if (isEditForm) {
      mutateBillEdit.mutate({ bill: data, uuid: billToBeEdited.uuid });
    } else {
      mutateBillCreate.mutate({ billToBeCreated: data, alreadyPaid });
    }
  };

  const handleTabsChange = (event: ChangeEvent<{}>, value: BillTabsEnum): void => {
    const billFormContainer = document.getElementById('bill-form-container');
    setCurrentBillTab(value);
    billFormContainer!.scrollTop = 0;
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        {isDesktop ? (
          <Box display="flex" flexDirection="row">
            <BillFormOverview />

            <Box ml={2.5} />
            <Divider orientation="vertical" flexItem />
            <Box mr={2.5} />

            <Box sx={{ width: '100%' }}>
              {formType === MovimentTypesSlugEnum.invoice && (
                <Tabs
                  indicatorColor="primary"
                  onChange={handleTabsChange}
                  scrollButtons="auto"
                  textColor="primary"
                  value={currentBillTab}
                  variant="fullWidth"
                >
                  {billTabs.map((tab) => (
                    <Tab
                      key={tab.value}
                      label={tab?.label}
                      value={tab.value}
                      disabled={tab.value === BillTabsEnum.fiscal && !shouldEmitFiscal}
                    />
                  ))}
                </Tabs>
              )}
              <Grid
                id="bill-form-container"
                // container
                sx={{
                  pt: 4,
                  height: upXl ? '80vh' : '70vh',
                  overflow: 'auto',
                }}
              >
                {currentBillTab === BillTabsEnum.bill && (
                  <BillFormGroup fullWidth={fullWidth} billToBeEdited={billToBeEdited} />
                )}

                {currentBillTab === BillTabsEnum.fiscal && (
                  <BillFiscalForm fullWidth={fullWidth} billToBeEdited={billToBeEdited} />
                )}

              </Grid>
              <Grid item xs={12} md={2}>
                <Box display="flex" mt={2} flexDirection="row-reverse">
                  <MutatingButton
                    startIcon={<Save fontSize="small" />}
                    type="submit"
                    color="primary"
                    fullWidth
                    variant="contained"
                    loadingText="Salvando..."
                    disabled={isDisabledSaveButton}
                  >
                    Salvar
                  </MutatingButton>
                </Box>
              </Grid>
            </Box>
          </Box>
        ) : (
          <BillMobileForm
            fullWidth={fullWidth}
            billToBeEdited={billToBeEdited}
            isDisabledSaveButton={isDisabledSaveButton}
          />
        )}
      </form>
    </FormProvider>
  );
};

export default BillForm;
