import React from 'react';
import Divider from '@material-ui/core/Divider';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { useNavigate } from 'react-router-dom';
import { ListItemIcon, ListItemText } from '@material-ui/core';
import { Add } from '@material-ui/icons';
import useLayout from 'src/hooks/useLayout';
import { useEnterprise } from '../hooks/useEnterprise';

interface SelectAutocompleteEnterpriseInterface {}

export const SelectAutocompleteEnterprise: React.FC<SelectAutocompleteEnterpriseInterface> = () => {
  const navigate = useNavigate();
  const { closeMenu } = useLayout();
  const { availableEnterprises, selectedEnterprise, selectEnterprise } = useEnterprise();

  const handleChange = (value: string) => {
    if (value === 'new') {
      navigate('/empresas/novo');
    }

    const selected = availableEnterprises.find(({ uuid }) => uuid === value);
    if (selected) selectEnterprise(selected);

    closeMenu();
  };

  return (
    <FormControl fullWidth variant="outlined">
      <Select fullWidth value={selectedEnterprise?.uuid} onChange={(event) => handleChange(event.target.value)}>
        <MenuItem value="new">
          <ListItemIcon>
            <Add />
          </ListItemIcon>
          <ListItemText primary="Criar nova empresa" />
        </MenuItem>
        <Divider />
        {availableEnterprises.map((enterprise) => (
          <MenuItem value={enterprise.uuid}>{enterprise.fantasy_name}</MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default SelectAutocompleteEnterprise;
