import React from 'react';
import { useFormContext } from 'react-hook-form';
import { CheckboxController } from 'src/components/forms/CheckboxController';
import { SelectController } from 'src/components/forms/SelectController';
import { PaymentTypeInterface } from 'src/pages/movements/types/paymentTypeInterface';

interface BillFormPaymentTypesProps {
  isEditForm: boolean;
  billPaymentsTypes: PaymentTypeInterface[];
}

export const BillFormPaymentTypes: React.FC<BillFormPaymentTypesProps> = ({ isEditForm, billPaymentsTypes }) => {
  const { control, watch } = useFormContext();

  const shouldEmitFiscal = watch('emitNfse');

  return (
    <>
      <SelectController
        control={control}
        rules={{ required: true }}
        disabled={isEditForm}
        name="payment_types_uuid"
        size="small"
        label="Tipo de movimentação"
        values={billPaymentsTypes.map(({ uuid, label }) => ({
          key: uuid,
          label,
        }))}
      />
      {!isEditForm && (
        <CheckboxController
          control={control}
          name="emitNfse"
          checked={shouldEmitFiscal}
          rules={{ required: false }}
        />
      )}
    </>
  );
};

export default BillFormPaymentTypes;
