import { Suspense, lazy, LazyExoticComponent } from 'react';
// @ts-ignore
import type { PartialRouteObject } from 'react-router';

import DashboardLayout from './components/dashboard/DashboardLayout';
import GuestGuard from './pages/authentication/guards/GuestGuard';
import LoadingScreen from './components/LoadingScreen';
import MainLayout from './components/MainLayout';
import AuthorizationRequired from './pages/AuthorizationRequired';
import EnterpriseGuard from './pages/enterprises/guards/EnterpriseGuard';

import UsersRootPage from './pages/users/UsersRootPage';
import EnterpriseRootPage from './pages/enterprises/EnterpriseRootPage';
import ProfileRootPage from './pages/dashboard/profile/ProfileRootPage';
import NotFound from './pages/NotFound';
import ServerError from './pages/ServerError';
import Redirect from './utils/Redirect';
import BusinessContactsRootPage from './pages/businessContacts/BusinessContactsRootPage';
import MovementsRootPage from './pages/movements/MovementsRootPage';
import EnterpriseProvider from './pages/enterprises/contexts/EnterpriseContext/EnterpriseContext';
import MovementProvider from './pages/movements/contexts/MovementContext/MovementContext';
import { MovementDrawer } from './pages/movements/components/MovementDrawer';
import MenuRootPage from './pages/menu/MenuRootPage';
import DashboardProvider from './pages/dashboard/contexts/DashboardContext';
import BillsRootPage from './pages/bills/BillsRootPage';
import TagsRootPage from './pages/tags/TagsRootPage';
import PaymentOptionsRootPage from './pages/paymentOptions/PaymentOptionsRootPage';
import ServiceITemRootPage from './pages/service-item/ServiceItemRootPage';
import ReportsRootPage from './pages/reports/ReportsRootPage';
import AuthGuard from './pages/authentication/guards/AuthGuard';
import OpenBankingRootPage from './pages/openBanking/OpenBankingRootPage';
import OpenBanking from './pages/openBanking/pages/OpenBanking';
import BillsProvider from './pages/bills/context/BillsContext';
import NotasFiscaisRootPage from './pages/notas-fiscais/NotasFiscaisRootPage';

const Loadable = (Component: LazyExoticComponent<React.FC<{}>>) => (props: any) =>
(
  <Suspense fallback={<LoadingScreen />}>
    <Component {...props} />
  </Suspense>
);

// Authentication pages

const Login = Loadable(lazy(() => import('./pages/authentication/Login')));
const PasswordRecovery = Loadable(lazy(() => import('./pages/authentication/PasswordRecovery')));
const PasswordReset = Loadable(lazy(() => import('./pages/authentication/PasswordReset')));
const Register = Loadable(lazy(() => import('./pages/authentication/Register')));
const VerifyCode = Loadable(lazy(() => import('./pages/authentication/VerifyCode')));

// Dashboard pages
const UserDetails = Loadable(lazy(() => import('./pages/users/UserDetails')));
const UserEdit = Loadable(lazy(() => import('./pages/users/UserEdit')));
const UserCreate = Loadable(lazy(() => import('./pages/users/UserCreate')));
const UserList = Loadable(lazy(() => import('./pages/users/UserList')));

// Enterprise pages
const EnterpriseDetails = Loadable(lazy(() => import('./pages/enterprises/EnterpriseDetails')));
const EnterpriseEdit = Loadable(lazy(() => import('./pages/enterprises/EnterpriseEdit')));
const EnterpriseCreate = Loadable(lazy(() => import('./pages/enterprises/EnterpriseCreate')));
const EnterpriseList = Loadable(lazy(() => import('./pages/enterprises/EnterpriseList')));
const CreateNewEnterprisePage = Loadable(lazy(() => import('./pages/enterprises/CreateNewEnterprisePage')));
const SelectEnterprise = Loadable(lazy(() => import('./pages/enterprises/SelectEnterprise')));

// Business contact pages
const BusinessContactDetails = Loadable(lazy(() => import('./pages/businessContacts/BusinessContactDetails')));
const BusinessContactEdit = Loadable(lazy(() => import('./pages/businessContacts/BusinessContactEdit')));
const BusinessContactCreate = Loadable(lazy(() => import('./pages/businessContacts/BusinessContactCreate')));
const BusinessContactList = Loadable(lazy(() => import('./pages/businessContacts/BusinessContactList')));

// Tags pages
const TagDetails = Loadable(lazy(() => import('./pages/tags/TagDetails')));
const TagEdit = Loadable(lazy(() => import('./pages/tags/TagEdit')));
const TagCreate = Loadable(lazy(() => import('./pages/tags/TagCreate')));
const TagList = Loadable(lazy(() => import('./pages/tags/TagList')));
const TagItemsList = Loadable(lazy(() => import('./pages/tags/TagItemsList')));
const TagItemsCreate = Loadable(lazy(() => import('./pages/tags/TagItemsCreate')));
const TagItemsDetails = Loadable(lazy(() => import('./pages/tags/TagItemsDetails')));
const TagItemsEdit = Loadable(lazy(() => import('./pages/tags/TagItemsEdit')));

const GetReport = Loadable(lazy(() => import('./pages/reports/GetReport')));

// Movements pages
const MovementDetails = Loadable(lazy(() => import('./pages/movements/MovementDetails')));
const MovementEdit = Loadable(lazy(() => import('./pages/movements/MovementEdit')));
const MovementCreate = Loadable(lazy(() => import('./pages/movements/MovementCreate')));
const MovementList = Loadable(lazy(() => import('./pages/movements/MovementList')));

// Bills pages
const BillsDetails = Loadable(lazy(() => import('./pages/bills/BillDetails')));
const BillsEdit = Loadable(lazy(() => import('./pages/bills/BillEdit')));
const BillsList = Loadable(lazy(() => import('./pages/bills/BillList')));

// Notas Fiscais
const NotasFiscaisList = Loadable(lazy(() => import('./pages/notas-fiscais/NotasFiscaisList')));

// Dashboard
const Overview = Loadable(lazy(() => import('./pages/dashboard/Overview')));
const Resume = Loadable(lazy(() => import('./pages/resume/Resume')));

const PaymentDetails = Loadable(lazy(() => import('./pages/paymentOptions/PaymentOptionDetails')));
const PaymentEdit = Loadable(lazy(() => import('./pages/paymentOptions/PaymentOptionEdit')));
const PaymentList = Loadable(lazy(() => import('./pages/paymentOptions/PaymentOptionList')));
const PaymentCreate = Loadable(lazy(() => import('./pages/paymentOptions/PaymentOptionCreate')));

const ServiceItemsList = Loadable(lazy(() => import('./pages/service-item/ServiceItemList')));
const ServiceItemsDetails = Loadable(lazy(() => import('./pages/service-item/ServiceItemDetails')));
const ServiceItemsCreate = Loadable(lazy(() => import('./pages/service-item/ServiceItemCreate')));
const ServiceItemEdit = Loadable(lazy(() => import('./pages/service-item/ServiceItemEdit')));

const IntegrationConfig = Loadable(lazy(() => import('./pages/paymentOptions/IntegrationConfig')));

// Profile
const ProfilePage = Loadable(lazy(() => import('./pages/dashboard/profile/ProfilePage')));

const routes: PartialRouteObject[] = [
  {
    path: 'authentication',
    children: [
      {
        path: 'login',
        element: (
          <GuestGuard>
            <Login />
          </GuestGuard>
        ),
      },
      {
        path: 'register',
        element: (
          <GuestGuard>
            <Register />
          </GuestGuard>
        ),
      },
      {
        path: 'login-unguarded',
        element: <Login />,
      },
      {
        path: 'password-recovery',
        element: <PasswordRecovery />,
      },
      {
        path: 'password-reset',
        element: <PasswordReset />,
      },
      {
        path: 'register-unguarded',
        element: <Register />,
      },
      {
        path: 'verify-code',
        element: <VerifyCode />,
      },
    ],
  },
  {
    path: '',
    element: (
      <AuthGuard>
        <EnterpriseProvider>
          <MovementProvider>
            <EnterpriseGuard>
              <DashboardProvider>
                <BillsProvider>
                  <DashboardLayout />
                  <MovementDrawer />
                </BillsProvider>
              </DashboardProvider>
            </EnterpriseGuard>
          </MovementProvider>
        </EnterpriseProvider>
      </AuthGuard>
    ),
    children: [
      {
        path: '',
        element: <Resume />,
      },
      {
        path: 'fluxo-de-caixa',
        element: <Overview />,
      },
      {
        path: 'menu',
        element: <MenuRootPage />,
      },
      {
        path: 'perfil',
        element: <ProfileRootPage />,
        children: [
          {
            path: '',
            element: <ProfilePage />,
          },
        ],
      },
      {
        path: 'usuarios',
        element: <UsersRootPage />,
        children: [
          {
            path: '',
            element: <UserList />,
          },
          {
            path: 'novo',
            element: <UserCreate />,
          },
          {
            path: ':userId',
            element: <UserDetails />,
          },
          {
            path: ':userId/editar',
            element: <UserEdit />,
          },
        ],
      },
      {
        path: ':customerOrProvider',
        element: <BusinessContactsRootPage />,
        children: [
          {
            path: '',
            element: <BusinessContactList />,
          },
          {
            path: 'novo',
            element: <BusinessContactCreate />,
          },
          {
            path: ':businessContactId',
            element: <BusinessContactDetails />,
          },
          {
            path: ':businessContactId/editar',
            element: <BusinessContactEdit />,
          },
        ],
      },
      {
        path: '/movimentacoes/:invoiceOrExpense',
        element: <MovementsRootPage />,
        children: [
          {
            path: '',
            element: <MovementList />,
          },
          {
            path: 'novo',
            element: <MovementCreate />,
          },
          {
            path: ':movementId',
            element: <MovementDetails />,
          },
          {
            path: ':movementId/editar',
            element: <MovementEdit />,
          },
        ],
      },
      {
        path: '/contas/:invoiceOrExpense',
        element: <BillsRootPage />,
        children: [
          {
            path: '',
            element: <BillsList />,
          },
          {
            path: ':billId',
            element: <BillsDetails />,
          },
          {
            path: ':billId/editar',
            element: <BillsEdit />,
          },
        ],
      },
      {
        path: '/notas-fiscais',
        element: <NotasFiscaisRootPage />,
        children: [
          {
            path: '',
            element: <NotasFiscaisList />,
          },
        ],
      },
      {
        path: 'centro-de-custos',
        element: <TagsRootPage />,
        children: [
          {
            path: '',
            element: <TagList />,
          },
          {
            path: 'itens',
            element: <TagItemsList />,
          },
          {
            path: 'itens/novo',
            element: <TagItemsCreate />,
          },
          {
            path: 'itens/:tagItemId/editar',
            element: <TagItemsEdit />,
          },
          {
            path: 'itens/:tagItemId',
            element: <TagItemsDetails />,
          },
          {
            path: 'novo',
            element: <TagCreate />,
          },
          {
            path: ':tagId',
            element: <TagDetails />,
          },
          {
            path: ':tagId/editar',
            element: <TagEdit />,
          },
        ],
      },
      {
        path: 'configuracoes',
        element: <PaymentOptionsRootPage />,
        children: [
          {
            path: '',
            element: <PaymentList />,
          },
          {
            path: 'integracoes/:name',
            element: <IntegrationConfig />,
          },
          {
            path: 'novo/:paymentId',
            element: <PaymentCreate />,
          },
          {
            path: ':paymentId',
            element: <PaymentDetails />,
          },
          {
            path: ':paymentId/editar',
            element: <PaymentEdit />,
          },
        ],
      },
      // {
      //   path: 'boletos',
      //   element: <PaymentOptionsRootPage />,
      //   children: [
      //     {
      //       path: '',
      //       element: <TicketList />,
      //     },
      //     {
      //       path: ':ticketId',
      //       element: <TicketDetails />,
      //     },
      //   ],
      // },
      {
        path: 'produtos-servicos',
        element: <ServiceITemRootPage />,
        children: [
          {
            path: '',
            element: <ServiceItemsList />,
          },
          {
            path: 'novo',
            element: <ServiceItemsCreate />,
          },
          {
            path: ':serviceItemUuid',
            element: <ServiceItemsDetails />,
          },
          {
            path: ':serviceItemUuid/editar',
            element: <ServiceItemEdit />,
          },
        ],
      },
      {
        path: '/empresas',
        element: <EnterpriseRootPage />,
        children: [
          {
            path: 'criar-nova-empresa',
            element: <CreateNewEnterprisePage />,
          },
          {
            path: 'selecionar',
            element: <SelectEnterprise />,
          },
          {
            path: '',
            element: <EnterpriseList />,
          },
          {
            path: 'novo',
            element: <EnterpriseCreate />,
          },
          {
            path: ':enterpriseId',
            element: <EnterpriseDetails />,
          },
          {
            path: ':enterpriseId/editar',
            element: <EnterpriseEdit />,
          },
        ],
      },
      {
        path: '/relatorios',
        element: <ReportsRootPage />,
        children: [
          {
            path: '',
            element: <GetReport />,
          },
        ],
      },
      {
        path: '/open-banking',
        element: <OpenBankingRootPage />,
        children: [
          {
            path: '',
            element: <OpenBanking />,
          },
        ],
      },
    ],
  },
  {
    path: '*',
    element: <MainLayout />,
    children: [
      {
        path: 'dashboard',
        element: <Redirect to="/" />,
      },
      {
        path: 'movimentacoes',
        element: <Redirect to="/movimentacoes/recebimentos" />,
      },
      {
        path: 'recebimentos',
        element: <Redirect to="/movimentacoes/recebimentos" />,
      },
      {
        path: 'despesas',
        element: <Redirect to="/movimentacoes/despesas" />,
      },
      {
        path: 'contas',
        element: <Redirect to="/contas/receber" />,
      },
      {
        path: 'receber',
        element: <Redirect to="/contas/receber" />,
      },
      {
        path: 'pagar',
        element: <Redirect to="/contas/pagar" />,
      },
      {
        path: 'dashboard/empresas',
        element: <Redirect to="/empresas" />,
      },
      {
        path: '401',
        element: <AuthorizationRequired />,
      },
      {
        path: '404',
        element: <NotFound />,
      },
      {
        path: '500',
        element: <ServerError />,
      },
      {
        path: '*',
        element: <NotFound />,
      },
    ],
  },
];

export default routes;
