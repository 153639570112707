import {
  OutlinedInput,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  FormHelperText,
  Typography,
  Box,
  ListItemIcon,
  Avatar,
} from '@material-ui/core';
import { Control, RegisterOptions, useController, UseControllerProps } from 'react-hook-form';
import styled, { css } from 'styled-components';
import { useTheme } from '@material-ui/core/styles';
import { truncate } from 'src/utils/getShortName';
import { getHelperMessage } from './TextfieldController';

interface KeyLabel {
  key: string | number;
  label: string;
  avatar?: string;
}

interface SelectControllerInterface extends Omit<UseControllerProps<any, any>, 'control' | 'rules'> {
  placeholder?: string;
  control: Control<any>;
  rules: Omit<RegisterOptions<any, any>, 'valueAsNumber' | 'valueAsDate' | 'setValueAs'>;
  values: KeyLabel[];
  label?: string;
  disabled?: boolean;
  fullWidth?: boolean;
  variant?: 'filled' | 'outlined';
  helperMessage?: string;
  size?: 'small' | 'medium';
  truncateText?: boolean;
}

interface CustomInputLabelInterface {
  isSelected: boolean;
  isDarkMode: boolean;
}

export const CustomInputLabel = styled(InputLabel)<CustomInputLabelInterface>`
  margin: -5px;
  padding-left: 5px;
  padding-right: 5px;
  background-color: ${({ isDarkMode }) => (isDarkMode ? '#222b36' : 'white')};

  ${({ isSelected }) =>
    isSelected &&
    css`
      margin: 0px;
      left: -5px;
    `}
`;

export const SelectController: React.FC<SelectControllerInterface> = ({
  control,
  name,
  rules,
  values,
  defaultValue,
  placeholder,
  label,
  helperMessage,
  fullWidth = true,
  disabled = false,
  variant = 'outlined',
  size = 'medium',
  truncateText = false,
}) => {
  const {
    field: { value, onChange, ...fieldProps },
    fieldState: { error },
    formState: { isSubmitting },
  } = useController({
    name,
    control,
    rules,
    defaultValue,
  });
  const theme = useTheme();

  const isDarkMode = theme.palette.mode === 'dark';

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    onChange(event.target.value || undefined);
  };

  return (
    <Box mb={2}>
      <FormControl error={!!error} variant={variant} fullWidth={fullWidth}>
        <CustomInputLabel isSelected={!!value} error={!!error} isDarkMode={isDarkMode}>
          {label}
        </CustomInputLabel>
        <Select
          multiple={false}
          size="small"
          variant={variant}
          disabled={disabled || isSubmitting}
          placeholder={placeholder}
          onChange={handleChange}
          error={!!error}
          fullWidth
          input={<OutlinedInput />}
          value={value || undefined}
          renderValue={(str) => {
            const selected = values.find((obj) => obj.key === str);

            const Text = () =>
              <Typography variant="body2">
                {selected?.label && truncateText ? truncate(selected?.label || '', 90) : selected?.label}
              </Typography>;

            return selected?.avatar ? (
              <Box pt={0.75} alignItems="center" alignContent="center" display="flex" flexDirection="row">
                <Avatar sx={{ width: 16, height: 16 }} src={selected.avatar} />
                <Box mr={1} />
                <Text />
              </Box>
            ) : (
              <Text />
            );
          }}
          {...fieldProps}
        >
          {values.map((keyValue) => (
            <MenuItem
              key={keyValue.key}
              style={{ fontSize: size === 'small' ? 12 : 16 }}
              value={keyValue.key}
            >
              {keyValue.avatar && (
                <ListItemIcon>
                  <Avatar sx={{ width: 16, height: 16 }} src={keyValue.avatar} />
                </ListItemIcon>
              )}
              {truncateText ? truncate(keyValue.label, 120) : keyValue.label}
            </MenuItem>
          ))}
        </Select>
        <FormHelperText error={!!error}>{getHelperMessage(error || helperMessage)}</FormHelperText>
      </FormControl>
    </Box>
  );
};
