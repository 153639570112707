import { convertFormattedMoneyToCents } from 'src/utils/moneyConverter';
import { axiosPOST, CustomAxiosResponse } from '../../../services/axiosService';
import { ServiceItemInterface } from '../types/ServiceItemInterface';

export interface ServiceItemToBeCreated {
  name: string;
  description?: string;
  value: string;
}

interface CreateTagInteface {
  enterpriseUuid: string;
  serviceItem: ServiceItemToBeCreated;
}

export const createItemService = async ({
  enterpriseUuid,
  serviceItem,
}: CreateTagInteface): Promise<CustomAxiosResponse<ServiceItemInterface>> => {
  try {
    const endpoint = `${process.env.REACT_APP_BACKEND}/service-item/${enterpriseUuid}`;

    const formattedData = {
      ...serviceItem,
      value: convertFormattedMoneyToCents(serviceItem.value),
    };

    const response = await axiosPOST<ServiceItemInterface>(endpoint, formattedData);

    return {
      ...response,
      data: response.data,
      message: 'Pronto!',
    };
  } catch (error: any) {
    throw new Error(error?.message ? error.message : 'Ocorreu um erro, tente novamente.');
  }
};
