import { useEffect, useState } from 'react';
import type { FC } from 'react';
import { Box, Drawer, IconButton, Typography } from '@material-ui/core';
import { BrowserView, isMobile } from 'react-device-detect';
import { Close, LocalOffer, PeopleRounded } from '@material-ui/icons';
import { useAuth } from 'src/hooks/useAuth';
import { useMatch, useNavigate } from 'react-router';
import styled from 'styled-components';
import BillForm from 'src/pages/bills/forms/BillForm';
import useSettings from '../../../hooks/useSettings';
import { useMovements } from '../hooks/useMovements';
import SpeedDialTooltipOpen from './SpeedDial';
import { InvoiceIcon } from './InvoiceIcon';
import { ExpenseIcon } from './ExpenseIcon';
import { MovimentTypesSlugEnum } from '../types/MovementInterface';

const getValues = (movement: any) => ({
  compact: movement.compact,
  direction: movement.direction,
  responsiveFontSizes: movement.responsiveFontSizes,
  roundedCorners: movement.roundedCorners,
  theme: movement.theme,
});

const CloseButton = styled(IconButton)`
  padding: 0;
`;

export const MovementDrawer: FC = () => {
  const { settings } = useSettings();
  const navigate = useNavigate();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [values, setValues] = useState(getValues(settings));
  const matchEnterpriseRoute = !!useMatch({
    path: 'empresas',
    end: false,
  });
  const { openDrawer, closeDrawer, isDrawerOpen, isCalendarOpen, formType } = useMovements();
  const { isAuthenticated } = useAuth();

  useEffect(() => {
    setValues(getValues(settings));
  }, [settings]);

  const actions = [
    {
      name: 'Saída',
      icon: <ExpenseIcon />,
      open: () => openDrawer(MovimentTypesSlugEnum.expense),
    },
    {
      name: 'Entrada',
      icon: <InvoiceIcon />,
      open: () => openDrawer(MovimentTypesSlugEnum.invoice),
    },
    {
      name: 'Contato',
      icon: <PeopleRounded color="primary" />,
      open: () => navigate('/clientes/novo'),
    },
    {
      name: 'Tag',
      icon: <LocalOffer color="primary" />,
      open: () => navigate('centro-de-custos/novo'),
    },
  ];

  return (
    <>
      <Drawer
        anchor="right"
        onClose={closeDrawer}
        open={isDrawerOpen}
        PaperProps={{
          sx: {
            p: 2,
            width: window.innerWidth / (isMobile ? 1 : 1.25),
          },
        }}
      >
        <Box display="flex" flexDirection="row" justifyContent="space-between" pr={2}>
          <Box display="flex" flexDirection="row" alignItems="center">
            <Box mr={1}>{formType === MovimentTypesSlugEnum.expense ? <ExpenseIcon /> : <InvoiceIcon />}</Box>
            <Typography color="textPrimary" variant="h6">
              {formType === MovimentTypesSlugEnum.expense ? 'Saída' : 'Entrada'}
            </Typography>
          </Box>
          <Box>
            <CloseButton onClick={closeDrawer} edge="end">
              <Close />
            </CloseButton>
          </Box>
        </Box>
        <Box sx={{ mt: 1.5 }}>
          <BillForm billToBeEdited={undefined} fullWidth />
        </Box>
      </Drawer>
      {/* @ts-ignore */}
      <BrowserView>
        {
          isAuthenticated &&
          !matchEnterpriseRoute &&
          !isDrawerOpen &&
          !isCalendarOpen &&
          <SpeedDialTooltipOpen actions={actions} />
        }
      </BrowserView>
    </>
  );
};

export default MovementDrawer;
