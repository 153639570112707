import { axiosPOST, CustomAxiosResponse } from 'src/services/axiosService';
import { TagInterface } from '../types/TagInterface';
export interface TagToBeCreatedInterface {
  title: string;
  description?: string;
}

interface CreateTagInteface {
  enterpriseUuid: string;
  tag: TagToBeCreatedInterface;
}

export const createTag = async ({
  enterpriseUuid,
  tag,
}: CreateTagInteface): Promise<CustomAxiosResponse<TagInterface>> => {
  try {
    const endpoint = `${process.env.REACT_APP_BACKEND}/tag/${enterpriseUuid}`;

    const response = await axiosPOST<TagInterface>(endpoint, tag);

    return {
      ...response,
      data: response.data,
      message: 'Pronto!',
    };
  } catch (error: any) {
    throw new Error(error?.message ? error.message : 'Ocorreu um erro, tente novamente.');
  }
};
