import React, { createContext, useReducer, useState } from 'react';
import Reducer, { LayoutAction } from './LayoutReducer';
import { layoutInitialState } from './LayoutInitializer';
import { LayoutContextInterface } from './LayoutInterface';

export type LayoutDispatchType = React.Dispatch<LayoutAction>;

interface LayoutProviderInterface {
  stateLayout: LayoutContextInterface;
  dispatchLayout: LayoutDispatchType;
  isSidebarMobileOpen: boolean;
  setIsSidebarMobileOpen: React.Dispatch<React.SetStateAction<boolean>>;
  pageTitle: string;
  setPageTitle: React.Dispatch<React.SetStateAction<string>>;
}

export const LayoutContext = createContext<LayoutProviderInterface>({
  stateLayout: layoutInitialState,
  dispatchLayout: () => console.warn('LayoutDispatch is not ready'),
  isSidebarMobileOpen: false,
  setIsSidebarMobileOpen: () => console.warn('setIsSidebarMobileOpen is not ready'),
  pageTitle: 'Wivvi',
  setPageTitle: () => console.warn('setIsSidebarMobileOpen is not ready'),
});

const LayoutProvider: React.FC = ({ children }) => {
  const [stateLayout, dispatchLayout] = useReducer(Reducer, layoutInitialState);
  const [isSidebarMobileOpen, setIsSidebarMobileOpen] = useState<boolean>(false);
  const [pageTitle, setPageTitle] = useState<string>('Wivvi');

  const globals = {
    stateLayout,
    dispatchLayout,
    isSidebarMobileOpen,
    setIsSidebarMobileOpen,
    pageTitle,
    setPageTitle,
  };

  return <LayoutContext.Provider value={globals}>{children}</LayoutContext.Provider>;
};

export default LayoutProvider;
