import { axiosGET, CustomAxiosResponse } from './../../../services/axiosService';
import { PaymentOptionInterface } from './../types/PaymentOptionInterface';

export const getAllPaymentOptions = async (
  enterpriseUuid: string,
): Promise<CustomAxiosResponse<PaymentOptionInterface[]>> => {
  try {
    const endpoint = `${process.env.REACT_APP_BACKEND}/paymentIntegrations/${enterpriseUuid}`;

    const response = await axiosGET<PaymentOptionInterface[]>(endpoint);

    return {
      ...response,
      data: response.data,
      message: 'Pronto!',
    };
  } catch (error: any) {
    throw new Error(error?.message ? error.message : 'Ocorreu um erro, tente novamente.');
  }
};
