import { axiosPUT, CustomAxiosResponse } from './../../../services/axiosService';
import { EnterpriseInterface } from './../types/EnterpriseInterface';

export interface EditEnterpriseInteface extends Partial<EnterpriseInterface> {}

export const editEnterprise = async (
  enterprise: EditEnterpriseInteface,
): Promise<CustomAxiosResponse<EnterpriseInterface>> => {
  try {
    const endpoint = `${process.env.REACT_APP_BACKEND}/enterprises/${enterprise.uuid}`;

    const response = await axiosPUT<EnterpriseInterface>(endpoint, enterprise);

    return {
      ...response,
      data: response.data,
      message: 'Empresa editada com sucesso!',
    };
  } catch (error: any) {
    throw new Error(error?.message ? error.message : 'Ocorreu um erro, tente novamente.');
  }
};
