import { FC } from 'react';
import { Box, Container, Grid } from '@material-ui/core';
import DashboardNavbar from 'src/components/dashboard/DashboardNavbar';
import { DashboardLayoutContainer, DashboardLayoutContent } from 'src/components/dashboard/DashboardLayout';
import { EnterpriseCard } from './components/EnterpriseCard';
import { useEnterprise } from './hooks/useEnterprise';

const SelectEnterprise: FC = () => {
  const { availableEnterprises } = useEnterprise();

  return (
    <>
      <DashboardNavbar />
      <Box mt={2}>
        <DashboardLayoutContainer>
          <DashboardLayoutContent>
            <Container maxWidth="md">
              <Box my={8}>
                <Grid container spacing={4}>
                  {availableEnterprises?.map((_enterprise) => (
                    <Grid item xs={12} sm={6} md={4} lg={4}>
                      <EnterpriseCard key={_enterprise.uuid} enterprise={_enterprise} />
                    </Grid>
                  ))}
                </Grid>
              </Box>
            </Container>
          </DashboardLayoutContent>
        </DashboardLayoutContainer>
      </Box>
    </>
  );
};

export default SelectEnterprise;
