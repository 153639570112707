import type { FC } from 'react';
import { Typography, Button, experimentalStyled } from '@material-ui/core';
import useAuth from 'src/hooks/useAuth';

const StyledButton = experimentalStyled(Button)(({ theme }) => ({
  border: '1px solid rgba(0, 0, 0, 0.23)',
  borderRadius: '16px',
  padding: '16px 14px',
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  background: theme.palette.mode === 'dark' ? '#fff' : 'initial',
  '&:hover': {
    background: theme.palette.mode === 'dark' ? '#ccc' : theme.palette.background.default,
  },
}));

const SignInGoogleButon: FC = () => {
  const { loginWithGoogle } = useAuth();

  return (
    <StyledButton onClick={loginWithGoogle}>
      <img alt="Auth platform" src="/static/logos/google_logo.svg" style={{ width: '26px' }} />
      <Typography variant="subtitle1" sx={{ marginLeft: '8px' }} color="#005578">
        Fazer login com o Google
      </Typography>
    </StyledButton>
  );
};

export default SignInGoogleButon;
